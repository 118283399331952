<template>
  <div class="row my-4">
    <div class="col">
      <button class="btn btn-primary" @click.prevent="$router.push(route)">Back</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RouteButton',
  props: {
    text: {
      type: String,
      required: true,
    },
    route: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped></style>
