<template>
  <b-modal
    ref="modalDlg"
    title="Table"
    @ok="onOk"
    @cancel="onCancel"
    ok-title="Next"
    no-fade
    size="lg"
  >
    <template v-slot:modal-header />

    <div class="alert alert-info text-center mb-4">
      <div class="align-middle">Here are the fields we've found in this file</div>
    </div>
    <field-list :fields="xfields" />
  </b-modal>
</template>

<script>
import * as _ from 'lodash';
import FieldList from './FieldList.vue';
import * as Fields from './fields.js';

export default {
  name: 'TableFieldsDlg',
  components: {
    FieldList,
  },
  data() {
    return {
      xfields: [],
      types: Fields.Type.getTypes(),
    };
  },
  mounted() {},
  methods: {
    open(fields) {
      this.xfields = fields.map((field) => {
        const type = field.type ? field.type : Fields.Type.TEXT.name;
        const matcher =
          field.matcher && Fields.Type.isMatcherOfType(field.matcher, type)
            ? field.matcher
            : Fields.Type.getMatchers(type)[0].name;
        const fieldSourceType = Fields.SourceType.SELF_CONTAINED;
        const fieldValueBuilderType = Fields.ValueBuilderType.SELF_CONTAINED;
        return Object.assign({}, field, { type, matcher, fieldSourceType, fieldValueBuilderType });
      });

      this.$refs.modalDlg.show();
    },
    onOk(event) {
      event.preventDefault();
      if (!this.allFieldsAreValid()) {
        alert('Please fill up all the fields');
      } else {
        const fields = _.clone(this.xfields);
        this.resetModel();

        console.log('%s.onOk():', this.$options.name, fields);
        this.$emit('ok', fields);

        this.$nextTick(() => {
          this.$refs.modalDlg.hide();
        });
      }
    },
    onCancel() {
      this.resetModel();
      this.$emit('cancel');
    },
    resetModel() {
      this.xfields = [];
    },
    allFieldsAreValid() {
      const emptyFields = this.xfields
        .filter((field) => field.fieldSourceType === 'DERIVED')
        .filter(
          (field) =>
            !field.name ||
            field.name.length === 0 ||
            !field.fieldValueBuilderArgument ||
            field.fieldValueBuilderArgument.length === 0,
        );
      return emptyFields.length === 0;
    },
  },
};
</script>

<style scoped></style>
