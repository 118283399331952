import path from 'path';
import format from 'string-format';
import { Api } from './Api';
import DataRepositoryApi from './DataRepositoryApi';

class DataTableApi extends Api {
  static URL_TABLES = path.join(DataRepositoryApi.URL_REPOSITORY, 'tables');
  static URL_TABLE = path.join(DataTableApi.URL_TABLES, '{tableReferenceId}');
  static URL_INDEXES = path.join(DataTableApi.URL_TABLE, 'indexes');

  constructor(callbacks) {
    super(callbacks);
  }

  /**
   * Get list of tables for given repository
   */
  getTables(repositoryId) {
    const url = format(DataTableApi.URL_TABLES, { repositoryId });
    return super.get(url);
  }

  /**
   * Get table for given repository and table IDs
   */
  getTable(repositoryId, tableReferenceId) {
    const url = format(DataTableApi.URL_TABLE, { repositoryId, tableReferenceId });
    return super.get(url);
  }

  /**
   * Create a new table for given repository
   */
  createTable(repositoryId, table) {
    const url = format(DataTableApi.URL_TABLES, { repositoryId });
    return super.post(url, table);
  }

  deleteTable(repositoryId, tableReferenceId) {
    const url = format(DataTableApi.URL_TABLE, { repositoryId, tableReferenceId });
    return super.delete(url);
  }

  /**
   * Create a new index for given repository and table
   */
  createIndex(repositoryId, tableReferenceId, index) {
    const url = format(DataTableApi.URL_INDEXES, { repositoryId, tableReferenceId });
    return super.post(url, [index]);
  }

  /**
   *Delete an existing index
   */
  deleteIndex(repositoryId, tableReferenceId, index) {
    const url =
      format(DataTableApi.URL_INDEXES, { repositoryId, tableReferenceId }) + '?indexName=' + index;
    return super.delete(url);
  }

  /**
   * Update table for given repository
   */
  updateTable(repositoryId, tableReferenceId, table) {
    const url = format(DataTableApi.URL_TABLE, { repositoryId, tableReferenceId });
    return super.put(url, table);
  }

  /**
   * Upload data source file for given table
   */
  createResource(repositoryId, tableReferenceId, form, onUploadProgress) {
    const config = {
      method: 'POST',
      url: format(DataTableApi.URL_TABLE, { repositoryId, tableReferenceId }),
      data: form,
      onUploadProgress,
    };
    return this._request(config, true);
  }
}

export default DataTableApi;
