<template>
  <modal-dlg
    id="modal"
    ref="modal"
    title="Remove installation"
    :submit="removeInstall"
    :validator="$validator"
    no-fade
    lazy
    ok-variant="danger"
    ok-title="Remove"
    @cancel="clearState"
    @hidden="clearState"
  >
    Are you sure you want to delete the install?
  </modal-dlg>
</template>

<script>
import ApiMixin from '../../mixins/ApiMixin.vue';
import ModalDlg from '../../components/ModalDlg.vue';

export default {
  name: 'RemoveInstallConfiguration',
  mixins: [ApiMixin],
  components: {
    ModalDlg,
  },

  data() {
    return {
      install: {},
    };
  },

  methods: {
    removeInstall() {
      this.$emit('removeInstallation', this.install);
    },

    show(install) {
      this.install = install;
      this.$refs.modal.show();
    },

    clearState() {
      this.install = {};
    },
  },
};
</script>
