import path from 'path';
import format from 'string-format';
import { Api } from './Api';

class CatalogApi extends Api {
  static URL_CATALOG = 'catalog';
  static URL_APIS = path.join(this.URL_CATALOG, 'apis');
  static URL_API = path.join(this.URL_APIS, '{id}');
  static URL_REPOSITORIES = path.join(this.URL_CATALOG, 'repositories');
  static URL_REPOSITORY = path.join(this.URL_REPOSITORIES, '{id}');

  async getApis() {
    return await super.get(CatalogApi.URL_APIS);
  }

  getApi(id) {
    const url = format(CatalogApi.URL_API, { id });
    return super.get(url);
  }

  async getRepositories() {
    return await super.get(CatalogApi.URL_REPOSITORIES);
  }

  async getRepository(id) {
    const url = format(CatalogApi.URL_REPOSITORY, { id });
    return await super.get(url, id);
  }
}

export default CatalogApi;
