<template>
  <div class="card-body">
    <div class="row">
      <div class="col">
        {{ getTypeCaption() }}
      </div>

      <div class="col-8 d-flex align-items-center">
        <b-progress
          v-if="isInProgress()"
          :value="model.progress"
          :max="model.total"
          show-progress
          animated
          class="w-100"
        >
        </b-progress>

        <div
          :class="[
            'px-4 text-center',
            { 'bg-primary text-white': isPending(), 'bg-success text-white': isDone() },
          ]"
        >
          {{ getStateCaption() }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Tasks } from './tasks.js';

export default {
  name: 'ProgressBar',
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getTypeCaption() {
      return Tasks.getTypeCaption(this.model);
    },
    getStateCaption() {
      return Tasks.getStatesCaption(this.model);
    },
    isInProgress() {
      return this.model.state === Tasks.STATE_IN_PROGRESS;
    },
    isDone() {
      return this.model.state === Tasks.STATE_DONE;
    },
    isPending() {
      return this.model.state === Tasks.STATE_PENDING;
    },
  },
};
</script>

<style scoped></style>
