<template>
  <div>
    <canvas id="chart"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js/auto';

export default {
  name: 'TimeChart',
  props: {
    dataset: {
      type: Array,
      required: true,
    },
    query: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    this.init('chart');
  },
  watch: {
    dataset: function () {
      const datasets = this.chart.data.datasets;
      const dataset = datasets && datasets.length > 0 ? this.chart.data.datasets[0] : null;
      if (dataset) {
        dataset.data = this.dataset;
        const period = this.query.period.toLowerCase();
        const timeConfig = this.chart.options.scales.xAxes[0].time;
        timeConfig.unit = period;
        timeConfig.round = period;
        this.chart.update();
      }
    },
  },
  methods: {
    init(el) {
      const ctx = document.getElementById(el);
      this.chart = new Chart(ctx, {
        type: 'bar',
        data: {
          datasets: [
            {
              backgroundColor: 'rgba(54, 162, 235, 0.5)',
              borderColor: 'rgba(54, 162, 235, 1)',
              borderWidth: 1,
              lineTension: 0,
              pointRadius: 5,
              pointBorderWidth: 1,
              barPercentage: 1,
              fill: false,
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                offset: true,
                type: 'time',
                bounds: 'ticks',
                time: {
                  unit: 'hour',
                },
              },
            ],
            yAxes: [
              {
                offset: true,
                ticks: {
                  beginAtZero: true,
                  userCallback(label) {
                    return Math.floor(label) === label ? label : null;
                  },
                },
              },
            ],
          },
        },
      });
    },
  },
};
</script>

<style scoped></style>
