import * as components from '.';
import { default as projectRoutes } from '../project/routes';

export default [
  {
    path: '/integrations',
    component: components.IntegrationList,
    name: 'integration-list',
  },
  {
    path: '/integrations/:blueprintId',
    component: components.IntegrationDetails,
    name: 'integration-details',
    props: true,
    children: [
      {
        path: 'source',
        components: {
          helper: components.IntegrationSource,
        },
        name: 'integration-source',
      },
      {
        path: 'transform',
        components: {
          helper: components.IntegrationTransform,
        },
        name: 'integration-transform',
      },
      {
        path: 'target',
        components: {
          helper: components.IntegrationTarget,
        },
        name: 'integration-target',
      },
    ],
  },
  {
    path: '/installs',
    component: components.Installs,
    name: 'installs',
  },
  {
    path: '/tokens',
    component: components.IntegrationsTokens,
    name: 'tokens',
  },
  {
    path: '/projects',
    component: components.IntegrationProjects,
    name: 'projects',
  },
  {
    path: '/projects/:id',
    component: components.IntegrationProjectPage,
    name: 'projectPage',
    props: (route) => ({ projectId: route.params.id }),
    children: projectRoutes,
  },
];
