<template>
  <div class="p-4">
    <div class="pb-4 row">
      <span slot="prepend" class="search"></span>
      <input type="text" v-model="filter" placeholder="Find" class="search" />
      <button class="ml-3" @click="$refs.newKeyDialog.show()">+New Key</button>
    </div>
    <b-table
      hover
      bordered
      :items="keys"
      :fields="COLUMNS"
      :filter="filter"
      :sort-compare="sort"
      thead-class="table-header"
      tbody-class="table-body"
      sort-by="createdOn"
      sort-desc
      fixed
    >
      <template #cell(actions)="data">
        <div class="d-flex flex-row justify-content-center">
          <b-button
            class="mr-3"
            :id="'show-btn-' + data.item.referenceId"
            variant="outline-primary"
            style="float: left"
            @click="showKey(data.item)"
          >
            Key info
            <i class="bi bi-info-circle" />
          </b-button>
          <b-button
            :id="'delete-btn-' + data.item.referenceId"
            variant="outline-primary"
            style="float: left"
            @click="deleteKey(data.item.referenceId)"
          >
            Remove key
            <i class="bi bi-trash" />
          </b-button>
        </div>
      </template>
    </b-table>
    <span v-if="isLoading">Loading...</span>
    <span v-else-if="!keys.length">There are no keys</span>
    <key-view v-else ref="keyView" />
    <new-integration-key-dialog
      ref="newKeyDialog"
      @newKeyCreated="onNewKeyCreated"
      :project-id="projectId"
    />
  </div>
</template>

<script>
import { default as ApiMixin } from '../mixins/ApiMixin.vue';
import NewIntegrationKeyDialog from './components/integrationKeys/NewKey.vue';
import KeyView from './components/integrationKeys/KeyView.vue';
import { formatInstant } from '../dates';

const COLUMNS = [
  {
    key: 'name',
    label: 'Name',
    sortable: false,
  },
  {
    key: 'createdOn',
    label: 'Created On',
    sortable: false,
    formatter: (value) => formatInstant(value),
  },
  {
    key: 'actions',
    label: 'Actions',
    sortable: false,
  },
];

export default {
  name: 'IntegrationsKeys',
  mixins: [ApiMixin],
  components: {
    KeyView,
    NewIntegrationKeyDialog,
  },
  props: {
    projectId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      COLUMNS,
      isLoading: true,
      keys: [],
      newKey: null,
      filter: null,
    };
  },
  async mounted() {
    if (this.projectId) {
      this.keys = await this.keysApi().getIntegrationsKeysByProjectId(this.projectId);
      this.keys.filter((key) => key.projectId === this.projectId);
      this.isLoading = false;
    }
  },
  methods: {
    sort(a, b, key) {
      return a[key].localeCompare(b[key]);
    },
    onNewKeyCreated: function (newKey) {
      this.keys.push(newKey);
      this.showKey(newKey);
    },
    showKey(newKey) {
      this.$refs.keyView.show(newKey);
    },
    deleteKey: async function (deletedKeyId) {
      await this.keysApi().deleteKey(deletedKeyId);
      this.keys = this.keys.filter((key) => key.referenceId !== deletedKeyId);
    },
  },
};
</script>
