<template>
  <div class="field-list">
    <table-field
      v-for="(field, idx) in sortedFields"
      :field="field"
      :key="field.name"
      :readonly="readonly"
      @add="onAdd(idx)"
      @remove="onRemove(idx)"
      @change="onChange(idx)"
    />
  </div>
</template>

<script>
import * as Fields from './fields.js';
import TableField from './TableField.vue';
import { Type } from './fields';

export default {
  name: 'FieldList',
  components: { TableField },
  props: {
    fields: {
      type: Array,
    },
    readonly: {
      type: Boolean,
    },
  },
  model: {
    prop: 'fields',
    event: 'change',
  },
  data() {
    return {};
  },
  computed: {
    sortedFields() {
      return this.fields
        ? [...this.fields].sort((left, right) => {
            const leftName =
              left.fieldSourceType === Fields.SourceType.SELF_CONTAINED
                ? left.name
                : left.fieldSourceArgument;
            const rightName =
              right.fieldSourceType === Fields.SourceType.SELF_CONTAINED
                ? right.name
                : right.fieldSourceArgument;
            return leftName.localeCompare(rightName);
          })
        : [];
    },
  },
  methods: {
    getFieldTypeText(type) {
      return Fields.Type.getType(type).text;
    },
    getFieldMatcherText(type, matcher) {
      const result = Fields.Type.getMatchers(type).find((m) => m.name === matcher);
      return result ? result.text : '';
    },
    onAdd(idx) {
      const fieldToCopy = this.fields[idx];
      this.fields.push(this.newDerivedField(fieldToCopy));
    },
    onRemove(idx) {
      this.fields.splice(idx, 1);
    },
    onChange(idx) {
      const field = this.fields[idx];
      if (field.type !== Type.TEXT.name) {
        const filtered = this.fields.filter((f) => f.fieldSourceArgument !== field.name);
        this.fields.splice(0, this.fields.length, ...filtered);
      }
    },
    newDerivedField(parent) {
      return {
        tableReferenceId: parent.tableReferenceId,
        type: parent.type,
        matcher: parent.matcher,
        fieldSourceType: Fields.SourceType.DERIVED,
        fieldSourceArgument: parent.name,
        fieldValueBuilderType: Fields.ValueBuilderType.HEAD,
      };
    },
  },
};
</script>

<style scoped></style>
