<template>
  <b-modal
    ref="dialog"
    :title="title"
    :ok-disabled="okDisabled"
    :size="size || 'md'"
    centered
    no-fade
    lazy
    v-bind="{ busy, okTitle, okVariant }"
    :busy="busy"
    @ok="onOk"
    @cancel="onCancel"
    @hidden="onHidden"
    @shown="onShown"
  >
    <slot />
  </b-modal>
</template>

<script>
import { Callbacks } from '../api.js';
import { default as ValidateMixin } from '../mixins/ValidationMixin.vue';
import { default as ApiMixin } from '../mixins/ApiMixin.vue';

class ApiEvents extends Callbacks {
  constructor(component) {
    super();
    this._component = component;
    this.busy = false;
  }

  onFailure() {
    this.busy = false;
  }

  onPending() {
    this.busy = true;
  }

  onSuccess() {
    this.busy = false;
  }

  get busy() {
    return this._component.busy;
  }

  set busy(value) {
    this._component.busy = value;
  }
}

export default {
  name: 'ModalDlg',
  mixins: [ApiMixin, ValidateMixin],
  props: {
    okDisabled: {
      type: Boolean,
      required: false,
    },
    okTitle: {
      type: String,
      required: false,
    },
    okVariant: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    submit: {
      type: Function,
      required: false,
    },
    validator: {
      type: Object,
      required: false,
    },
    size: {
      type: String,
      required: false,
    },
  },
  created() {
    this.apiEventHandler = new ApiEvents(this);
  },
  data() {
    return {
      dialog: null,
      busy: false,
      apiEventHandler: null,
      model: {},
    };
  },
  mounted() {
    this.dialog = this.$refs.dialog;
  },
  methods: {
    /**
     * @override ApiMixin.getApiEventHandler
     */
    getApiEventHandler() {
      return this.apiEventHandler;
    },
    show() {
      this.dialog.show();
    },
    hide() {
      this.dialog.hide();
    },
    onOk(event) {
      if (!this.submit) {
        return;
      }

      event.preventDefault(); // prevent closing

      this.validateInput(this.validator)
        .then(() => {
          let result = this.submit();
          if (!result) {
            result = Promise.resolve(() => this.hide());
          }
          return result.then(() => this.hide());
        })
        .catch((error) => {
          this.onSubmitError(this.validator, error);
        });
    },
    onShown() {
      this.apiEventHandler.busy = false;
      this.$emit('shown');
    },
    onHidden() {
      this.$emit('hidden');
    },
    onCancel() {},
  },
};
</script>
