import { ApiAjax } from './AjaxApi';

export class Api {
  /**
   * Base REST API URL
   * @type {string}
   */
  static BASE_URL = process.env.VUE_APP_API_SERVER + '/internal-api';

  constructor(callbacks) {
    this._ajax = new ApiAjax(Api.BASE_URL);
    this._callbacks = callbacks;
  }

  /**
   * GET request
   * @returns {Promise}
   */
  get(url, authenticate = true) {
    return this._request({ method: 'GET', url }, authenticate);
  }

  /**
   * DELETE request
   * @returns {Promise}
   */
  delete(url) {
    return this._request({ method: 'DELETE', url });
  }

  /**
   * POST request
   * @returns {Promise}
   */
  post(url, data, authenticate = true) {
    return this._request({ method: 'POST', url, data }, authenticate);
  }

  /**
   * PUT request
   * @returns {Promise}
   */
  put(url, data, authenticate = true) {
    return this._request({ method: 'PUT', url, data }, authenticate);
  }

  _request(config, authenticate = true) {
    this._onPending(config.method, config.url);

    return this._ajax
      .requestAsync(config, authenticate)
      .then((result) => {
        this._onSuccess(config.method, config.url);
        return result;
      })
      .catch((error) => {
        this._onFailure(error);
        throw error;
      });
  }

  _onPending(method, url) {
    if (this._callbacks) {
      this._callbacks.onPending(method, url);
    }
  }

  _onSuccess(method, url) {
    if (this._callbacks) {
      this._callbacks.onSuccess(method, url);
    }
  }

  _onFailure(error) {
    if (this._callbacks) {
      this._callbacks.onFailure(error);
    }
  }
}
