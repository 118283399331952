<template>
  <b-modal
    ref="dialog"
    title="Import Project"
    id="import-project-dialog"
    header-class="p-2 border-bottom-0"
    hide-header-close
  >
    <div v-if="project.id">
      The project with name '{{ project.name }}' has been imported successfully.
    </div>
    <div v-else>
      <b-icon icon="exclamation-circle-fill" variant="danger"></b-icon>
      There was a problem with project import!
    </div>
    <template #modal-footer>
      <router-link v-if="project.id" :to="{ name: 'blueprints', params: { id: project.id } }">
        <b-button variant="primary" @click="editProject">Edit project</b-button>
      </router-link>
      <b-button v-if="project.id" variant="primary" @click.prevent="ok">OK</b-button>
      <b-button v-else variant="primary" @click.prevent="hide">OK</b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'ImportProjectDialog',
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  emits: ['newProjectImportSuccess'],
  methods: {
    editProject: function () {
      this.$emit('newProjectImportSuccess', this.project);
    },
    ok: function () {
      this.hide();
      this.$emit('newProjectImportSuccess', this.project);
    },
    show: function () {
      this.$refs.dialog.show();
    },
    hide: function () {
      this.$refs.dialog.hide();
    },
  },
};
</script>
