<template>
  <b-modal ref="modal" ok-only size="lg" title="Your Refresh Token" @hide="onClose">
    This is your Refresh Token. Please keep it in a secure location.
    <div class="d-block text-center" style="width: 100%">
      <textarea v-model="token" readonly="true" style="width: 100%" rows="10"></textarea>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'RefreshToken',
  data() {
    return {
      token: null,
    };
  },
  methods: {
    open(token) {
      this.$set(this, 'token', token);
      this.$refs.modal.show();
    },
    onClose() {
      this.$set(this, 'token', null);
    },
  },
};
</script>

<style scoped></style>
