<template>
  <div>
    <p>Installs is under construction</p>
  </div>
</template>

<script>
export default {
  name: 'Installs',
};
</script>
