import path from 'path';
import { Auth } from '../auth/auth';
import { Api } from './Api';

class AuthApi extends Api {
  static URL_AUTH = 'auth';

  constructor(callbacks) {
    super(callbacks);
  }

  async loginAsync(email, password) {
    const url = path.join(AuthApi.URL_AUTH, 'login');
    const tokens = await this._request({ method: 'POST', url, data: { email, password } }, false);
    Auth.login(tokens);
  }

  async otpLoginAsync(authenticityToken, otp) {
    const url = path.join(AuthApi.URL_AUTH, 'login/otp');
    const tokens = await this._request(
      { method: 'POST', url, data: { authenticityToken, otp } },
      false,
    );
    Auth.login(tokens);
  }

  getAuthenticityTokenForOtp() {
    return Auth.getToken(Auth.AUTHENTICITY_TOKEN_KEY);
  }
}

export default AuthApi;
