<template>
  <div>
    <div class="row">
      <div class="col"><h4>Repositories</h4></div>
      <div class="col text-right">
        <b-button
          id="button-new-repository"
          @click.prevent="addRepo"
          variant="outline-primary"
          size="sm"
          >New repository</b-button
        >
      </div>
    </div>

    <b-tabs content-class="mt-3">
      <b-tab title="Source" active>
        <div
          class="card my-4"
          v-for="repository in sourceRepositories()"
          v-bind:key="repository.referenceId"
        >
          <repo-list-row
            :repository="repository"
            :editRepository="editRepository"
            :deleteRepository="onDelete"
          />
        </div>
        <div v-if="!sourceRepositories().length" class="row">
          <div class="col">The list is empty</div>
        </div>
      </b-tab>
      <b-tab title="Reference">
        <div
          class="card my-4"
          v-for="repository in referenceRepositories()"
          v-bind:key="repository.referenceId"
        >
          <repo-list-row
            :repository="repository"
            :editRepository="editRepository"
            :deleteRepository="onDelete"
          />
        </div>
        <div v-if="!referenceRepositories().length" class="row">
          <div class="col">The list is empty</div>
        </div>
      </b-tab>
    </b-tabs>

    <b-modal
      id="modalNewRepository"
      ref="modalNewRepository"
      title="New repository"
      @ok="insertRepository"
      @cancel="resetDetails"
      @shown="() => this.$refs.repoAdd.shown()"
      static
    >
      <repo-details ref="repoAdd"></repo-details>
    </b-modal>

    <b-modal
      id="modalEditRepository"
      ref="modalEditRepository"
      title="Repository"
      @ok="updateRepository"
      @cancel="resetDetails"
      @shown="() => this.$refs.repoEdit.shown()"
      static
    >
      <repo-details ref="repoEdit"></repo-details>
    </b-modal>

    <b-modal
      id="modalDelete"
      ref="modalDelete"
      title="Warning"
      @ok="deleteRepository"
      @cancel="$refs.modalDelete.repository = null"
      no-fade
      static
    >
      <div class="message alert alert-danger position-relative text-center" role="alert">
        All data in this repository will be deleted
      </div>
    </b-modal>
  </div>
</template>

<script>
import { default as ApiMixin } from '../../mixins/ApiMixin.vue';
import { mapGetters } from 'vuex';
import { BTab, BTabs } from 'bootstrap-vue';
import RepoDetails from './RepoDetails.vue';
import RepoListRow from './RepoListRow.vue';

const ERROR_DELETE_REPO = 'Unable to delete not empty repository';

export default {
  name: 'RepoList',
  mixins: [ApiMixin],
  components: {
    BTabs,
    BTab,
    RepoDetails,
    RepoListRow,
  },
  data() {
    return {
      repositories: [],
    };
  },
  computed: mapGetters(['currentUser', 'apiSubscriptionsToMeByRepository']),
  mounted() {
    this.load();
  },
  methods: {
    load: async function () {
      const repositories = await this.dataRepositoryApi().getRepositories();
      this.repositories = repositories;
      this.repositories.forEach((repository) => {
        this.loadCounters(repository);
      });
    },
    loadCounters(repository) {
      this.dataTableApi()
        .getTables(repository.referenceId)
        .then((tables) => this.$set(repository, 'tableCount', tables.length));
      this.dataApiApi()
        .getApis(repository.referenceId)
        .then((apis) => this.$set(repository, 'apiCount', apis.length));

      this.$set(
        repository,
        'subscriptionCount',
        this.apiSubscriptionsToMeByRepository(repository.referenceId).length,
      );

      this.tokensApi()
        .getTokens(repository.referenceId)
        .then((tokens) => this.$set(repository, 'tokensCount', tokens.length));
    },
    addRepo() {
      this.$refs.modalNewRepository.show();
    },
    editRepository(repository) {
      this.$refs.repoEdit.setModel(repository);
      this.$refs.modalEditRepository.show();
    },
    insertRepository() {
      const model = this.$refs.repoAdd.getModel();
      if (!model.type) model.type = 'Source';
      this.save(model);
    },
    resetDetails() {
      this.$refs.repoAdd.resetModel();
    },
    save: function (model) {
      this.dataRepositoryApi()
        .createRepository(model)
        .then(() => this.load());
    },
    onDelete(repository) {
      this.$refs.modalDelete.repository = repository;
      this.$refs.modalDelete.show();
    },
    deleteRepository() {
      this.dataRepositoryApi()
        .deleteRepository(this.$refs.modalDelete.repository.referenceId)
        .then(() => this.load())
        .catch((error) => {
          this.clearApiState();
          this.setFailure(error.response.status, ERROR_DELETE_REPO);
        });
    },
    updateRepository() {
      const repository = this.$refs.repoEdit.getModel();
      console.log('updateRepository', repository);
      this.dataRepositoryApi()
        .updateRepository(repository.referenceId, repository)
        .then((repository) => {
          const idx = this.repositories.findIndex((r) => r.referenceId === repository.referenceId);
          this.$set(this.repositories, idx, repository);
          this.loadCounters(repository);
        });
    },
    sourceRepositories() {
      return this.repositories.filter((r) => r.type === 'Source');
    },
    referenceRepositories() {
      return this.repositories.filter((r) => r.type === 'Reference');
    },
  },
};
</script>

<style scoped>
.repository-icon {
  width: 30px;
}
</style>
