<template>
  <div>
    <modal-dlg
      id="modal"
      ref="modal"
      title="Change Next Blueprint"
      :submit="createTransform"
      size="md"
      no-fade
      lazy
      @hidden="clearState"
      @cancel="clearState"
    >
      <div class="form-group row">
        <label class="col-3 col-form-label" for="status">Blueprints</label>
        <div class="col-9">
          <b-form-select
            valueField="id"
            textField="name"
            :options="[{ name: 'none', id: null }, ...blueprints]"
            v-model="newBlueprintId"
          />
        </div>
      </div>
    </modal-dlg>
  </div>
</template>

<script>
import ApiMixin from '../../mixins/ApiMixin.vue';
import ModalDlg from '../../components/ModalDlg.vue';

export default {
  name: 'ChangeNextBlueprint',
  mixins: [ApiMixin],
  components: {
    ModalDlg,
  },
  props: {
    blueprints: {
      type: Array,
      required: true,
    },
    nextBlueprintId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      newBlueprintId: '',
    };
  },
  watch: {
    nextBlueprintId: {
      handler() {
        this.newBlueprintId = this.nextBlueprintId;
      },
      immediate: true,
    },
  },
  methods: {
    clearState() {
      this.blueprintId = '';
    },
    show() {
      this.$refs.modal.show();
    },
    createTransform() {
      this.$emit('update-next-blueprint', this.newBlueprintId);
    },
  },
};
</script>
