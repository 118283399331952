import path from 'path';
import format from 'string-format';
import { Api } from './Api';

class DataRepositoryApi extends Api {
  static URL_REPOSITORIES = 'repositories';
  static URL_REPOSITORY = path.join(DataRepositoryApi.URL_REPOSITORIES, '{repositoryId}');

  constructor(callbacks) {
    super(callbacks);
  }

  /**
   * Get list of repositories
   */
  getRepositories() {
    const url = DataRepositoryApi.URL_REPOSITORIES;
    return super.get(url);
  }

  /**
   * Get repositories for given repository ID
   */
  getRepository(repositoryId) {
    const url = format(DataRepositoryApi.URL_REPOSITORY, { repositoryId });
    return super.get(url);
  }

  /**
   * Create a new repository
   */
  createRepository(repository) {
    const url = DataRepositoryApi.URL_REPOSITORIES;
    return super.post(url, repository);
  }

  /**
   * Update repository
   */
  updateRepository(repositoryId, repo) {
    const url = format(DataRepositoryApi.URL_REPOSITORY, { repositoryId });
    return super.put(url, repo);
  }

  cloneRepository(repositoryId) {
    const url = path.join(format(DataRepositoryApi.URL_REPOSITORY, { repositoryId }), 'clone');
    return super.post(url);
  }

  /**
   * Update repository visibility
   */
  async updateRepositoryVisibility(repositoryId, isPublic) {
    const url = path.join(format(DataRepositoryApi.URL_REPOSITORY, { repositoryId }), 'visibility');
    const visibility = { isPublic: isPublic };
    return super.put(url, visibility);
  }

  /**
   * Delete repository
   */
  deleteRepository(repositoryId) {
    const url = format(DataRepositoryApi.URL_REPOSITORY, { repositoryId });
    return super.delete(url);
  }
}

export default DataRepositoryApi;
