<template>
  <div class="ds-account-details">
    <div class="form-group row">
      <label class="col-2 col-form-label" for="account-name">Name</label>
      <div class="col">
        <input
          id="account-name"
          :class="[
            { 'is-invalid': errors.has('accountName') },
            readonly ? 'form-control-plaintext' : 'form-control',
          ]"
          :readonly="readonly"
          v-model="account.name"
          v-validate="'required'"
          data-vv-as="Name"
          name="accountName"
          type="text"
        />
        <div class="invalid-feedback" v-show="errors.has('accountName')">
          {{ errors.first('accountName') }}
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-2 col-form-label" for="account-businessInfo">Business information</label>
      <div class="col">
        <span v-if="readonly" class="form-control-plaintext">
          {{ account.info }}
        </span>
        <textarea
          id="account-businessInfo"
          v-else
          class="form-control"
          v-model="account.info"
          name="accountBusinessInfo"
          rows="5"
          cols="50"
          maxlength="1000"
        ></textarea>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { default as ApiMixin } from '../mixins/ApiMixin.vue';
import { default as ValidatorProviderMixin } from '../mixins/ValidatorProviderMixin.vue';
import { assign } from 'lodash';

export default {
  name: 'AccountDetails',
  mixins: [ApiMixin, ValidatorProviderMixin],
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  computed: mapGetters(['currentUser']),
  data() {
    return {
      account: {},
      error: null,
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load: function () {
      this.accountApi()
        .getAccount(this.currentUser.accountId)
        .then((account) => {
          const bi = JSON.parse(account.businessInformation);
          this.account = assign(account, bi);
        })
        .catch(() => (this.error = 'Error loading account details'));
    },
    getModel() {
      this.account.businessInformation = this.account.info;
      return this.account;
    },
  },
};
</script>
