<template>
  <div class="row">
    <div class="col">
      <div class="form-group row">
        <label class="col-3 col-form-label" for="name">Name</label>
        <div class="col">
          <input
            id="name"
            :readonly="readonly"
            :class="[
              { 'is-invalid': errors.has('name') },
              readonly ? 'form-control-plaintext' : 'form-control',
            ]"
            v-model="apiIn.name"
            v-validate="'required'"
            data-vv-as="Name"
            name="name"
            type="text"
          />
          <div class="invalid-feedback" v-show="errors.has('name')">
            {{ errors.first('name') }}
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-3 col-form-label" for="prefix">Prefix</label>
        <div class="col">
          <input
            id="prefix"
            :readonly="readonly"
            :class="[
              { 'is-invalid': errors.has('prefix') },
              readonly ? 'form-control-plaintext' : 'form-control',
            ]"
            v-model="apiIn.prefix"
            v-validate="'required|alpha_dash'"
            data-vv-as="Prefix"
            name="prefix"
            type="text"
          />
          <div class="invalid-feedback" v-show="errors.has('prefix')">
            {{ errors.first('prefix') }}
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-3 col-form-label" for="description">Description</label>
        <div class="col">
          <textarea
            id="description"
            :readonly="readonly"
            :class="[readonly ? 'form-control-plaintext' : 'form-control']"
            v-model="apiIn.description"
            rows="2"
            cols="50"
            maxlength="1000"
            data-vv-as="Description"
            name="description"
          >
          </textarea>
        </div>
      </div>

      <div class="form-group row align-items-center" v-if="apiIn.id">
        <label class="col-3 col-form-label" for="apiEnabled">Enabled</label>
        <div class="col">
          <input
            id="apiEnabled"
            type="checkbox"
            :disabled="readonly"
            v-model="apiIn.enabled"
            data-vv-as="Enabled"
            name="apiEnabled"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { default as ValidatorProviderMixin } from '../../mixins/ValidatorProviderMixin.vue';

export default {
  name: 'api-details',
  mixins: [ValidatorProviderMixin],
  props: {
    api: {
      type: Object,
      required: false,
      default: function () {
        return {};
      },
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      apiIn: cloneDeep(this.api),
    };
  },
  watch: {
    api: function () {
      this.resetModel();
    },
  },
  methods: {
    getModel() {
      return this.apiIn;
    },
    setModel(model) {
      this.show();
      return (this.apiIn = cloneDeep(model));
    },
    resetModel() {
      this.show();
      this.apiIn = cloneDeep(this.api);
    },
    show() {
      this.$validator.reset();
    },
  },
};
</script>
