<template>
  <div class="container-fluid p-0 m-0">
    <div class="row p-0">
      <div id="main-api-status" v-show="failureSize | pendingSize">
        <div v-show="failureSize" class="message alert alert-danger position-relative" role="alert">
          {{ failure }}
        </div>

        <div
          v-show="pendingSize"
          class="message alert alert-warning position-relative"
          role="alert"
        >
          Loading...
        </div>
      </div>

      <b-navbar class="p-0" sticky>
        <main-menu v-show="currentUser"></main-menu>
      </b-navbar>

      <main role="main" class="col p-0 ml-5">
        <router-view />
      </main>
    </div>
  </div>
</template>

<script>
import MainMenu from './layout/MainMenu.vue';
import { mapGetters } from 'vuex';
import { default as ApiMixin } from './mixins/ApiMixin.vue';

export default {
  name: 'app',
  mixins: [ApiMixin],
  computed: {
    ...mapGetters(['currentUser']),
  },
  components: {
    MainMenu,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Fira+Mono');
@import '../../scss/bootstrap-override.scss';

.form-group.row {
  min-height: 4em;
}

input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #014b69;
  opacity: 1; /* Firefox */
}
</style>

<style scoped lang="scss">
@import '../../scss/bootstrap-override.scss';

#main-logo {
  font-family: 'Fira Mono', Arial, sans-serif;
  font-size: 2em;
  font-weight: normal;
  color: #014b69;
  position: fixed;
  top: 10px;
  left: 30px;
  z-index: $zindex-fixed + 1;
  background-repeat: no-repeat;
}

#main-api-status {
  position: fixed;
  top: 0;
  left: 50%;
  z-index: 2;
}

#main-api-status > div.message {
  left: -50%;
}
</style>
