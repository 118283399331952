import * as components from '.';

export default [
  {
    path: '/inbox',
    component: components.Inbox,
  },
  {
    path: '/subscriptions',
    component: components.Subscriptions,
  },
  {
    path: '/repos/:repositoryId/subscriptions',
    component: components.SubscriptionListCompact,
    name: 'repository-subscriptions',
    props: true,
  },
  {
    path: '/repos/:repositoryId/apis/:apiId/subscriptions',
    component: components.SubscriptionListApi,
    name: 'api-subscriptions',
    props: true,
  },
];
