<template>
  <div class="col p-4">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">
          <div class="row mb-4">
            <div class="col"><h4>Account details</h4></div>
            <div class="col text-right" v-if="hasPermission(USER_MANAGEMENT)">
              <b-button
                :to="{ name: 'account-update' }"
                variant="outline-primary"
                size="sm"
                class="mx-1 font-weight-bold"
              >
                Edit
              </b-button>
            </div>
          </div>
        </h4>

        <account-details ref="accountDetails" :readonly="true"></account-details>
      </div>
    </div>

    <div class="card mt-4">
      <div class="card-body">
        <h4 class="card-title">Users</h4>

        <user-list></user-list>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UserList from '../users/UserList.vue';
import AccountDetails from './AccountDetails.vue';
import { default as ApiMixin } from '../mixins/ApiMixin.vue';
import { default as PermissionMixin } from '../mixins/PermissionMixin.vue';

export default {
  name: 'Account',
  mixins: [ApiMixin, PermissionMixin],
  components: {
    UserList,
    AccountDetails,
  },
  data() {
    return {
      account: {},
    };
  },
  computed: mapGetters(['currentUser']),
  methods: {},
};
</script>
