<template>
  <div>
    <b-modal
      id="modal"
      ref="modal"
      title="Integration Key"
      @hidden="clearState"
      @cancel="clearState"
      no-fade
      lazy
      size="lg"
    >
      <div
        v-show="secretCopied || idCopied"
        class="message alert alert-info position-relative"
        role="alert"
      >
        {{ secretCopied ? 'Secret' : 'Id' }} is copied
      </div>
      <div class="form-group row pb-3">
        <label class="col-3 col-form-label">Name</label>
        <p class="col-8 col-form-label" disabled>{{ key.name }}</p>
      </div>

      <div class="form-group row pb-3">
        <label class="col-3 col-form-label">Id</label>
        <div class="col-8 input-group padding">
          <textarea class="col-11 col-form-label" disabled v-model="key.referenceId"></textarea>
          <span class="btn col-1 input-group-text justify-content-center">
            <i
              key="referenceId"
              :class="`bi ${idCopied ? 'bi-clipboard-check' : 'bi-clipboard'}`"
              v-on:click="copy(key.referenceId, 'id')"
            ></i>
          </span>
        </div>
      </div>

      <div class="form-group row pb-3">
        <label class="col-3 col-form-label">Secret</label>
        <div class="col-8 input-group padding">
          <textarea class="col-11 col-form-label" disabled v-model="key.secret"></textarea>
          <span class="btn col-1 input-group-text justify-content-center">
            <i
              key="secret"
              :class="`bi ${secretCopied ? 'bi-clipboard-check' : 'bi-clipboard'}`"
              v-on:click="copy(key.secret, 'secret')"
            ></i>
          </span>
        </div>
      </div>

      <div class="form-group row pb-3">
        <label class="col-3 col-form-label">Blueprints</label>
        <select class="col-8" multiple>
          <option v-for="id in blueprintsIds" disabled :key="id">{{ id }}</option>
        </select>
      </div>

      <div class="form-group row pb-3">
        <label class="col-3 col-form-label">Created on</label>
        <label class="col-8 col-form-label" disabled>{{ formatInstant(key.createdOn) }}</label>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { default as ApiMixin } from '../../../mixins/ApiMixin.vue';
import { formatInstant } from '../../../dates';

export default {
  name: 'KeyView',
  mixins: [ApiMixin],
  data() {
    return {
      blueprintsIds: [],
      idCopied: false,
      key: {},
      secretCopied: false,
    };
  },
  methods: {
    async copy(value, field) {
      try {
        await navigator.clipboard.writeText(value).then(() => {
          if (field === 'secret') {
            this.idCopied = false;
            this.secretCopied = true;
          } else {
            this.idCopied = true;
            this.secretCopied = false;
          }
        });
      } catch ($e) {
        alert('Cannot copy');
      }
    },
    formatInstant,
    show: function (shownKey) {
      this.key = shownKey;
      this.blueprintsIds = shownKey.claims.map((claim) => {
        return claim.split(',')[1];
      });
      this.$refs.modal.show();
    },
    clearState: function () {
      this.blueprintsIds = [];
      this.idCopied = false;
      this.key = {};
      this.secretCopied = false;
    },
  },
};
</script>

<style>
.padding {
  padding: 0;
  margin: 0;
}
</style>
