/**
 * Mutations are operations that actually mutates the state.
 * Each mutation handler gets the entire state tree as the first argument, followed by additional payload arguments.
 * Mutations must be synchronous and can be recorded by plugins for debugging purposes.
 **/

import Vue from 'vue';
import _ from 'lodash';
import { Auth } from '../auth/auth';

const arrayToMap = (idFunction, array) => {
  if (array instanceof Array) {
    return array.reduce((acc, el) => {
      acc[idFunction(el)] = el;
      return acc;
    }, {});
  } else if (array instanceof Map) {
    return array;
  } else return null;
};

export default {
  setCurrentUser(state, currentUser) {
    state.currentUser = currentUser;
    if (state.currentUser) {
      state.currentUser.permissions = Auth.getPermissions();
      state.currentUser.limitations = Auth.getLimitations();
    }
  },
  setApiState(state, payload) {
    const type = payload.type;
    const component = payload.component;
    const api_state = payload.state;

    // make sure [name] section is there
    if (!state.api_state[type]) {
      Vue.set(state.api_state, type, {});
    }
    // set section record as 'component':'url'
    Vue.set(state.api_state[type], component, api_state);
  },
  removeApiState(state, payload) {
    _.forOwn(state.api_state, (type) => {
      Vue.delete(type, payload);
    });
  },
  setMySubscriptions(state, subscriptions) {
    state.mySubscriptionsMap = arrayToMap((x) => x.identifier, subscriptions);
    state.mySubscriptionsMapVersion++;
  },
  setSubscriptionsToMe(state, subscriptions) {
    state.subscriptionsToMeMap = arrayToMap((x) => x.identifier, subscriptions);
    state.subscriptionsToMeMapVersion++;
  },
  updateSubscription(state, subscription) {
    if (subscription.resourceOwnerId === state.currentUser.accountId) {
      state.subscriptionsToMeMap[subscription.identifier] = subscription;
      state.subscriptionsToMeMapVersion++;
    } else {
      state.mySubscriptionsMap[subscription.identifier] = subscription;
      state.mySubscriptionsMapVersion++;
    }
  },
  backupBlueprint(state) {
    state.blueprintBackup = { ...state.blueprint };
  },
  revertBlueprint(state) {
    state.blueprint = { ...state.blueprintBackup };
  },
  setBlueprints(state, blueprints) {
    state.blueprintsMap = arrayToMap((x) => x.id, blueprints);
  },
  setBlueprint(state, blueprint) {
    state.blueprint = blueprint;
  },
  setProjects(state, projects) {
    state.projectsMap = arrayToMap((x) => x.id, projects);
  },
  setAuthTemplates(state, templates) {
    state.authTemplates = arrayToMap((x) => x.id, templates);
  },
  setProject(state, project) {
    if (!project || state.project?.id !== project.id) {
      state.blueprintsMap = {};
      state.installsMap = {};
      state.logsMap = {};
    }
    state.project = project || {};
  },
  setInstalls(state, installs) {
    state.installsMap = arrayToMap((x) => x.referenceId, installs);
  },
  setLogs(state, payload) {
    const newLogs = arrayToMap((x) => x.id, payload);
    state.logsMap = { ...state.logsMap, ...newLogs };
  },
  clearLogs(state) {
    state.logsMap = {};
  },
  setTransform(state, transform) {
    state.transform = transform;
  },
  setTransforms(state, transformsMap) {
    state.transformsMap = transformsMap;
  },
  setConnectors(state, connectors) {
    state.connectorsMap = arrayToMap((x) => x.id, connectors);
  },
  reset(state) {
    state.currentUser = null;
    state.mySubscriptionsMap = new Map();
    state.mySubscriptionsMapVersion = 1;
    state.subscriptionsToMeMap = new Map();
    state.subscriptionsToMeMapVersion = 1;
  },
};
