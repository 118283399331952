<template>
  <div class="w-25 my-4 mx-auto">
    <div class="row justify-content-center">
      <h3>{{ title }}</h3>
    </div>

    <b-alert :show="error" variant="danger" class="mx-auto">
      {{ error }}
    </b-alert>

    <div class="card" v-if="tokenValid">
      <div class="card-body">
        <form v-on:submit.prevent="submit">
          <div class="form-group justify-content-center text-muted">
            Use 8 or more characters with a mix of letters, numbers & symbols
          </div>

          <div class="form-group space-for-feedback">
            <label class="col-form-label" for="password">Password</label>
            <input
              id="password"
              :class="[{ 'is-invalid': errors.has('password') }, 'form-control']"
              v-model="form.password"
              v-validate="'required|passwordStrong'"
              data-vv-as="Password"
              name="password"
              type="password"
            />
            <div class="invalid-feedback">{{ errors.first('password') }}</div>
          </div>

          <div class="form-group space-for-feedback">
            <label class="col-form-label" for="password-confirm">Confirm Password</label>
            <input
              id="password-confirm"
              :class="[{ 'is-invalid': errors.has('passwordConfirm') }, 'form-control']"
              v-model="form.passwordConfirm"
              v-validate="'required|passwordMatch:password'"
              data-vv-as="Password Confirmation"
              name="passwordConfirm"
              type="password"
            />
            <div class="invalid-feedback">{{ errors.first('passwordConfirm') }}</div>
          </div>

          <div class="form-group mb-0">
            <b-button type="submit" class="w-100" variant="outline-primary">
              {{ submitBtnTitle }}
            </b-button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { default as ApiMixin } from '../mixins/ApiMixin.vue';

export default {
  name: 'UserActivate',
  mixins: [ApiMixin],
  props: {
    existing: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      form: {
        password: '',
        passwordConfirm: '',
      },
      token: '',
      tokenValid: false,
      title: null,
      submitBtnTitle: null,
      error: null,
    };
  },
  mounted() {
    this.token = this.$route.params.token;
    this.userApi()
      .validateToken(this.token)
      .then(() => {
        this.tokenValid = true;
        this.error = null;
      })
      .catch(() => {
        this.tokenValid = false;
        this.error = 'It looks like you clicked on an invalid link. Please try again';
        this.clearApiState();
      });

    if (this.existing) {
      this.title = 'Change password';
      this.submitBtnTitle = 'Reset';
    } else {
      this.title = 'Activate user account';
      this.submitBtnTitle = 'Activate';
    }
  },
  methods: {
    submit: function () {
      this.$validator.errors.clear();
      this.$validator.validateAll().then((allValid) => {
        if (allValid) {
          this.activate();
        }
      });
    },
    activate: function () {
      this.userApi()
        .activate(this.token, this.form, this.existing)
        .then(() => this.$router.push({ name: 'login' }));
    },
  },
};
</script>

<style scoped>
.space-for-feedback {
  min-height: 4em;
}
</style>
