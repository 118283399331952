<template>
  <div class="col blueprint-config-card">
    <div class="p-3 row h-75">
      <h3>
        {{ connectorType }}
      </h3>
      <div>
        <img src="../../../../images/newproj.png" alt="" />
      </div>
      <button name="newProject" class="config-button" @click="editConnector(connectorType)">
        <span>{{ `Edit ${connectorType}` }}</span>
      </button>
    </div>
    <hr class="mt-0" />
    <div class="row">
      <button name="editTransform" class="config-button" @click="editTransform(connectorType)">
        <span>Configure</span>
      </button>
      <label>/</label>
      <button name="changeTransform" class="config-button" @click="chooseTransform(connectorType)">
        <span>Choose Transform</span>
      </button>
    </div>
  </div>
</template>

<script>
import { default as ApiMixin } from '../../mixins/ApiMixin.vue';

export default {
  name: 'ProjectCard',
  mixins: [ApiMixin],
  props: {
    imgSource: {
      type: String,
      required: false,
    },
    connectorType: {
      type: String,
      required: false,
    },
  },
  methods: {
    editConnector: function (connectorType) {
      this.$emit('editConnector', connectorType);
    },
    editTransform: function (connectorType) {
      this.$emit('editTransform', connectorType);
    },
    chooseTransform: function (connectorType) {
      this.$emit('chooseTransform', connectorType);
    },
  },
};
</script>
<style>
.label {
  display: flex;
  position: absolute;
  top: 3rem;
  left: 5rem;
}

.config-button {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border-width: 0;
  margin: auto;
  height: 2rem;
  min-width: 64px;
  background-color: #354e68;
  color: #ffffff;
}

.blueprint-config-card {
  position: relative;
  margin-top: 1rem;
  margin-right: 3rem;
  height: 40vh;
  min-height: 320px;
  background-color: white;
  border-radius: 4px;
  border: 2px solid #e9e9e9;
}
</style>
