<template>
  <div class="col">
    <b-breadcrumb
      :items="[
        { text: 'Repositories', to: { name: 'repo-list' } },
        { text: repositoryId, to: { name: 'repo-edit' } },
        { text: 'Tokens', active: true },
      ]"
    />
    <div class="row">
      <div class="col"><h4>Tokens</h4></div>
      <div class="col text-right">
        <b-button @click.prevent="showAddTokenDialog" variant="outline-primary" size="sm"
          >New token</b-button
        >
      </div>
    </div>
    <tokens-table :tokens="tokens" @showTokenClicked="showToken" />
    <new-data-token-dialog
      ref="newTokenDialog"
      :repositoryId="repositoryId"
      @newTokenCreated="onNewTokenCreated"
    />
    <refresh-token ref="tokenModal" />
    <route-button text="Back" :route="{ name: 'repo-list' }"></route-button>
  </div>
</template>

<script>
import { BBreadcrumb } from 'bootstrap-vue';
import RouteButton from '../../components/RouteButton.vue';
import RefreshToken from '../../components/RefreshToken.vue';
import TokensTable from '../../tokens/TokensTable.vue';
import NewDataTokenDialog from '../../tokens/NewDataToken.vue';
import { default as ApiMixin } from '../../mixins/ApiMixin.vue';

export default {
  name: 'RepositoryDataTokens',
  mixins: [ApiMixin],
  components: {
    BBreadcrumb,
    RouteButton,
    RefreshToken,
    TokensTable,
    NewDataTokenDialog,
  },
  props: {
    repositoryId: {
      required: true,
    },
  },
  data() {
    return {
      tokens: null,
    };
  },
  async mounted() {
    this.tokens = await this.tokensApi().getDataTokens(this.repositoryId);
  },
  methods: {
    showAddTokenDialog: function () {
      this.$refs.newTokenDialog.show();
    },
    onNewTokenCreated: function (newToken) {
      this.tokens.push(newToken);
      this.showToken(newToken.token);
    },
    showToken(token) {
      this.$refs.tokenModal.open(token);
    },
  },
};
</script>
