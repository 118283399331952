<template>
  <modal-dlg
    id="modal"
    ref="modal"
    title="Configure triggers"
    :submit="updateTrigger"
    :validator="$validator"
    :ok-disabled="hasErrors"
    @hidden="clearState"
    @shown="() => this.$refs.triggerNameInput"
    @cancel="clearState"
    size="lg"
    no-fade
    lazy
  >
    <div class="row font__color">
      <div class="col col-4 border-right mr-2 pr-2">
        <label class="row pb-1">Triggers</label>
        <div
          :class="`row border ${selectedApi === index ? 'bg-light' : ''}`"
          v-for="(api, index) in apis"
          :key="index"
        >
          <label class="btn row col-9 m-1 font__color" @click="selectedApi = index">
            {{ api.name }}
          </label>
          <i class="text-black-50 btn bi bi-trash" @click="removeTrigger(index)" />
        </div>
        <label class="row btn d-flex justify-content-center" @click="addTrigger">
          + new trigger
        </label>
      </div>
      <div style="min-height: 60vh" class="col pr-2 pre-scrollable" v-if="apis.length">
        <modal-input
          :item="apis[selectedApi].name"
          name="name"
          label="Trigger Name"
          @changeValue="changeValue"
        />
        <modal-input
          :item="apis[selectedApi].path"
          name="path"
          label="HTTP Path"
          @changeValue="changeValue"
        />
        <div class="form-group col pb-2">
          <div class="row">
            <label>HTTP METHOD</label>
          </div>
          <div class="row">
            <select
              key="selectHTTPMethod"
              v-model="apis[selectedApi].method"
              class="w-100 form-control"
            >
              <option disabled>Select method</option>
              <option v-for="(method, index) in httpMethods" :key="index">{{ method }}</option>
            </select>
          </div>
        </div>
        <div class="form-group col pb-2">
          <div class="row">
            <label>Authorization Type </label>
          </div>
          <div class="row">
            <select key="selectAuthType" class="w-100 form-control" v-model="selectedConfig">
              <option disabled>Select authentication type</option>
              <option v-for="(template, index) in authTemplates" :value="template" :key="index">
                {{ template.name }}
              </option>
            </select>
          </div>
        </div>
        <div v-if="!!selectedConfig && selectedConfig.name" class="pl-3 pr-3">
          <label>Configure authorization</label>
          <div
            v-for="(parameter, index) in Object.keys(selectedConfig.authTemplate.config)"
            class="form-group col"
            :key="index"
          >
            <label>{{ parameter }}</label>
            <input
              ref="parameter"
              :class="[{ 'is-invalid': errors.has(parameter) }, 'form-control']"
              v-model="authConfig[parameter]"
              v-validate="'required'"
              :name="parameter"
              type="text"
            />
            <div class="invalid-feedback" v-show="errors.has(parameter)"></div>
          </div>
        </div>
      </div>
      <div v-else class="d-flex justify-content-center align-content-center">
        <label>No triggers for this blueprint</label>
      </div>
    </div>
  </modal-dlg>
</template>

<script>
import { default as ApiMixin } from '../../mixins/ApiMixin.vue';
import { default as ModalDlg } from '../../components/ModalDlg.vue';
import { default as ModalInput } from './ModalInput.vue';
import { mapActions, mapGetters } from 'vuex';

const httpMethods = ['POST', 'PUT', 'GET', 'DELETE'];

export default {
  name: 'EditTriggers',
  mixins: [ApiMixin],
  components: {
    ModalDlg,
    ModalInput,
  },
  props: {
    blueprintId: {
      type: String,
      required: true,
    },
  },
  watch: {
    selectedConfig(newVal, prevVal) {
      if (
        !!newVal &&
        newVal !== prevVal &&
        this.apis[this.selectedApi]?.authenticationConfig.type !== newVal.name
      ) {
        this.apis[this.selectedApi].authenticationConfig =
          newVal.name === 'apikey' ? { type: newVal.name, config: {} } : { type: newVal.name };
      }
    },
    selectedApi(newVal, prevVal) {
      if (newVal !== prevVal) {
        this.selectedConfig = this.authTemplates.find(
          (aType) => aType.name === this.apis[newVal].authenticationConfig.type,
        );
      }
    },
  },
  computed: {
    ...mapGetters(['authTemplates']),
    hasErrors() {
      return this.apis.some((api) =>
        Object.values(api).some(
          (value) =>
            value === '' ||
            (!!value &&
              Object.values(
                value.authenticationConfig?.config || value.authenticationConfig || {},
              ).some((key) => key === '')),
        ),
      );
    },
    authConfig() {
      return this.selectedConfig.name === 'apikey'
        ? this.apis[this.selectedApi].authenticationConfig.config
        : this.apis[this.selectedApi].authenticationConfig;
    },
  },
  data() {
    return {
      apis: [],
      httpMethods,
      selectedConfig: {},
      selectedApi: 0,
    };
  },
  async mounted() {
    if (!this.authTemplates.length) {
      await this.loadAuthTemplates();
    }
  },
  methods: {
    ...mapActions(['loadAuthTemplates']),
    clearState() {
      this.apis = [];
      this.selectedConfig = {};
      this.selectedApi = 0;
    },
    async show() {
      this.apis = await this.dataApiApi().getApisByBlueprintId(this.blueprintId);
      if (this.apis.length) {
        this.selectedConfig = this.authTemplates.find(
          (aType) => aType.name === this.apis[0].authenticationConfig.type,
        );
      }
      this.$refs.modal.show();
    },
    addTrigger() {
      this.apis.push({ name: '', method: '', path: '', authenticationConfig: {} });
      this.selectedApi = this.apis.length - 1;
    },
    updateTrigger() {
      this.apis.forEach((api) => {
        if (api?.id) {
          this.dataApiApi().update(api);
        } else {
          const newTrigger = {
            ...api,
            blueprintId: this.blueprintId,
          };
          this.dataApiApi()
            .createNewApi(this.blueprintId, newTrigger)
            .then((trigger) => this.apis.push(trigger));
        }
      });
    },
    changeValue(target) {
      this.apis[this.selectedApi][target.name] = target.value;
    },
    async removeTrigger(index) {
      const id = this.apis[index].id;
      if (id) {
        await this.dataApiApi().softDeleteApi(id);
      }
      this.apis = this.apis.filter((_, i) => i !== index);
      this.selectedApi = 0;
      this.selectedConfig = {};
    },
  },
};
</script>

<style>
.font__color label {
  color: #8a8a8a;
}
</style>
