<template>
  <div class="col">
    <div v-if="subscriptionsMap.size === 0">
      <div class="col"><h4>Subscriptions</h4></div>
      <p class="col">Nothing is here yet</p>
    </div>

    <api-subscription-list
      v-if="apiSubscriptions.length"
      :subscriptions="apiSubscriptions"
      :mySubscriptions="false"
      @subscriptionChange="updateSubscription"
      title="Api subscriptions"
    >
    </api-subscription-list>

    <repository-subscription-list
      v-if="repositorySubscriptions.length"
      :subscriptions="repositorySubscriptions"
      :mySubscriptions="false"
      @subscriptionChange="updateSubscription"
      title="Repository subscriptions"
    >
    </repository-subscription-list>
  </div>
</template>

<script>
import { default as ApiMixin } from '../../mixins/ApiMixin.vue';
import { mapGetters } from 'vuex';
import ApiSubscriptionList from './ApiSubscriptionList.vue';
import RepositorySubscriptionList from './RepositorySubscriptionList.vue';

export default {
  name: 'SubscriptionListApi',
  mixins: [ApiMixin],
  components: {
    ApiSubscriptionList,
    RepositorySubscriptionList,
  },
  props: {
    repositoryId: {
      required: true,
    },
    apiId: {
      required: true,
    },
  },
  data() {
    return {
      changeTracker: 1, // This hack should be fixed in Vue3
      subscriptionsMap: new Map(),
    };
  },
  computed: {
    ...mapGetters(['currentUser']),
    apiSubscriptions: function () {
      return (
        this.changeTracker &&
        Array.from(this.subscriptionsMap.values()).filter((subscription) => subscription.api)
      );
    },
    repositorySubscriptions: function () {
      return (
        this.changeTracker &&
        Array.from(this.subscriptionsMap.values()).filter((subscription) => subscription.repository)
      );
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.subscriptionApi()
        .getApiSubscriptions(this.repositoryId, this.apiId)
        .then(
          (subscriptions) =>
            (this.subscriptionsMap = new Map(subscriptions.map((i) => [i.identifier, i]))),
        );
    },
    updateSubscription(subscription) {
      this.subscriptionsMap.set(subscription.identifier, subscription);
      this.changeTracker += 1;
    },
  },
};
</script>
