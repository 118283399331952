import format from 'string-format';
import { Api } from './Api';

class KeysApi extends Api {
  static URL_INTEGRATIONS_KEYS = 'integrations/keys';
  static URL_DELETE_KEYS = 'integrations/keys/{referenceId}';
  static URL_GET_KEYS_BY_PROJECT_ID = 'integrations/projects/{projectId}/keys';
  static URL_GET_KEYS_BY_TENANT_ID =
    'integrations/projects/{projectId}/installations/keys?tenantId={tenantId}';

  createAccessKey(keyForm) {
    const keysUrl = format(KeysApi.URL_INTEGRATIONS_KEYS);
    return super.post(keysUrl, keyForm);
  }

  deleteKey(referenceId) {
    const deleteKeyUrl = format(KeysApi.URL_DELETE_KEYS, { referenceId });
    return super.delete(deleteKeyUrl);
  }

  getIntegrationsKeysByProjectId(projectId) {
    const url = format(KeysApi.URL_GET_KEYS_BY_PROJECT_ID, { projectId });
    return super.get(url);
  }

  getIntegrationsKeysByTenantId(projectId, tenantId) {
    const url = format(KeysApi.URL_GET_KEYS_BY_TENANT_ID, { projectId, tenantId });
    return super.get(url);
  }
}

export default KeysApi;
