<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col text-left">
            <h2>Indexes</h2>
          </div>
          <div class="col w-50 text-right">
            <b-button
              :disabled="!new_index_enabled"
              @click.prevent="openModal"
              variant="outline-primary"
              size="sm"
              >New Index</b-button
            >
          </div>
        </div>

        <table class="table table-hover">
          <tbody>
            <tr v-for="(obj, index) in indexes" :key="index" class="row">
              <td class="col">{{ obj.name }}</td>

              <td class="col-auto">
                <b-dropdown variant="link" no-caret size="sm">
                  <template v-slot:button-content
                    ><img :src="require('../../../../images/gear.png')" />
                  </template>
                  <b-dd-item @click="deleteIndex(index)">delete</b-dd-item>
                </b-dropdown>
              </td>
            </tr>
          </tbody>
        </table>

        <b-modal ref="modalAdd" title="Index" @ok="addIndex" @cancel="resetChanges" no-fade>
          <div class="form-group" v-for="(field, idx) in field_list" :key="idx">
            <input type="checkbox" :value="field.name" :id="fieldId(idx)" v-model="checkedFields" />
            <label :for="fieldId(idx)">{{ field.name }}</label>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IndexList',
  props: {
    indexes: {
      required: true,
      default: [],
    },
    field_list: {
      required: true,
    },
    new_index_enabled: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      checkedFields: [],
      error: null,
    };
  },
  mounted() {},
  methods: {
    openModal() {
      this.$refs.modalAdd.show();
    },
    addIndex() {
      this.$emit('add', this.checkedFields);
      this.resetChanges();
    },
    resetChanges() {
      this.checkedFields = [];
    },
    deleteIndex: function (idx) {
      this.$emit('delete', idx);
    },
    fieldId(index) {
      return 'field-' + index;
    },
  },
};
</script>
