<template>
  <div>
    <modal-dlg
      id="modal"
      ref="modal"
      :title="`New ${transformType} transform`"
      :ok-disabled="!this.name || !this.description"
      :submit="createTransform"
      :validator="$validator"
      size="md"
      no-fade
      lazy
      @hidden="clearState"
      @shown="() => this.$refs.newTranform"
      @cancel="clearState"
    >
      <div v-if="!isNew" class="justify-content-center">
        <label class="row pb-1">Choose existing transform or create a new one</label>
        <div v-for="(transform, index) in transforms" :key="index">
          <label
            class="btn link row col-12 m-1 font__color border"
            @click="chooseTransform(transform)"
          >
            {{ transform.name }}
          </label>
        </div>
        <div class="d-flex justify-content-center pt-2">
          <button @click="() => (isNew = true)">+ New transform</button>
        </div>
      </div>
      <div style="min-height: 50vh" v-if="isNew">
        <modal-input :item="name" name="name" label="Name" @changeValue="change" />
        <modal-input
          :item="description"
          name="description"
          label="Description"
          @changeValue="change"
        />
        <div class="form-group">
          <div class="row">
            <b-form-checkbox
              id="new-transform-checkbox"
              v-model="needVersionName"
              name="new-transform-checkbox"
            >
              Is version name needed?
            </b-form-checkbox>
          </div>
        </div>
        <div v-if="needVersionName">
          <modal-input
            :item="versionName"
            name="versionName"
            label="Version name"
            @changeValue="change"
          />
        </div>
        <i
          class="btn bi-chevron-left position-absolute fixed-bottom"
          @click="() => (isNew = false)"
        >
          back to transforms list
        </i>
      </div>
    </modal-dlg>
  </div>
</template>

<script>
import { default as ApiMixin } from '../../mixins/ApiMixin.vue';
import { default as ModalDlg } from '../../components/ModalDlg.vue';
import { default as ModalInput } from './ModalInput.vue';

export default {
  name: 'NewTransform',
  mixins: [ApiMixin],
  components: {
    ModalDlg,
    ModalInput,
  },
  data() {
    return {
      name: '',
      isNew: false,
      description: '',
      versionName: '',
      needVersionName: false,
      transformType: '',
      transforms: [],
    };
  },
  props: {
    blueprintId: {
      type: String,
      required: true,
    },
  },
  methods: {
    clearState: function () {
      this.description = '';
      this.name = '';
      this.isNew = false;
      this.needVersionName = false;
      this.versionName = '';
    },
    show: async function (transformType) {
      this.transforms = await this.integrationApi().getTransforms(this.blueprintId);
      if (!this.transforms.length) {
        this.isNew = true;
      }
      this.transformType = transformType;
      this.$refs.modal.show();
    },
    change: function (target) {
      this[target.name] = target.value;
    },
    chooseTransform: function (transform) {
      this.$emit('createNewTransform', transform);
    },
    createTransform: function () {
      const newTransform = {
        name: this.name,
        blueprintId: this.blueprintId,
        description: this.description,
        code: {},
      };
      if (this.needVersionName) {
        newTransform.versionName = this.versionName;
      }
      this.$emit('createNewTransform', newTransform);
    },
  },
};
</script>
