<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-1">
          <img class="repository-icon" :src="require('../../../images/api.png')" />
        </div>

        <div class="col-11">
          <div class="row">
            <div class="col text-left">
              <h4>{{ repository.name }}</h4>
            </div>
          </div>

          <div class="row mt-3" v-if="repository.owner">
            <div class="col-auto font-weight-bold">Provided by:</div>
            <div class="col">{{ repository.owner.name }}</div>
            <subscription-manipulation-button
              v-if="subscription"
              :subscription="subscription"
              col-auto
              text-right
            />
          </div>

          <div class="row mt-3">
            <div class="col-auto font-weight-bold">Overview</div>
          </div>
          <div class="row mt-1">
            <div class="col">{{ repository.description }}</div>
          </div>

          <div v-if="isSubscribed">
            <div class="row mt-3">
              <div class="col-auto font-weight-bold">Usage</div>
            </div>

            <div class="row mt-3">
              <div class="col-auto">
                <b-link @click.prevent="showToken()">Download key</b-link>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-auto font-weight-bold">Available tables</div>
            </div>

            <div v-for="table in repository.tables" class="ml-5" :key="table.referenceId">
              <div class="row mt-3">
                <div class="col-auto">{{ table.name }}</div>
              </div>
              <div v-for="field in table.fields" class="row pl-5" :key="field.id">
                <div class="col-2">
                  {{ field.name }}
                </div>
                <div class="col-auto">
                  {{ getTypeName(field.type) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <refresh-token ref="tokenModal"></refresh-token>
  </div>
</template>

<script>
import { default as ApiMixin } from '../mixins/ApiMixin.vue';
import { mapGetters } from 'vuex';
import { SubscriptionStatus } from '../data/subscriptions/statuses.js';
import * as Fields from '../data/tables/fields.js';
import { default as RefreshToken } from '../components/RefreshToken.vue';
import SubscriptionManipulationButton from '../components/SubscriptionManipulationButton.vue';

export default {
  name: 'RepositorySubscriberInfo',
  mixins: [ApiMixin],
  components: {
    RefreshToken,
    SubscriptionManipulationButton,
  },
  props: {
    repositoryId: {
      required: true,
    },
  },
  computed: {
    ...mapGetters(['currentUser', 'myRepositorySubscriptionsByRepository']),
    subscription: function () {
      const val = this.myRepositorySubscriptionsByRepository(this.repositoryId);
      return val;
    },
    status() {
      return this.subscription?.status;
    },
    isSubscribed() {
      return this.subscription?.status === SubscriptionStatus.APPROVED;
    },
  },
  data() {
    return {
      repository: {},
      SubscriptionStatus,
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.catalogApi()
        .getRepository(this.repositoryId)
        .then((repository) => (this.repository = repository));
    },
    getTypeName(type) {
      return Fields.Type.getType(type).text;
    },
    showToken() {
      this.subscriptionApi()
        .getRefreshToken(this.repository.subscription.identifier)
        .then((token) => this.$refs.tokenModal.open(token));
    },
    cloneRepository() {
      this.dataRepositoryApi().cloneRepository(this.repositoryId);
    },
  },
};
</script>
