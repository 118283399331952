var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('b-breadcrumb',{attrs:{"items":[
      { text: 'Repositories', to: { name: 'repo-list' } },
      { text: _vm.repositoryId, to: { name: 'repo-edit' } },
      { text: 'APIs', to: { name: 'apis' } },
      { text: _vm.apiId, active: true },
    ]}}),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col w-50 text-right"},[_c('b-button',{attrs:{"variant":"outline-primary","size":"sm"},on:{"click":function($event){$event.preventDefault();return _vm.editDetails.apply(null, arguments)}}},[_vm._v(" Edit ")])],1)]),_c('api-details',{attrs:{"api":_vm.api,"readonly":""}})],1)]),_c('b-modal',{ref:"modalDetails",attrs:{"title":"API","no-fade":"","static":""},on:{"ok":_vm.saveDetails,"cancel":_vm.resetDetails}},[_c('api-details',{ref:"apiEdit",attrs:{"api":_vm.api}})],1),_c('api-usage',{staticClass:"my-2",attrs:{"repository-id":_vm.repositoryId,"api-id":_vm.apiId}}),_c('div',{staticClass:"row my-4"},[_c('div',{staticClass:"col"},[_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){$event.preventDefault();return _vm.$router.push({ name: 'repo-edit', params: { repositoryId: _vm.repositoryId } })}}},[_vm._v(" Back ")])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col text-left"},[_c('h2',[_vm._v("API")])])
}]

export { render, staticRenderFns }