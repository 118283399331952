<template>
  <div>
    <div class="form-group row">
      <label class="col-3 col-form-label" for="repoName">Name</label>
      <div class="col">
        <input
          id="repoName"
          ref="repoNameInput"
          :class="[
            { 'is-invalid': errors.has('repoName') },
            readonly ? 'form-control-plaintext' : 'form-control',
          ]"
          :readonly="readonly"
          v-model="repoIn.name"
          v-validate="'required'"
          data-vv-as="Name"
          name="repoName"
          type="text"
        />
        <div class="invalid-feedback" v-show="errors.has('repoName')">
          {{ errors.first('repoName') }}
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-3 col-form-label" for="Type">Type</label>
      <div class="col">
        <b-form-select
          id="type"
          :class="[
            { 'is-invalid': errors.has('type') },
            readonly ? 'form-control-plaintext' : 'form-control',
          ]"
          v-model="repoIn.type"
          :options="types"
          v-validate="'required'"
          data-vv-validate-on="change"
          data-vv-as="Type"
          data-vv-name="type"
        />
        <div class="invalid-feedback" v-show="errors.has('type')">
          {{ errors.first('type') }}
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-3 col-form-label" for="repoDescription">Description</label>
      <div class="col">
        <textarea
          id="repoDescription"
          :readonly="readonly"
          :class="[readonly ? 'form-control-plaintext' : 'form-control']"
          v-model="repoIn.description"
          data-vv-as="Description"
          name="repoDescription"
          rows="3"
          cols="50"
          maxlength="1000"
        >
        </textarea>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';

export default {
  name: 'repo-details',
  props: {
    repo: {
      type: Object,
      required: false,
      default: function () {
        return {};
      },
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    types: () => [
      { value: 'Source', text: 'Source' },
      { value: 'Reference', text: 'Reference' },
    ],
  },
  data() {
    return {
      error: null,
      repoIn: cloneDeep(this.repo),
    };
  },
  watch: {
    repo: function () {
      this.resetModel();
    },
  },
  methods: {
    getModel() {
      return this.repoIn;
    },
    setModel(model) {
      this.repoIn = cloneDeep(model);
    },
    resetModel() {
      this.repoIn = cloneDeep(this.repo);
    },
    shown() {
      this.$refs.repoNameInput.focus();
    },
  },
};
</script>
