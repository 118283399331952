<template>
  <div class="col">
    <div class="row col">
      <i
        class="btn bi-chevron-left"
        v-on:click="
          $router.push({
            name: 'editBlueprint',
          })
        "
        style="color: #737373"
      >
        Back to blueprint
      </i>
    </div>
    <div class="row col justify-content-center pb-3">
      <h3 class="ml-3">{{ transform.name }}</h3>
      <button
        class="ml-2 btn btn-primary"
        @click="updateTransform"
        :disabled="!transform.accountId"
      >
        Update transform
      </button>
      <button class="ml-2 btn btn-primary" @click="testTransform">Test transform</button>
      <select
        v-if="testObjects.length"
        key="selectAuthType"
        class="ml-2 form-control col-2"
        v-model="selectedRun"
        placeholder="Select Run Id"
      >
        <option :value="null">Select Run Id</option>
        <option v-for="(object, index) in testObjects" :value="object" :key="index">
          {{ object.runId }}
        </option>
      </select>
    </div>
    <div class="row" style="min-height: 50vh">
      <div class="col-6">
        <h4 class="text-center">Transform</h4>
        <CodeEditor v-model="transform.code" />
      </div>
      <div class="col-6">
        <h4 class="text-center">Test object</h4>
        <CodeEditor v-model="testObject" />
      </div>
    </div>
    <b-modal
      ref="testResult"
      title="Test result"
      centered
      ok-only
      static
      scrollable
      style="max-height: 40vh"
      @ok="closeModal"
    >
      <CodeEditor :model-value="testResult" :height="400" readonly />
    </b-modal>
  </div>
</template>

<script>
import { default as ApiMixin } from '../mixins/ApiMixin';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import CodeEditor from '../components/CodeEditor.vue';

export default {
  name: 'EditTransform',
  mixins: [ApiMixin],
  components: {
    CodeEditor,
  },
  computed: {
    ...mapGetters(['transform']),
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    blueprintId: {
      type: String,
      required: true,
    },
    transformId: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      testObject: {},
      testObjects: [],
      testResult: null,
      selectedRun: null,
    };
  },

  watch: {
    selectedRun() {
      this.setTestObject();
    },
  },

  async mounted() {
    if (!this.transformId || !this.blueprintId || !this.type) {
      this.$router.push({ name: 'blueprints' });
      return;
    }

    await this.loadTransform({ blueprintId: this.blueprintId, transformId: this.transformId });

    this.testObjects = await this.auditApi().getAuditByBlueprintLogs(
      this.id,
      this.blueprintId,
      10,
      0,
    );

    this.prefillRun();

    document.addEventListener('keydown', this.handleCmdEnter);
  },

  unmounted() {
    document.removeEventListener('keydown', this.handleCmdEnter);
  },

  methods: {
    ...mapActions(['loadTransform', 'loadProjectLogData']),
    ...mapMutations(['setTransform']),

    async prefillRun() {
      if (this.$route.query.runId) {
        this.selectedRun = this.testObjects.find((obj) => obj.runId === this.$route.query.runId);
        await this.changeTestObject(this.$route.query.runId, this.selectedRun.id);
      }
    },

    closeModal() {
      this.testResult = null;
      this.$refs.testResult.hide();
    },

    async testTransform() {
      try {
        this.testResult = await this.integrationApi().testTransform(
          this.transform.code,
          this.testObject,
        );
      } catch (error) {
        this.testResult = error;
      } finally {
        this.$refs.testResult.show();
      }
    },

    async setTestObject() {
      if (!this.selectedRun) {
        this.testObject = {};
        return;
      }

      const test = await this.auditApi().getProjectLogData(
        this.id,
        this.selectedRun.runId,
        this.selectedRun.id,
      );

      this.testObject = test[`${this.type}Data`];
    },

    async updateTransform() {
      const updatedTransform = await this.integrationApi().updateTransform(this.transform);

      this.setTransform(updatedTransform);
    },

    handleCmdEnter: function (e) {
      if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) {
        this.testTransform();
      }
    },
  },
};
</script>
