<template>
  <div class="col">
    <div class="row">
      <div class="col"><h4>Subscriptions</h4></div>
      <div class="col text-right">
        <b-button
          :disabled="availableApis.length === 0"
          v-b-tooltip.hover
          :title="availableApis.length === 0 ? 'No APIs' : null"
          @click.prevent="invite"
          variant="outline-primary"
          size="md"
          >Invite</b-button
        >
      </div>
    </div>
    <p v-if="isEmpty">Nothing is here yet</p>

    <api-subscription-list
      v-if="apiSubscriptions.length"
      :subscriptions="apiSubscriptions"
      :mySubscriptions="false"
      title="Subscriptions"
    >
    </api-subscription-list>

    <repository-subscription-list
      v-if="repositorySubscriptions.length"
      :subscriptions="repositorySubscriptions"
      :mySubscriptions="false"
      title="Repository subscriptions"
    >
    </repository-subscription-list>
    <invite-to-subscribe-dialog ref="modalInvite" :apis="availableApis" />
  </div>
</template>

<script>
import { default as ApiMixin } from '../../mixins/ApiMixin.vue';
import { mapGetters, mapMutations } from 'vuex';
import InviteToSubscribeDialog from './InviteToSubscribeDialog.vue';
import ApiSubscriptionList from './ApiSubscriptionList.vue';
import RepositorySubscriptionList from './RepositorySubscriptionList.vue';
import { VBTooltip } from 'bootstrap-vue';

export default {
  name: 'SubscriptionListCompact',
  mixins: [ApiMixin],
  components: {
    InviteToSubscribeDialog,
    ApiSubscriptionList,
    RepositorySubscriptionList,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    repositoryId: {
      required: true,
    },
  },
  data() {
    return {
      availableApis: [],
    };
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'apiSubscriptionsToMeByRepository',
      'repositorySubscriptionsToMeByRepository',
    ]),
    apiSubscriptions: function () {
      return this.apiSubscriptionsToMeByRepository(this.repositoryId);
    },
    repositorySubscriptions: function () {
      return this.repositorySubscriptionsToMeByRepository(this.repositoryId);
    },
    isEmpty: function () {
      return this.apiSubscriptions.length === 0 && this.repositorySubscriptions.length === 0;
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapMutations(['updateSubscription']),
    load() {
      this.dataApiApi()
        .getApis(this.repositoryId)
        .then((apis) => (this.availableApis = apis));
    },
    invite() {
      this.$refs.modalInvite.open();
    },
  },
};
</script>
