export default {
  currentUser(state) {
    return state.currentUser;
  },
  apiState(state) {
    return state.api_state;
  },
  mySubscriptions(state) {
    return state.mySubscriptionsMapVersion && state.mySubscriptionsMap;
  },
  subscriptionsToMe(state) {
    return state.subscriptionsToMeMapVersion && state.subscriptionsToMeMap;
  },
  myApiSubscriptions(state) {
    return (
      state.mySubscriptionsMapVersion &&
      Object.values(state.mySubscriptionsMap).filter((subscription) => subscription.api)
    );
  },
  myRepositorySubscriptions(state) {
    return (
      state.mySubscriptionsMapVersion &&
      Object.values(state.mySubscriptionsMap).filter((subscription) => subscription.repository)
    );
  },
  apiSubscriptionsToMe(state) {
    return (
      state.subscriptionsToMeMapVersion &&
      Object.values(state.subscriptionsToMeMap).filter((subscription) => subscription.api)
    );
  },
  repositorySubscriptionsToMe(state) {
    return (
      state.subscriptionsToMeMapVersion &&
      Object.values(state.subscriptionsToMeMap).filter((subscription) => subscription.repository)
    );
  },
  apiSubscriptionsToMeByRepository: (state, getters) => (repositoryId) => {
    return getters.apiSubscriptionsToMe.filter(
      (subscription) => subscription.api.repositoryReferenceId === repositoryId,
    );
  },
  /**
   * @param {UUID} repositoryId
   * @returns {Array} Array of subscriptions to my repository
   */
  repositorySubscriptionsToMeByRepository: (state, getters) => (repositoryId) => {
    return getters.repositorySubscriptionsToMe.filter(
      (subscription) => subscription.repository.referenceId === repositoryId,
    );
  },
  /**
   * @param {UUID} repositoryId
   * @returns {Object} Repository subscription from me to the repository
   */
  myRepositorySubscriptionsByRepository: (state, getters) => (repositoryId) => {
    return getters.myRepositorySubscriptions.find(
      (subscription) => subscription.repository.referenceId === repositoryId,
    );
  },

  /**
   * @param {UUID} apiReferenceId
   * @returns {Object} Api subscription from me to the Api
   */
  myApiSubscriptionsByApi: (state, getters) => (apiReferenceId) => {
    return getters.myApiSubscriptions.find(
      (subscription) => subscription.api.referenceId === apiReferenceId,
    );
  },
  connectors(state) {
    return Object.values(state.connectorsMap);
  },
  getConnector: (state) => (connectorId) => {
    return state.connectorsMap[connectorId];
  },
  getConnectorExamples(state) {
    return state.connectorsExamplesMap;
  },
  blueprint(state) {
    return state.blueprint;
  },
  blueprintBackup(state) {
    return state.blueprintBackup;
  },
  blueprints(state) {
    return Object.values(state.blueprintsMap || {});
  },
  installs(state) {
    return Object.values(state.installsMap || {});
  },
  logs(state) {
    return Object.values(state.logsMap || {});
  },
  blueprintsMap() {
    return Arraystate.blueprintsMap;
  },
  getBlueprint: (state) => (blueprintId) => {
    return state.blueprintsMap[blueprintId];
  },
  getTransforms: (state) => (blueprintId) => {
    return state.transformsMap[blueprintId] || [];
  },
  project(state) {
    return state.project;
  },
  projects(state) {
    return Object.values(state.projectsMap);
  },
  projectsMap() {
    return Arraystate.projectsMap;
  },
  transform(state) {
    return state.transform;
  },
  authTemplates(state) {
    return Object.values(state.authTemplates);
  },
};
