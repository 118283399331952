<template>
  <div class="form-group col pb-2">
    <label class="col pb-1">{{ fields.name || 'error' }}</label>
    <div class="row" v-if="fields.type === 'String'">
      <input
        :ref="fields.name"
        v-validate="fields.required ? 'required' : ''"
        :class="[{ 'is-invalid': errors.has(fields.name) }, 'form-control']"
        :name="fields.name || 'error'"
        v-model="value"
        :data-vv-as="fields.name"
        type="text"
        :placeholder="fields.description || ''"
      />
      <div class="invalid-feedback" v-show="errors.has(fields.name)">
        {{ errors.first(fields.name) }}
      </div>
    </div>
    <div class="row" v-if="fields.type === 'Boolean'">
      <input
        :ref="fields.name"
        :name="fields.name || 'error'"
        v-model="value"
        :data-vv-as="fields.name"
        type="checkbox"
      />
    </div>
    <div class="row" v-if="fields.type === 'Object' && !hasChildren">
      <CodeEditor v-model="value" />
    </div>
    <div v-if="fields.type === 'Array'">
      <template v-if="value.length > 0">
        <div v-for="(item, index) in value" class="row pl-3 pb-1" :key="index">
          <input
            :name="fields.name + index || 'error'"
            v-validate="'required'"
            :class="[{ 'is-invalid': errors.has(fields.name + index) }, 'form-control']"
            v-model="value[index]"
            type="text"
            class="col-6"
            :data-vv-as="item[index]"
          />
          <div class="btn row col-3 justify-content-center" @click="value.splice(index, 1)">
            - remove
          </div>
        </div>
      </template>
      <div
        v-if="!value.length || !value.includes('')"
        class="btn row col-6 justify-content-center"
        @click="value.push('')"
      >
        + add new value
      </div>
    </div>
    <div v-if="!!hasChildren" class="pl-2">
      <ConnectorInput
        v-for="key in Object.keys(fields).filter(
          (key) =>
            key !== 'name' &&
            key !== 'description' &&
            key !== 'default' &&
            key !== 'required' &&
            key !== 'type',
        )"
        :fields="fields[key]"
        :key-path="keyPath + '.' + key"
        :key="fields[key].name"
      />
    </div>
  </div>
</template>

<script>
import { default as ApiMixin } from '../../mixins/ApiMixin.vue';
import _ from 'lodash';
import { mapGetters } from 'vuex';
import CodeEditor from '../../components/CodeEditor.vue';

export default {
  name: 'ConnectorInput',
  mixins: [ApiMixin],
  components: {
    CodeEditor,
  },
  props: {
    fields: {
      type: Object,
      required: true,
    },
    keyPath: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      key: '',
      value: '',
    };
  },
  computed: {
    ...mapGetters(['blueprint']),
    hasChildren() {
      const children = Object.keys(this.fields).filter(
        (key) =>
          typeof this.fields[key] === 'object' &&
          key !== 'name' &&
          key !== 'description' &&
          key !== 'default' &&
          key !== 'required' &&
          key !== 'type',
      );
      return children && children.length > 0;
    },
  },
  beforeMount() {
    let defaultValue = '';
    if (this.fields.type === 'Object' && !this.hasChildren) {
      defaultValue = { key: 'value' };
    } else if (this.fields.type === 'Array') {
      defaultValue = [];
    }
    this.value = _.get(this.blueprint, this.keyPath, defaultValue);
  },
  watch: {
    value() {
      _.set(this.blueprint, this.keyPath, this.value);
    },
  },
};
</script>
