<template>
  <div class="info-icon">
    <img :id="icon_id" class="icon" :src="require('../../../images/help.png')" />

    <b-popover :target="icon_id" placement="right" triggers="hover focus">
      <slot></slot>
    </b-popover>
  </div>
</template>

<script>
export default {
  name: 'InfoIcon',
  data() {
    return {
      icon_id: null,
    };
  },
  mounted() {
    this.icon_id = 'icon_' + this._uid;
  },
};
</script>

<style scoped>
.icon {
  width: 18px;
  opacity: 0.5;
  cursor: pointer;
}

.info-icon {
  position: relative;
  top: 0.3em;
  right: 2.5em;
}
</style>
