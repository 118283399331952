<template>
  <div class="card-body">
    <div class="row">
      <div class="col-1">
        <img class="repository-icon" :src="require('../../../images/api.png')" />
      </div>

      <div class="col-11">
        <div class="row mb-1">
          <div class="col text-left">
            <router-link :to="{ name: 'api-info', params: { apiId: api.referenceId } }">
              <h4>{{ api.name }}</h4>
            </router-link>
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-auto font-weight-bold">Provided by:</div>
          <div class="col">{{ api.owner.name }}</div>
          <subscription-manipulation-button
            v-if="subscription"
            :subscription="subscription"
            col-auto
            text-right
          />
        </div>

        <div class="row">
          <div class="col">
            {{ api.description }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubscriptionManipulationButton from '../components/SubscriptionManipulationButton.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'CatalogApiEntry',
  components: {
    SubscriptionManipulationButton,
  },
  props: {
    api: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['currentUser', 'myApiSubscriptionsByApi']),
    subscription: function () {
      const val = this.myApiSubscriptionsByApi(this.api.referenceId);
      return val;
    },
  },
};
</script>
