<template>
  <div class="card">
    <form v-on:submit.prevent="save">
      <CodeEditor v-model="variables" />
      <div class="btn-group dropright px-2">
        <button type="submit" class="btn btn-primary mx-2">Save</button>
        <button class="btn btn-primary mx-2" @click.prevent="$router.back()">Cancel</button>
      </div>
    </form>
  </div>
</template>

<script>
import { default as ApiMixin } from '../mixins/ApiMixin.vue';
import CodeEditor from '@/js/app/components/CodeEditor.vue';

export default {
  name: 'Variables',
  mixins: [ApiMixin],
  components: { CodeEditor },
  props: {
    projectId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      variables: {},
    };
  },
  async mounted() {
    if (this.projectId) {
      this.variables = await this.variablesApi().getVariables(this.projectId);
      this.isLoading = false;
    }
  },
  methods: {
    save() {
      this.variablesApi().updateVariables(this.projectId, this.variables);
    },
  },
};
</script>
