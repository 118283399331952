<template>
  <div class="col" v-if="blueprint">
    <div class="d-flex justify-content-between">
      <div class="sub-route" @click="$router.push({ name: 'blueprints' })">
        <i class="btn bi-chevron-left"> </i>
        Back to blueprints
      </div>
      <div
        v-if="blueprint.nextBlueprintId"
        class="sub-route"
        @click="
          $router.push({
            name: 'editBlueprint',
            params: { blueprintId: blueprint.nextBlueprintId },
          })
        "
      >
        Next blueprint
        <i class="btn bi-chevron-right" />
      </div>
    </div>

    <div class="d-flex row col justify-content-center align-items-center">
      <h3>{{ blueprint.name }}</h3>
      <b-button
        variant="outline-primary"
        size="sm"
        class="ml-3"
        @click="$refs.renameBlueprint.show()"
      >
        Rename
      </b-button>
      <b-button
        v-if="availableNextBlueprints.length"
        variant="outline-primary"
        size="sm"
        class="ml-3"
        @click="$refs.changeNextBlueprint.show()"
      >
        Change next blueprint
      </b-button>
    </div>
    <div class="row justify-content-center">
      <hr class="line-between-cards" />
      <div class="col-3 source-card p-0">
        <div class="p-3 row" style="height: 100%">
          <h3>Trigger</h3>
          <div>
            <img src="../../../images/newproj.png" alt="" />
          </div>
          <button name="editBlueprint" class="config-button" @click="editTrigger()">
            <span>Configure</span>
          </button>
        </div>
      </div>
      <config-card
        class="col-3"
        :connectorType="'Source'"
        @editConnector="editConnectorConfig"
        @editTransform="editTransformConfig"
        @chooseTransform="chooseTransform"
      />
      <config-card
        class="col-3"
        :connectorType="'Destination'"
        @editConnector="editConnectorConfig"
        @editTransform="editTransformConfig"
        @chooseTransform="chooseTransform"
      />
    </div>
    <RenameEntityModal
      v-if="Object.keys(blueprint).length"
      ref="renameBlueprint"
      entity="Blueprint"
      @update-name="updateBlueprintName"
      :current-name="blueprint.name"
    />
    <edit-triggers ref="editBlueprintTrigger" :blueprint-id="blueprintId" />
    <edit-blueprint-connector
      ref="editConnector"
      @updateConnector="updateBlueprint"
      :connectorType="connectorType"
    />
    <new-transform
      ref="newTransform"
      @createNewTransform="newTransform"
      :blueprint-id="blueprintId"
    />
    <change-next-blueprint
      ref="changeNextBlueprint"
      :next-blueprint-id="blueprint.nextBlueprintId"
      :blueprints="availableNextBlueprints"
      @update-next-blueprint="updateNextBlueprint"
    />
  </div>
</template>

<script>
import ApiMixin from '../mixins/ApiMixin';
import ConfigCard from './components/BlueprintConfigCard';
import EditTriggers from './components/EditTriggers';
import EditBlueprintConnector from './components/EditConnector';
import NewTransform from './components/NewTransform';
import ChangeNextBlueprint from './components/ChangeNextBlueprint';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import RenameEntityModal from '@/js/app/components/RenameEntityModal.vue';

export default {
  name: 'EditBlueprint',
  mixins: [ApiMixin],
  components: {
    RenameEntityModal,
    ConfigCard,
    EditTriggers,
    EditBlueprintConnector,
    NewTransform,
    ChangeNextBlueprint,
  },
  props: {
    blueprintId: {
      required: true,
    },
    id: {
      required: true,
    },
  },
  data: () => {
    return {
      connectorType: '',
    };
  },
  computed: {
    ...mapGetters(['blueprint', 'blueprints']),

    availableNextBlueprints() {
      return this.blueprints.filter((b) => b.id !== this.blueprint.id);
    },
  },
  watch: {
    blueprintId: {
      async handler() {
        await this.loadBlueprint(this.blueprintId);
        this.loadBlueprints(this.id);
        this.backupBlueprint();
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations(['setBlueprint', 'backupBlueprint', 'revertBlueprint']),
    ...mapActions(['loadBlueprint', 'loadBlueprints']),

    editTrigger() {
      this.$refs.editBlueprintTrigger.show();
    },

    editConnectorConfig(connectorType) {
      this.$refs.editConnector.show(connectorType.toLowerCase());
    },

    async newTransform(transform) {
      const newTransform = transform.id
        ? transform
        : await this.integrationApi().createTransform(transform, this.blueprint.id);

      const blueprint = await this.blueprintsApi().updateBlueprint({
        ...this.blueprint,
        [this.connectorType.toLowerCase() + 'TransformId']: newTransform.id,
      });

      this.setBlueprint(blueprint);
      this.editTransformConfig(this.connectorType);
    },

    editTransformConfig(connectorType) {
      this.connectorType = connectorType;
      if (this.blueprint[connectorType.toLowerCase() + 'TransformId']) {
        this.$router.push({
          name: 'editTransform',
          params: {
            transformId: this.blueprint[connectorType.toLowerCase() + 'TransformId'],
            blueprintId: this.blueprint.id,
            type: connectorType.toLowerCase(),
          },
        });
      } else {
        this.chooseTransform();
      }
    },
    async updateNextBlueprint(id) {
      const blueprint = await this.blueprintsApi().updateBlueprint({
        ...this.blueprint,
        nextBlueprintId: id,
      });

      this.setBlueprint(blueprint);
    },
    chooseTransform(connectorType) {
      this.connectorType = connectorType;
      this.$refs.newTransform.show(this.connectorType.toLowerCase());
    },
    updateBlueprintName(name) {
      this.setBlueprint({
        ...this.blueprint,
        name,
      });
      this.updateBlueprint();
    },
    async updateBlueprint() {
      const blueprint = await this.blueprintsApi().updateBlueprint(this.blueprint);
      this.setBlueprint(blueprint);
      this.backupBlueprint();
    },
  },
};
</script>

<style scoped>
.line-between-cards {
  position: absolute;
  top: 50%;
  width: 50%;
  border-top: 2px dashed #979797;
}

.sub-route {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  color: #737373;
}

.source-card {
  position: relative;
  margin-top: 1rem;
  margin-right: 3rem;
  height: 30vh;
  min-height: 240px;
  background-color: white;
  border-radius: 4px;
  border: 2px solid #e9e9e9;
}
</style>
