var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col text-left"},[_c('h2',[_vm._v(_vm._s(_vm.title))])])]),_c('table',{staticClass:"table table-hover"},[_c('thead',[_c('tr',[_c('th',[_vm._v("API")]),_c('th',[_vm._v("Created by")]),_c('th',[_vm._v("Created on")]),(!_vm.mySubscriptions)?_c('th',[_vm._v("Subscriber")]):_vm._e(),_c('th',[_vm._v("Used")]),_c('th',[_vm._v("Status")])])]),_c('tbody',_vm._l((_vm.orderedSubscriptions),function(subscription,idx){return _c('tr',{key:idx},[(_vm.mySubscriptions)?_c('td',[_c('router-link',{attrs:{"to":{ name: 'api-info', params: { apiId: subscription.api.referenceId } }}},[_vm._v(" "+_vm._s(subscription.api.name)+" ")])],1):_c('td',[_c('router-link',{attrs:{"to":{
                name: 'api',
                params: {
                  repositoryId: subscription.api.repositoryReferenceId,
                  apiId: subscription.api.referenceId,
                },
              }}},[_vm._v(" "+_vm._s(subscription.api.name)+" ")])],1),_c('td',[_vm._v(_vm._s(subscription.createdBy.firstName)+" "+_vm._s(subscription.createdBy.lastName))]),_c('td',[_vm._v(_vm._s(_vm.formatInstant(subscription.created)))]),(!_vm.mySubscriptions)?_c('td',[_vm._v(_vm._s(subscription.subscriber))]):_vm._e(),_c('td',[_vm._v(_vm._s(_vm.getUsage(subscription)))]),_c('td',[_c('subscription-manipulation-button',{attrs:{"subscription":subscription}})],1)])}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }