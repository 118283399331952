<template>
  <div
    :class="`main-menu ${isHovered ? '' : 'main-menu--collapsed'}`"
    @mouseover="onHover(true)"
    @mouseleave="onHover(false)"
    v-if="currentUser"
  >
    <main-menu-item
      id="main-menu-safe-digit"
      v-if="hasPermission(USER_MANAGEMENT)"
      :icon="require('../../../images/SafeDigitLogo.svg')"
      route="/projects"
      text="SafeDigit"
    />
    <main-menu-item
      id="main-menu-project"
      v-if="hasPermission(USER_MANAGEMENT)"
      :icon="require('../../../images/projects.png')"
      route="/projects"
      text="Projects"
    />
    <main-menu-item
      id="main-menu-account"
      v-if="hasPermission(USER_MANAGEMENT)"
      :icon="require('../../../images/users.png')"
      route="/account"
      text="Account"
    />
    <main-menu-item
      id="main-menu-repositories"
      v-if="hasPermission(DATA_MANAGEMENT)"
      :icon="require('../../../images/database.png')"
      route="/repos"
      text="Repositories"
    />
    <main-menu-item
      id="main-menu-inbox"
      v-if="hasPermission(DATA_MANAGEMENT)"
      :icon="require('../../../images/envelope.png')"
      route="/inbox"
      text="Inbox"
    />
    <main-menu-item
      id="main-menu-subscriptions"
      v-if="hasPermission(DATA_MANAGEMENT)"
      :icon="require('../../../images/list.png')"
      route="/subscriptions"
      text="Subscriptions"
    />
    <main-menu-item
      id="main-menu-catalog"
      v-if="hasPermission(DATA_MANAGEMENT)"
      :icon="require('../../../images/internet.png')"
      route="/catalog"
      text="Catalog"
    />
    <main-menu-item
      id="main-menu-accounts"
      v-if="hasPermission(EMPLOYEE_USER_READ)"
      :icon="require('../../../images/users.png')"
      route="/accounts"
      text="Accounts"
    />
    <div class="bottom__items">
      <div class="main-menu__item">
        <div class="main-menu__icon-container">
          <img :src="require('../../../images/Notificationsbell.png')" width="16px" alt="" />
        </div>
        <span class="main-menu__label">Notifications</span>
      </div>
      <b-dropdown
        id="profile-dropdown"
        dropup
        left
        no-caret
        variant="link"
        toggle-class="p-0
          dropdown__class border-0"
        class="border-0"
      >
        <template #button-content>
          <div class="main-menu__profile">
            <div class="main-menu__icon-container">
              <img :src="require('../../../images/user.png')" width="24px" alt="" />
            </div>
            <span class="main-menu__label">Profile</span>
          </div>
        </template>
        <b-dropdown-header v-if="currentUser">{{ currentUser.email }}</b-dropdown-header>
        <b-dropdown-item @click="onEditProfile">Edit Profile</b-dropdown-item>
        <b-dropdown-item id="logout-dropdown-item" @click="onLogout">Logout</b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { Auth } from '../auth/auth';
import MainMenuItem from './MainMenuItem.vue';
import { default as PermissionMixin } from '../mixins/PermissionMixin.vue';
import { debounce } from 'lodash';

export default {
  name: 'MainMenu',
  mixins: [PermissionMixin],
  components: {
    MainMenuItem,
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  methods: {
    ...mapActions(['resetStore']),
    onLogout() {
      this.resetStore();
      Auth.logout();
      this.$router.push({ name: 'home' });
    },
    onEditProfile() {
      this.$router.push({ name: 'profile' });
    },
    onHover: debounce(function (isHovered) {
      this.isHovered = isHovered;
    }, 750),
  },

  data() {
    return { isHovered: false };
  },
};
</script>

<style lang="scss">
:root {
  --nav-icon-width: 3rem;
  --nav-border-width: 2px;
}

.main-menu {
  width: 200px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  background-color: #ffffff;
  transition: width 0.15s;
  border-right: #e9e9e9 solid 2px;
  flex-shrink: 0;
  z-index: 100;

  &--collapsed {
    width: var(--nav-icon-width);

    .main-menu__label {
      display: none;
    }
  }

  &__item {
    display: flex;
    padding-top: 0;
    margin-top: 0;
    align-items: center;
    height: var(--nav-icon-width);
    color: rgba(0, 0, 0, 0.75);
    text-decoration: none;

    &:hover {
      background-color: #dddddd;
    }
  }

  &__profile {
    display: flex;
    position: inherit;
    bottom: 0;
    align-items: center;
    width: inherit;
    height: var(--nav-icon-width);
    color: rgba(0, 0, 0, 0.75);
    text-decoration: none;

    &:hover {
      background-color: #dddddd;
    }
  }

  &__border {
    position: absolute;
    left: 100%;
    top: 0;
    width: var(--nav-border-width);
    height: inherit;
    background-color: #e9e9e9;
    z-index: 100;
  }

  .dropdown__class {
    min-width: 48px;
    bottom: 0;
  }

  .bottom__items {
    bottom: 0;
    position: absolute;
    width: inherit;
    display: flex;
    flex-direction: column;
  }

  a.nav-link {
    text-decoration: none;
  }

  a.router-link-active {
    font-weight: bold;
    background-color: transparent;
  }

  a.router-link-active > div.main-menu__item,
  a:hover > div.main-menu__item {
    background-color: rgba(9, 30, 66, 0.04);
    text-decoration: none;
  }
}
</style>
