import { get } from 'lodash';

class User {
  static getFullName(user) {
    user = user ? user : {};
    let firstName = get(user, 'firstName', '');
    if (firstName.length) {
      firstName += ' ';
    }
    return firstName + get(user, 'lastName', '');
  }
}

class Role {
  constructor(value, text) {
    this._value = value;
    this._text = text;
  }

  get value() {
    return this._value;
  }

  get text() {
    return this._text;
  }

  static get(value) {
    return ALL_ROLES.find((el) => el.value === value);
  }

  /**
   * returns all the roles user with this role can assign others
   */
  get canAssign() {
    if (this.value === ADMIN.value) return ALL_ROLES;
    else if (this.value === INTEGRATIONS_ADMIN.value) return INTEGRATIONS_ROLES;
    else return [];
  }

  static get ADMIN() {
    return ADMIN;
  }

  static get DATA_MANAGER() {
    return DATA_MANAGER;
  }
}

class Status {
  constructor(value, text) {
    this._value = value;
    this._text = text;
  }

  get value() {
    return this._value;
  }

  get text() {
    return this._text;
  }

  static get(value) {
    return Status.ALL.find((el) => el.value === value);
  }

  static get ALL() {
    return ALL_STATUSES;
  }

  static get ENABLED() {
    return ENABLED;
  }

  static get DISABLED() {
    return DISABLED;
  }

  static get SUSPENDED() {
    return SUSPENDED;
  }
}

const ENABLED = new Status('ENABLED', 'Enabled');
const DISABLED = new Status('DISABLED', 'Disabled');
const SUSPENDED = new Status('SUSPENDED', 'Suspended');

const ALL_STATUSES = [ENABLED, DISABLED, SUSPENDED];

const ADMIN = new Role('ROLE_ADMIN', 'Admin');
const DATA_MANAGER = new Role('ROLE_DATA_MANAGER', 'Data manager');
const INTEGRATIONS_MANAGER = new Role('ROLE_INTEGRATIONS_USER', 'Integration manager');
const INTEGRATIONS_ADMIN = new Role('ROLE_INTEGRATIONS_ADMIN', 'Integration admin');

// Feature flag
const ALL_ROLES = [ADMIN, INTEGRATIONS_ADMIN, DATA_MANAGER, INTEGRATIONS_MANAGER];

const INTEGRATIONS_ROLES = [INTEGRATIONS_ADMIN, INTEGRATIONS_MANAGER];

export { User, Role, Status };
