<template>
  <div class="">
    <b-breadcrumb
      :items="[
        { text: 'Repositories', to: { name: 'repo-list' } },
        { text: repositoryId, to: { name: 'repo-edit' } },
        { text: 'APIs', active: true },
      ]"
    />

    <div class="row">
      <div class="col"><h4>APIs</h4></div>
      <div class="col text-right">
        <b-button @click.prevent="addApi" variant="outline-primary" size="sm">New API</b-button>
      </div>
    </div>

    <div class="card my-4" v-for="(api, idx) in apis" :key="idx">
      <div class="card-body">
        <div class="card-title row py-1 align-items-center">
          <div class="col">
            <img class="icon mr-3" :src="require('../../../../images/api.png')" />
            <router-link
              :to="{ name: 'api', params: { repositoryId: repositoryId, apiId: api.referenceId } }"
            >
              {{ api.name }}
            </router-link>
          </div>

          <toggle-button
            @change="togglePublishState(idx)"
            :value="api.enabled"
            :labels="{ checked: 'Enabled', unchecked: 'Disabled' }"
            :sync="true"
            :height="30"
            :width="80"
            :color="{ checked: '#007bff', unchecked: '#6c757d', disabled: '#CCCCCC' }"
          />

          <div class="col-auto text-right">
            <b-dropdown variant="link" text="Actions">
              <b-dd-item @click.prevent="edit(idx)">Edit</b-dd-item>
              <b-dd-item
                @click.prevent="deleteApi(idx)"
                :disabled="api.enabled || api.subscriptionCount > 0"
                >Delete</b-dd-item
              >
            </b-dropdown>
          </div>
        </div>

        <stat-card
          text="Subscriptions"
          :value="api.subscriptionCount"
          :route="{
            name: 'api-subscriptions',
            params: { repositoryId: repositoryId, apiId: api.referenceId },
          }"
        >
        </stat-card>

        <stat-card :value="getSummary(api, 'HOUR')" text="Calls in last hour"></stat-card>

        <stat-card :value="getSummary(api, 'DAY')" text="Calls in last 24 hours"></stat-card>
      </div>
    </div>

    <div v-if="!(apis && apis.length)" class="row">
      <div class="col">The list is empty</div>
    </div>

    <b-modal ref="modalDetails" title="API" @ok="saveDetails" @cancel="resetDetails" no-fade static>
      <api-details ref="apiDetails"></api-details>
    </b-modal>

    <route-button text="Back" :route="{ name: 'repo-list' }"></route-button>
  </div>
</template>

<script>
import { default as ApiMixin } from '../../mixins/ApiMixin.vue';
import { default as ValidationMixin } from '../../mixins/ValidationMixin.vue';
import { mapGetters } from 'vuex';
import ApiDetails from './ApiDetails.vue';
import Vue from 'vue';
import StatCard from '../../components/StatCard.vue';
import RouteButton from '../../components/RouteButton.vue';
import { get as _get } from 'lodash';
import { BBreadcrumb } from 'bootstrap-vue';

export default {
  name: 'ApiList',
  mixins: [ApiMixin, ValidationMixin],
  props: {
    repositoryId: {
      required: true,
    },
  },
  data() {
    return {
      apis: [],
    };
  },
  computed: mapGetters(['currentUser']),
  components: {
    ApiDetails,
    StatCard,
    RouteButton,
    BBreadcrumb,
  },
  mounted() {
    this.load();
  },
  methods: {
    load: function () {
      this.dataApiApi()
        .getApis(this.repositoryId)
        .then((apis) => (this.apis = apis))
        .then(() => {
          this.apis.forEach((api) => this.loadCounters(api));
        });
    },
    loadCounters(api) {
      this.subscriptionApi()
        .getApiSubscriptions(this.repositoryId, api.referenceId)
        .then((subscriptions) => this.$set(api, 'subscriptionCount', subscriptions.length));

      this.auditApi()
        .getApiUsageSummary(this.repositoryId, api.referenceId)
        .then((summary) => this.$set(api, 'summary', summary));
    },
    addApi: function () {
      this.resetDetails();
      this.$refs.modalDetails.show();
      this.$refs.apiDetails.show();
    },
    saveDetails(event) {
      event.preventDefault();

      const apiDetails = this.$refs.apiDetails;
      const validator = apiDetails.getValidator();
      this.validateInput(validator)
        .then(() => {
          const model = apiDetails.getModel();
          const idx = apiDetails.idx;
          if (model.referenceId) {
            return this.dataApiApi()
              .updateApi(this.repositoryId, model.referenceId, model)
              .then((api) => Vue.set(this.apis, idx, api));
          } else {
            return this.dataApiApi()
              .createApi(this.repositoryId, model)
              .then((api) => {
                api.subscriptionCount = 0;
                this.apis.push(api);
              });
          }
        })
        .then(() => this.$refs.modalDetails.hide())
        .catch((error) => {
          this.clearApiState();
          this.onSubmitError(validator, error);
        });
    },
    resetDetails() {
      this.$refs.apiDetails.apiIn = {};
      this.$refs.apiDetails.idx = null;
    },
    togglePublishState(idx) {
      const api = this.apis[idx];
      api.enabled = !api.enabled;
      this.dataApiApi()
        .updateApiVisibility(this.repositoryId, api.referenceId, api.enabled)
        .then((api) => Vue.set(this.apis, idx, api));
    },
    edit(idx) {
      this.resetDetails();
      this.$refs.apiDetails.setModel(this.apis[idx]);
      this.$refs.apiDetails.idx = idx;
      this.$refs.modalDetails.show();
    },
    deleteApi(idx) {
      const api = this.apis[idx];
      this.dataApiApi()
        .deleteApi(this.repositoryId, api.referenceId)
        .then(() => Vue.delete(this.apis, idx))
        .catch((errors) => console.error(errors));
    },
    getSummary(api, period) {
      return _get(api, ['summary', period], 0);
    },
  },
};
</script>

<style scoped>
.icon {
  width: 30px;
}
</style>
