<template>
  <div class="code-editor">
    <div class="d-flex justify-content-end">
      <b-button
        v-if="!readonly"
        variant="outline-primary"
        size="sm"
        class="beautify"
        @click="editor.getAction('editor.action.formatDocument').run()"
      >
        Beautify
      </b-button>
    </div>
    <div class="editor" ref="editor" :style="{ height: `${height}px` }" />
  </div>
</template>

<script>
import * as monaco from 'monaco-editor';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { UUID_REGEXP, getRegExpPropWithValue } from '@/js/app/utils/regexp';

export default {
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },

  props: {
    modelValue: {
      type: [String, Object, Array],
      default: null,
    },
    height: {
      type: Number,
      default: 500,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    language: {
      type: String,
      default: 'json',
    },
  },

  data: () => ({
    editor: null,
    subscription: null,
  }),

  mounted() {
    this.initEditor();
  },

  beforeUnmount() {
    this.editor.dispose();
    this.subscription.dispose();
  },

  watch: {
    modelValue: {
      handler(newValue) {
        if (!this.editor) {
          return;
        }

        this.setValue(newValue);
      },
      deep: true,
    },
  },

  methods: {
    initEditor() {
      this.editor = monaco.editor.create(this.$refs.editor, {
        value: JSON.stringify(this.modelValue, null, 2),
        tabSize: 2,
        readOnly: this.readonly,
        lineDecorationsWidth: 3,
        lineNumbersMinChars: 4,
        language: this.language,
        scrollBeyondLastLine: false,
        automaticLayout: true,
        minimap: { enabled: false },
      });

      if (this.language === 'json') {
        this.registerJSONCustomLinks();
      }
      this.subscription = this.editor.onDidChangeModelContent(() => this.emitValue());
    },

    setValue(newValue) {
      const value = this.editor.getValue();

      if ((!value && newValue) || !isEqual(newValue, JSON.parse(value))) {
        monaco.editor.setModelLanguage(this.editor.getModel(), this.language);
        this.editor.setValue(JSON.stringify(newValue, null, 2));
        this.editor.trigger(value, 'editor.action.formatDocument');
      }
    },

    emitValue: debounce(function () {
      const value = this.editor.getValue();

      try {
        if ((!value && this.modelValue) || !isEqual(this.modelValue, JSON.parse(value))) {
          this.$emit('update:modelValue', JSON.parse(value));
        }
      } catch (_) {
        // Do nothing if the value is not valid JSON
      }
    }, 300),

    registerJSONCustomLinks() {
      monaco.editor.registerLinkOpener({
        open: (link) => {
          this.$router.push(link.path);
          return true;
        },
      });

      monaco.languages.registerLinkProvider('json', {
        provideLinks: (model) => {
          const blueprintMatches = model.findMatches(
            getRegExpPropWithValue('blueprintId'),
            true,
            true,
          );

          return {
            links: blueprintMatches.map((m) => {
              const value = model.getValueInRange(m.range);
              const uuidMatches = value.match(UUID_REGEXP);
              const url = uuidMatches?.[0]
                ? this.$router.resolve({
                    name: 'editBlueprint',
                    params: { blueprintId: uuidMatches?.[0] },
                  }).href
                : null;

              return {
                url,
                range: url ? m.range : new monaco.Range(0, 0, 0, 0),
              };
            }),
          };
        },
      });
    },
  },
};
</script>

<style scoped>
.editor {
  border: 2px solid #e9e9e9;
}

.code-editor {
  width: 100%;
  position: relative;
}

.beautify {
  position: absolute;
  top: 10px;
  right: 25px;
  z-index: 1;
}
</style>
