<template>
  <div class="row">
    <div class="col">
      <div class="form-group row">
        <label class="col-3 col-form-label">Name</label>
        <div class="col">
          <input
            ref="tableNameInput"
            :readonly="readonly"
            :class="[
              { 'is-invalid': errors.has('tableName') },
              readonly ? 'form-control-plaintext' : 'form-control',
            ]"
            v-model="tableIn.name"
            v-validate="'required'"
            data-vv-as="Name"
            name="tableName"
            type="text"
          />
          <div class="invalid-feedback" v-show="errors.has('tableName')">
            {{ errors.first('tableName') }}
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-3 col-form-label">Description</label>
        <div class="col">
          <textarea
            :readonly="readonly"
            :class="[readonly ? 'form-control-plaintext' : 'form-control']"
            v-model="tableIn.description"
            rows="4"
            cols="50"
            maxlength="1000"
            data-vv-as="Description"
          >
          </textarea>
        </div>
      </div>

      <div class="form-group row" v-if="readonly">
        <label class="col-3 col-form-label">Size</label>
        <div class="col">
          <div id="size" class="form-control-plaintext">{{ getSize(tableIn) }}</div>
        </div>
      </div>

      <div class="form-group row" v-if="readonly">
        <label class="col-3 col-form-label">Entries</label>
        <div class="col">
          <div class="form-control-plaintext">{{ tableIn.rowCount }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import filesize from 'filesize';

export default {
  name: 'table-details',
  props: {
    table: {
      type: Object,
      required: false,
      default: function () {
        return {};
      },
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableIn: cloneDeep(this.table),
    };
  },
  watch: {
    table: function () {
      this.resetModel();
    },
  },
  methods: {
    getModel() {
      return this.tableIn;
    },
    setModel(model) {
      return (this.tableIn = model ? cloneDeep(model) : {});
    },
    resetModel() {
      this.tableIn = cloneDeep(this.table);
    },
    getSize(table) {
      return filesize(table.size);
    },
    shown() {
      if (!this.readonly) this.$refs.tableNameInput.focus();
    },
  },
};
</script>
