<template>
  <div class="card h-100 col-xl-2 col-lg-4 col-md-6 col-sm-12 px-0">
    <div class="card-body">
      <div class="px-0 text-center">
        <img :src="connectorLogoSrc" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import format from 'string-format';
export default {
  name: 'IntegrationTarget',
  computed: {
    ...mapGetters(['getBlueprint', 'connectors', 'getConnector']),
    blueprintId() {
      // For some reason the props did not work with nested routes
      return this.$route.params.blueprintId;
    },
    connector() {
      return this.getConnector(this.getBlueprint(this.blueprintId).destinationConnectorId);
    },
    connectorLogoSrc() {
      return format('/public/{fileName}.png', { fileName: this.connector.name });
    },
  },
};
</script>
<style>
img {
  max-width: 100%;
  max-height: 100%;
}
</style>
