<template>
  <div>
    <div v-if="mySubscriptions.size === 0 && subscriptionsToMe.size === 0">
      <div class="col"><h4>Subscriptions</h4></div>
      <p class="col">Nothing is here yet</p>
    </div>

    <api-subscription-list
      v-if="myApiSubscriptions.length"
      :subscriptions="myApiSubscriptions"
      :mySubscriptions="true"
      @subscriptionChange="updateSubscription"
      title="My subscriptions to Apis"
    >
    </api-subscription-list>
    <api-subscription-list
      v-if="apiSubscriptionsToMe.length"
      :subscriptions="apiSubscriptionsToMe"
      :mySubscriptions="false"
      @subscriptionChange="updateSubscription"
      title="Subscriptions to my Apis"
    >
    </api-subscription-list>
    <repository-subscription-list
      v-if="myRepositorySubscriptions.length"
      :subscriptions="myRepositorySubscriptions"
      :mySubscriptions="true"
      @subscriptionChange="updateSubscription"
      title="My Subscriptions to Repositories"
    >
    </repository-subscription-list>
    <repository-subscription-list
      v-if="repositorySubscriptionsToMe.length"
      :subscriptions="repositorySubscriptionsToMe"
      :mySubscriptions="false"
      @subscriptionChange="updateSubscription"
      title="Subscriptions to my Repositories"
    >
    </repository-subscription-list>
  </div>
</template>

<script>
import { default as ApiMixin } from '../../mixins/ApiMixin.vue';
import { mapGetters, mapMutations } from 'vuex';
import ApiSubscriptionList from './ApiSubscriptionList.vue';
import RepositorySubscriptionList from './RepositorySubscriptionList.vue';

export default {
  name: 'Subscriptions',
  mixins: [ApiMixin],
  components: {
    ApiSubscriptionList,
    RepositorySubscriptionList,
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'mySubscriptions',
      'subscriptionsToMe',
      'myApiSubscriptions',
      'myRepositorySubscriptions',
      'apiSubscriptionsToMe',
      'repositorySubscriptionsToMe',
    ]),
  },
  methods: {
    ...mapMutations(['updateSubscription']),
  },
};
</script>
