<template>
  <div class="card my-4">
    <div class="card-body">
      <h4>Upload a data file</h4>

      <b-form-file
        v-model="file"
        ref="file-input"
        placeholder="Choose a file..."
        accept="text/csv"
        @change="onFileChange"
      >
      </b-form-file>
    </div>

    <table-fields-dlg ref="modalFields" @ok="onFieldsOk" @cancel="onCancel" />

    <owner-key-dialog
      ref="modalKey"
      title="Table"
      key-prompt="Provide the key that will be used to encrypt your data"
      generate="true"
      @ok="onKeyOk"
      @cancel="onCancel"
    />
  </div>
</template>

<script>
import { default as ApiMixin } from '../../mixins/ApiMixin.vue';
import { mapGetters } from 'vuex';
import OwnerKeyDialog from '../../components/OwnerKeyDialog.vue';
import TableFieldsDlg from './TableFieldsDlg.vue';
import { default as FileParser } from './file_parser.js';
import JSZIP from 'jszip';
import { Tasks } from './tasks.js';
import _ from 'lodash';

const ZIP_CONF = {
  type: 'blob',
  compression: 'DEFLATE',
  streamFiles: true,
  compressionOptions: {
    level: 9,
  },
};

const EVENT_START = 'start';
const EVENT_FINISH = 'finish';
const EVENT_PROGRESS = 'progress';
const EVENT_ERROR = 'error';

export default {
  name: 'TableUp',
  mixins: [ApiMixin],
  components: {
    OwnerKeyDialog,
    TableFieldsDlg,
  },
  props: {
    repositoryId: {
      required: true,
    },
    tableReferenceId: {
      required: true,
    },
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  data() {
    return {
      file: null,
      xfields: [],
    };
  },
  methods: {
    onFileChange(event) {
      this.clearApiState();
      this.file = _.first(event.target.files);

      if (!this.currentUser.limitations.validateFileSize(this.file)) {
        alert('This file size is not supported by your plan');
        this.$set(this, 'file', null);
        this.$refs['file-input'].reset();
      } else {
        new FileParser(this.file, 0, 10000)
          .firstLine()
          .then((firstLine) => this.onFileParsed(firstLine));
      }
    },
    onFileParsed(line) {
      const headers = line
        .split(/,/)
        // eslint-disable-next-line no-useless-escape
        .map((str) => str.replace(/^(\s|\"|\')+|(\s|\"|\')+$/g, ''))
        .map((str) => {
          return { name: str };
        });
      this.$refs.modalFields.open(headers);
    },
    requestKey() {
      this.$refs.modalKey.open();
    },
    onKeyOk(key) {
      this.startUpload(key);
    },
    uploadFormData(formData) {
      this.dataTableApi()
        .createResource(this.repositoryId, this.tableReferenceId, formData, this.onUploadProgress)
        .then((table) => this.emitLocalEvent(EVENT_FINISH, Tasks.TYPE_FILE_UPLOAD, table))
        .catch((error) => this.onError(Tasks.TYPE_FILE_UPLOAD, error));
    },
    async startUpload(key) {
      if (this.file && key) {
        const compressed = await this.compressFile();
        if (compressed) {
          const fields = JSON.stringify(this.xfields);
          const formData = new FormData();
          formData.set('key', key);
          formData.set('fields', fields);
          formData.set('file', compressed, this.file.name + '.zip');
          this.uploadFormData(formData);
          this.emitLocalEvent(EVENT_START, Tasks.TYPE_FILE_UPLOAD);
        }
      }
    },
    async compressFile() {
      this.emitLocalEvent(EVENT_START, Tasks.TYPE_FILE_COMPRESS);
      let compressed = null;
      try {
        const zip = new JSZIP().file(this.file.name, this.file);
        compressed = await zip.generateAsync(ZIP_CONF, (update) => {
          if (update.percent) {
            this.emitLocalEvent(
              EVENT_PROGRESS,
              Tasks.TYPE_FILE_COMPRESS,
              parseInt(update.percent),
              100,
            );
          }
        });
      } catch (e) {
        this.onError(Tasks.TYPE_FILE_COMPRESS, e);
      }
      this.emitLocalEvent(EVENT_FINISH, Tasks.TYPE_FILE_COMPRESS);
      return compressed;
    },
    onUploadProgress(event) {
      if (event.lengthComputable) {
        this.emitLocalEvent(EVENT_PROGRESS, Tasks.TYPE_FILE_UPLOAD, event.loaded, event.total);
      }
    },
    onFieldsOk(fields) {
      this.xfields = fields;
      this.requestKey();
    },
    onCancel() {
      console.log('onCancel');
      this.$set(this, 'file', null);
      this.$refs['file-input'].reset();
    },
    onError(task, error, message) {
      this.emitLocalEvent(EVENT_ERROR, task);
      this.clearApiState();

      if (error && error.response) {
        this.setFailure(error.response.status, message || error.response.data.error);
      } else {
        alert(error);
      }
      this.$set(this, 'file', null);
      this.$refs['file-input'].reset();
    },
    emitLocalEvent(event, task, ...args) {
      this.$emit(event, task, ...args);
    },
  },
};
</script>

<style scoped></style>
