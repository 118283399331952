var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('b-breadcrumb',{attrs:{"items":[
      { text: 'Repositories', to: { name: 'repo-list' } },
      { text: _vm.repositoryId, to: { name: 'repo-edit' } },
      { text: 'APIs', active: true },
    ]}}),_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col text-right"},[_c('b-button',{attrs:{"variant":"outline-primary","size":"sm"},on:{"click":function($event){$event.preventDefault();return _vm.addApi.apply(null, arguments)}}},[_vm._v("New API")])],1)]),_vm._l((_vm.apis),function(api,idx){return _c('div',{key:idx,staticClass:"card my-4"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"card-title row py-1 align-items-center"},[_c('div',{staticClass:"col"},[_c('img',{staticClass:"icon mr-3",attrs:{"src":require('../../../../images/api.png')}}),_c('router-link',{attrs:{"to":{ name: 'api', params: { repositoryId: _vm.repositoryId, apiId: api.referenceId } }}},[_vm._v(" "+_vm._s(api.name)+" ")])],1),_c('toggle-button',{attrs:{"value":api.enabled,"labels":{ checked: 'Enabled', unchecked: 'Disabled' },"sync":true,"height":30,"width":80,"color":{ checked: '#007bff', unchecked: '#6c757d', disabled: '#CCCCCC' }},on:{"change":function($event){return _vm.togglePublishState(idx)}}}),_c('div',{staticClass:"col-auto text-right"},[_c('b-dropdown',{attrs:{"variant":"link","text":"Actions"}},[_c('b-dd-item',{on:{"click":function($event){$event.preventDefault();return _vm.edit(idx)}}},[_vm._v("Edit")]),_c('b-dd-item',{attrs:{"disabled":api.enabled || api.subscriptionCount > 0},on:{"click":function($event){$event.preventDefault();return _vm.deleteApi(idx)}}},[_vm._v("Delete")])],1)],1)],1),_c('stat-card',{attrs:{"text":"Subscriptions","value":api.subscriptionCount,"route":{
          name: 'api-subscriptions',
          params: { repositoryId: _vm.repositoryId, apiId: api.referenceId },
        }}}),_c('stat-card',{attrs:{"value":_vm.getSummary(api, 'HOUR'),"text":"Calls in last hour"}}),_c('stat-card',{attrs:{"value":_vm.getSummary(api, 'DAY'),"text":"Calls in last 24 hours"}})],1)])}),(!(_vm.apis && _vm.apis.length))?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_vm._v("The list is empty")])]):_vm._e(),_c('b-modal',{ref:"modalDetails",attrs:{"title":"API","no-fade":"","static":""},on:{"ok":_vm.saveDetails,"cancel":_vm.resetDetails}},[_c('api-details',{ref:"apiDetails"})],1),_c('route-button',{attrs:{"text":"Back","route":{ name: 'repo-list' }}})],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col"},[_c('h4',[_vm._v("APIs")])])
}]

export { render, staticRenderFns }