<template>
  <b-modal ref="rename" :title="`Rename ${entity}`" @ok="updateName" centered no-fade static>
    <div class="row">
      <label class="text-center mb-0">New {{ entity }} Name:</label>
      <input class="ml-2 w-50" type="text" v-model="name" />
    </div>
  </b-modal>
</template>

<script>
import ApiMixin from '../mixins/ApiMixin.vue';

export default {
  mixins: [ApiMixin],

  data: () => {
    return {
      name: '',
    };
  },
  props: {
    entity: {
      type: String,
      required: true,
    },
    currentName: {
      type: String,
      required: true,
    },
  },
  methods: {
    show() {
      this.name = this.currentName;
      this.$refs.rename.show();
    },
    updateName() {
      this.$emit('update-name', this.name);
    },
  },
};
</script>
