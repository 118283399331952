import * as components from '.';

export default [
  {
    path: '/login',
    component: components.Login,
    name: 'login',
    meta: { requiresAuth: false },
  },
  {
    path: '/login/otp',
    component: components.OtpLogin,
    name: 'otp-login',
    meta: { requiresAuth: false },
  },
  {
    path: '/signup',
    component: components.SignupAccount,
    name: 'signup',
    meta: { requiresAuth: false },
  },
  {
    path: '/reset',
    component: components.ResetPassword,
    name: 'reset',
    meta: { requiresAuth: false },
  },
  {
    path: '/invite/:token',
    component: components.SignupAccount,
    name: 'invite',
    props: {
      existing: false,
    },
    meta: { requiresAuth: false },
  },
];
