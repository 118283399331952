import * as components from '.';

export default [
  {
    path: '/repos',
    component: components.RepoList,
    name: 'repo-list',
  },
  {
    path: '/repos/:repositoryId',
    component: components.Repo,
    name: 'repo-edit',
    props: true,
  },
  {
    path: '/repos/:repositoryId/tokens',
    component: components.RepositoryDataTokens,
    name: 'repo-tokens',
    props: true,
  },
];
