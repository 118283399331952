/**
 * Set of utilities operating with tasks
 */
export class Tasks {
  static STATE_DONE = 'DONE';
  static STATE_PENDING = 'PENDING';
  static STATE_IN_PROGRESS = 'IN_PROGRESS';

  static TYPE_S3_UPLOAD = 'S3_UPLOAD';
  static TYPE_TABLE_UPLOAD = 'TABLE_UPLOAD';
  static TYPE_INDEXING = 'INDEXING';
  static TYPE_FILE_UPLOAD = 'FILE_UPLOAD';
  static TYPE_FILE_COMPRESS = 'FILE_COMPRESS';

  /**
   * Sort and fix task states
   * @param tasks
   * @return {*}
   */
  static prepare(tasks) {
    // sort by id
    tasks.sort((t1, t2) => t1.id - t2.id);
    // fix states
    tasks.forEach((task) => (task.state = Tasks.getTaskState(task)));

    return tasks;
  }

  /**
   * Filter given tasks by state
   * @param tasks
   * @param states
   * @return {*}
   */
  static filterByStates(tasks, ...states) {
    const set = new Set(states);
    return tasks.filter((task) => set.has(task.state));
  }

  /**
   * Get 'active' i.e. PENDING and IN_PROGRESS tasks
   * @param tasks
   * @return {*}
   */
  static getActive(tasks) {
    return Tasks.filterByStates(tasks, Tasks.STATE_IN_PROGRESS, Tasks.STATE_PENDING);
  }

  /**
   * Get a 'real' task state basing on a 'progress' and 'total' values
   * @param task
   * @return {string}
   */
  static getTaskState(task) {
    if (task.progress === 0 || task.total === 0) {
      return Tasks.STATE_PENDING;
    } else if (task.progress === task.total) {
      return Tasks.STATE_DONE;
    } else {
      return Tasks.STATE_IN_PROGRESS;
    }
  }

  /**
   * Get task caption
   * @param task
   * @return {*}
   */
  static getTypeCaption(task) {
    const caption = TYPE_CAPTIONS.get(task.taskType);
    if (!caption) {
      throw new Error('Not supported task type ' + task);
    }
    return caption;
  }

  /**
   * Get task state caption
   * @param task
   * @return {*}
   */
  static getStatesCaption(task) {
    const state = task.state;
    const caption = STATE_CAPTIONS.get(state);
    if (!caption) {
      throw new Error('Not supported task state ' + state);
    }
    return caption;
  }
}

export class LocalTask {
  constructor(type) {
    this._progress = 0;
    this._total = 0;
    this.taskType = type;
    this.state = Tasks.STATE_PENDING;
  }

  get progress() {
    return this._progress;
  }

  get total() {
    return this._total;
  }

  update(progress, total) {
    this._progress = progress;
    this._total = total;
    if (progress > 0) {
      this.state = progress >= total ? Tasks.STATE_DONE : Tasks.STATE_IN_PROGRESS;
    }
  }
}

const TYPE_CAPTIONS = new Map([
  [Tasks.TYPE_S3_UPLOAD, 'Buffering'],
  [Tasks.TYPE_TABLE_UPLOAD, 'Encrypting'],
  [Tasks.TYPE_INDEXING, 'Building index'],
  [Tasks.TYPE_FILE_UPLOAD, 'Uploading'],
  [Tasks.TYPE_FILE_COMPRESS, 'Compressing'],
]);

const STATE_CAPTIONS = new Map([
  [Tasks.STATE_DONE, 'Done'],
  [Tasks.STATE_PENDING, 'Waiting'],
  [Tasks.STATE_IN_PROGRESS, ' '],
]);
