<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col text-left">
            <h2>Usage Details</h2>
          </div>
        </div>

        <b-navbar toggleable="md" type="light" variant="light">
          <b-navbar-brand>Number of calls</b-navbar-brand>

          <b-navbar-nav class="ml-auto">
            <b-nav-text>Time range</b-nav-text>
            <time-range-dropdown
              :options="timeRangeOptions"
              :selection="timeRange"
              @change="onTimeRangeChange"
            ></time-range-dropdown>
          </b-navbar-nav>
        </b-navbar>

        <div class="row">
          <div class="col pt-3">
            <time-chart :dataset="dataset" :query="timeRange"></time-chart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { default as ApiMixin } from '../../mixins/ApiMixin.vue';
import { mapGetters } from 'vuex';
import TimeRangeDropdown from '../../components/TimeRangeDropdown.vue';
import TimeChart from '../../components/TimeChart.vue';

export default {
  name: 'ApiUsage',
  mixins: [ApiMixin],
  components: {
    TimeChart,
    TimeRangeDropdown,
  },
  props: {
    repositoryId: {
      required: true,
    },
    apiId: {
      required: true,
    },
  },
  computed: mapGetters(['currentUser']),
  data() {
    return {
      dataset: [],
      timeRangeOptions: [
        { value: this.hourToMs(1), text: 'Last hour', period: 'minute' },
        { value: this.hourToMs(24), text: 'Last 24 hours', period: 'hour' },
        { value: this.hourToMs(24 * 7), text: 'Last week', period: 'day' },
        { value: this.hourToMs(24 * 14), text: 'Last two weeks', period: 'day' },
        { value: this.hourToMs(24 * 30), text: 'Last month', period: 'day' },
      ],
      timeRange: {},
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.timeRange = this.timeRangeOptions[0];
      this.refreshChart();
    },
    hourToMs(hour) {
      return hour * 3600 * 1000;
    },
    onTimeRangeChange(selection) {
      this.timeRange = selection;
      this.refreshChart();
    },
    refreshChart() {
      const query = {
        startTime: Date.now() - this.timeRange.value,
        period: this.timeRange.period,
      };
      this.auditApi()
        .getApiUsage(this.repositoryId, this.apiId, query)
        .then(
          (response) =>
            (this.dataset = response.map((point) => {
              return {
                x: point.time * 1000,
                y: point.count,
              };
            })),
        );
    },
  },
};
</script>

<style scoped></style>
