import path from 'path';
import { Api } from './Api';

class AccountApi extends Api {
  static URL_ACCOUNTS = 'accounts';

  constructor(callbacks) {
    super(callbacks);
  }

  /**
   * Retrieve list of accounts
   */
  getAccounts() {
    return super.get(AccountApi.URL_ACCOUNTS);
  }

  /**
   * Retrieve single account by id
   */
  getAccount(accountId) {
    const url = path.join(AccountApi.URL_ACCOUNTS, accountId.toString());
    return super.get(url);
  }

  /**
   * Save account. If account.id is assigned - it will be updated, new account will be created otherwise
   */
  updateAccount(account) {
    const url = path.join(AccountApi.URL_ACCOUNTS, account.id.toString());
    return super.put(url, account);
  }

  /**
   * Post signup data
   */
  signup(form) {
    return super.post(AccountApi.URL_ACCOUNTS + '/signup', form, false);
  }

  /**
   * Invite user to create a new account
   */
  invite(invitation) {
    return super.post(AccountApi.URL_ACCOUNTS + '/invite', invitation);
  }

  /**
   * Validate token
   */
  validateToken(token) {
    if (token) {
      const url = path.join(AccountApi.URL_ACCOUNTS, 'validate', token);
      return super.get(url, false);
    } else {
      return Promise.reject('No token provided');
    }
  }
}

export default AccountApi;
