<template>
  <div class="">
    <b-breadcrumb
      :items="[
        { text: 'Repositories', to: { name: 'repo-list' } },
        { text: repositoryId, active: true },
      ]"
    />
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col text-left">
            <h2>Repository</h2>
          </div>
          <div class="col w-50 text-right">
            <b-button @click.prevent="editDetails" variant="outline-primary" size="sm"
              >Edit</b-button
            >
          </div>
        </div>

        <repo-details :repo="repository" readonly></repo-details>
      </div>
    </div>

    <b-modal
      ref="modalDetails"
      title="Table"
      @ok="saveDetails"
      @cancel="resetDetails"
      no-fade
      static
    >
      <repo-details ref="repoEdit" :repo="repository"></repo-details>
    </b-modal>

    <div class="row my-4">
      <div class="col">
        <button class="btn btn-primary" @click.prevent="$router.push({ name: 'repo-list' })">
          Back
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BBreadcrumb } from 'bootstrap-vue';
import { default as ApiMixin } from '../../mixins/ApiMixin.vue';
import RepoDetails from './RepoDetails.vue';

export default {
  name: 'Repo',
  mixins: [ApiMixin],
  props: {
    repositoryId: {
      required: true,
    },
  },
  components: {
    RepoDetails,
    BBreadcrumb,
  },
  data() {
    return {
      repository: {},
    };
  },
  computed: mapGetters(['currentUser']),
  mounted() {
    this.load();
  },
  methods: {
    load: function () {
      this.dataRepositoryApi()
        .getRepository(this.repositoryId)
        .then((repository) => (this.repository = repository));
    },
    save: function (model) {
      this.dataRepositoryApi()
        .updateRepository(this.repositoryId, model)
        .then((response) => (this.repository = response));
    },
    editDetails: function () {
      this.$refs.modalDetails.show();
    },
    saveDetails() {
      const model = this.$refs.repoEdit.getModel();
      this.save(model);
    },
    resetDetails() {
      this.$refs.repoEdit.resetModel();
    },
  },
};
</script>
