<template>
  <div>
    <modal-dlg
      ref="modal"
      title="Add user"
      :submit="submit"
      :validator="$validator"
      @hidden="onHidden"
      @shown="() => this.$refs.firstNameInput.focus()"
      no-fade
      lazy
    >
      <div class="form-group row">
        <label class="col-3 col-form-label" for="firstName">First name</label>
        <div class="col-9">
          <input
            id="firstName"
            ref="firstNameInput"
            :class="[{ 'is-invalid': errors.has('firstName') }, 'form-control']"
            v-model="user.firstName"
            v-validate="'required'"
            data-vv-as="First name"
            name="firstName"
            type="text"
          />
          <div class="invalid-feedback" v-show="errors.has('firstName')">
            {{ errors.first('firstName') }}
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-3 col-form-label" for="lastName">Last name</label>
        <div class="col-9">
          <input
            id="lastName"
            :class="[{ 'is-invalid': errors.has('lastName') }, 'form-control']"
            v-model="user.lastName"
            v-validate="'required'"
            data-vv-as="Last name"
            name="lastName"
            type="text"
          />
          <div class="invalid-feedback" v-show="errors.has('lastName')">
            {{ errors.first('lastName') }}
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-3 col-form-label" for="email">Email</label>
        <div class="col-9">
          <input
            id="email"
            :class="[{ 'is-invalid': errors.has('email') }, 'form-control']"
            v-model="user.email"
            v-validate="'required'"
            data-vv-as="Email"
            name="email"
            type="email"
          />
          <div class="invalid-feedback" v-show="errors.has('email')">
            {{ errors.first('email') }}
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-3 col-form-label" for="role">Role</label>
        <div class="col-9">
          <b-form-select id="role" v-model="user.role" :options="assignableRoles" class="" />
        </div>
      </div>
    </modal-dlg>
  </div>
</template>

<script>
import { default as ModalDlg } from '../components/ModalDlg.vue';
import { Role } from './users.js';
import { mapGetters } from 'vuex';

export default {
  name: 'UserCreate',
  components: {
    ModalDlg,
  },
  data() {
    return {
      modal: null,
      user: {},
    };
  },
  mounted() {
    this.modal = this.$refs.modal;
  },
  computed: {
    Role() {
      return Role;
    },
    assignableRoles() {
      const role = Role.get(this.currentUser.role);
      return role.canAssign;
    },
    ...mapGetters(['currentUser']),
  },
  methods: {
    show() {
      this.modal.show();
    },
    onHidden() {
      this.user = {};
    },
    submit() {
      // call API via modal to employ its event handler
      return this.modal
        .userApi()
        .invite(this.user)
        .then(() => this.$emit('ok'));
    },
  },
};
</script>
