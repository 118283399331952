<template>
  <div class="my-4 mx-auto fixed-width">
    <div class="row justify-content-center">
      <h3>Sign in</h3>
    </div>

    <b-alert :show="error" variant="danger"> Incorrect email or password </b-alert>

    <div class="card">
      <div id="login-form" class="card-body">
        <form @submit.prevent="onLogin" @keyup.enter="onLogin" novalidate>
          <div class="form-group">
            <label for="email" class="font-weight-bold">Email</label>
            <input
              id="email"
              :class="[{ 'is-invalid': errors.has('email') }, 'form-control']"
              name="email"
              type="text"
              v-model="email"
              v-validate="'required'"
              data-vv-as="email"
              autocomplete="email"
              @keyup.enter="onLogin"
            />
            <div class="invalid-feedback" v-show="errors.has('email')">
              {{ errors.first('email') }}
            </div>
          </div>

          <div class="form-group">
            <label for="password" class="font-weight-bold">Password</label>
            <router-link class="float-right" to="reset" variant="outline-primary"
              >Forgot password?</router-link
            >
            <input
              id="password"
              name="password"
              :class="[{ 'is-invalid': errors.has('password') }, 'form-control']"
              type="password"
              v-model="password"
              v-validate="'required|passwordStrong'"
              data-vv-as="Password"
              autocomplete="curent-password"
              @keyup.enter="onLogin"
            />
            <div class="invalid-feedback" v-show="errors.has('password')">
              {{ errors.first('password') }}
            </div>
          </div>

          <div class="form-group mb-0">
            <b-button type="submit" class="w-100" variant="outline-primary">Sign in</b-button>
          </div>
        </form>
      </div>
    </div>

    <div class="card mt-3 p-3 bg-light">
      <div class="row justify-content-center">
        New to SafeDigit?&nbsp;<router-link to="signup">Create an account</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { default as ApiMixin } from '../mixins/ApiMixin.vue';

export default {
  name: 'Login',
  mixins: [ApiMixin],
  data() {
    return {
      email: '',
      password: '',
      error: false,
    };
  },

  methods: {
    ...mapActions(['setCurrentUser', 'initStore']),
    async onLogin() {
      try {
        await this.authApi().loginAsync(this.email, this.password);
        const authenticityToken = this.authApi().getAuthenticityTokenForOtp();
        if (!authenticityToken) {
          const user = await this.userApi().getCurrent();
          this.setCurrentUser(user);
          // redirect might be set previously in router.beforeEach guard
          const redirect = this.$router.currentRoute.query.redirect;
          const location = redirect ? { path: redirect } : { name: 'home' };
          this.initStore();
          this.$router.push(location);
        } else {
          this.$router.push('login/otp');
        }
      } catch (err) {
        console.error(err);
        this.error = true;
        this.setCurrentUser(null);
      }
    },
  },
};
</script>

<style scoped>
.fixed-width {
  width: 25rem;
  min-width: 20rem;
}

.low-button {
  width: 48%;
}
</style>
