<script>
import * as Api from '../api.js';
import { ApiCallbacks, ApiState } from '../apiHelpers';
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'ApiMixin',
  created() {
    this.mxApiState = new ApiState(this.$store, this._getName());
    this.mxApiEventHandler = this.getApiEventHandler();
  },
  unmounted() {
    this._cleanup();
  },
  data() {
    return {
      mxApiEventHandler: null,
      mxApiState: null,
    };
  },
  computed: {
    ...mapGetters(['apiState']),
    /**
     * returns size of a 'PENDING' section in api_state
     */
    pendingSize() {
      return _.size(_.get(this.apiState, [ApiState.Type.PENDING]));
    },
    /**
     * returns 'PENDING' object for current component
     */
    pending() {
      return _.get(this.apiState, [ApiState.Type.PENDING, this._getName()]);
    },
    /**
     * returns size of a 'FAILURE' section in api_state
     */
    failureSize() {
      return _.size(_.get(this.apiState, [ApiState.Type.FAILURE]));
    },
    /**
     * returns 'FAILURE' object for current component
     */
    failure() {
      const failures = _.get(this.apiState, [ApiState.Type.FAILURE]);
      let message = '';
      if (failures) {
        _.forOwn(failures, function (value) {
          let msg = _.get(value, ['data', 'error']);
          if (!msg) {
            msg = _.get(value, ['data', 'global']);
          }
          if (msg) {
            message += msg;
          }
        });
      }
      return message ? message : 'Error loading information';
    },
  },
  methods: {
    ...mapActions(['setCurrentUser']),
    _getName() {
      return `${this.$options.name}_${this._uid}`;
    },
    /**
     * The mixin client can be override this method to replace ApiCallbacks with it's own event handler
     * @return {ApiCallbacks}
     */
    getApiEventHandler() {
      return new ApiCallbacks(this.mxApiState, this.$router);
    },
    setFailure(status, message) {
      this.mxApiState.setFailure({ status, data: { error: message } });
    },
    _cleanup() {
      if (this.mxApiEventHandler) {
        this.mxApiEventHandler.clear();
      }
    },
    clearApiState() {
      this._cleanup();
    },
    userApi() {
      return new Api.UserApi(this.mxApiEventHandler);
    },
    accountApi() {
      return new Api.AccountApi(this.mxApiEventHandler);
    },
    dataRepositoryApi() {
      return new Api.DataRepositoryApi(this.mxApiEventHandler);
    },
    dataTableApi() {
      return new Api.DataTableApi(this.mxApiEventHandler);
    },
    dataApiApi() {
      return new Api.DataApiApi(this.mxApiEventHandler);
    },
    authApi() {
      return new Api.AuthApi(this.mxApiEventHandler);
    },
    taskApi() {
      return new Api.TaskApi(this.mxApiEventHandler);
    },
    subscriptionApi() {
      return new Api.SubscriptionApi(this.mxApiEventHandler);
    },
    auditApi() {
      return new Api.AuditApi(this.mxApiEventHandler);
    },
    tokensApi() {
      return new Api.TokensApi(this.mxApiEventHandler);
    },
    keysApi() {
      return new Api.KeysApi(this.mxApiEventHandler);
    },
    variablesApi() {
      return new Api.VariablesApi(this.mxApiEventHandler);
    },
    catalogApi() {
      return new Api.CatalogApi(this.mxApiEventHandler);
    },
    integrationApi() {
      return new Api.IntegrationsApi(this.mxApiEventHandler);
    },
    projectsApi() {
      return new Api.ProjectsApi(this.mxApiEventHandler);
    },
    blueprintsApi() {
      return new Api.BlueprintsApi(this.mxApiEventHandler);
    },
  },
};
</script>
