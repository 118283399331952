var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col"},[_c('b-breadcrumb',{attrs:{"items":[
      { text: 'Repositories', to: { name: 'repo-list' } },
      { text: _vm.repositoryId, to: { name: 'repo-edit' } },
      { text: 'Tables', active: true },
    ]}}),_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col text-right"},[_c('b-button',{attrs:{"variant":"outline-primary","size":"sm"},on:{"click":function($event){$event.preventDefault();return _vm.addTable.apply(null, arguments)}}},[_vm._v("New table")])],1)]),(_vm.tables && _vm.tables.length)?_vm._l((_vm.tables),function(table){return _c('div',{key:table.tableReferenceId,staticClass:"card my-4"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"card-title row py-1 align-items-center"},[_c('div',{staticClass:"col-auto text-left mr-3"},[_c('img',{staticClass:"icon",attrs:{"src":require('../../../../images/table.png')}})]),_c('div',{staticClass:"col text-left"},[_c('router-link',{attrs:{"to":{
                name: 'table',
                params: { repositoryId: _vm.repositoryId, tableReferenceId: table.referenceId },
              }}},[_vm._v(" "+_vm._s(table.name)+" ")])],1),_c('div',{staticClass:"col-auto text-right"},[_c('b-dropdown',{attrs:{"variant":"link","text":"Actions"}},[_c('b-dd-item',{attrs:{"to":{
                  name: 'table',
                  params: { repositoryId: _vm.repositoryId, tableReferenceId: table.referenceId },
                }}},[_vm._v(" edit ")]),_c('b-dd-item',{on:{"click":function($event){return _vm.deleteTable(table.referenceId)}}},[_vm._v("delete")]),_c('b-dd-item',{attrs:{"disabled":""}},[_vm._v("disable")])],1)],1)]),(_vm.isDataUploaded(table))?_c('div',[_c('stat-card',{attrs:{"text":"Size","value":_vm.getSize(table)}}),_c('stat-card',{attrs:{"text":"Records","value":table.rowCount}}),_c('stat-card',{attrs:{"text":"Indexes","value":table.indexes.length}})],1):_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('router-link',{attrs:{"to":{
                name: 'table',
                params: { repositoryId: _vm.repositoryId, tableReferenceId: table.referenceId },
              }}},[_vm._v(" Upload data ")])],1)])])])}):_vm._e(),(!(_vm.tables && _vm.tables.length))?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_vm._v("The list is empty")])]):_vm._e(),_c('b-modal',{ref:"modalDetails",attrs:{"title":"Table","no-fade":"","static":""},on:{"ok":_vm.saveDetails,"cancel":_vm.resetDetails,"shown":() => this.$refs.tableDetails.shown()}},[_c('table-details',{ref:"tableDetails"})],1),_c('route-button',{attrs:{"text":"Back","route":{ name: 'repo-list' }}})],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col"},[_c('h4',[_vm._v("Tables")])])
}]

export { render, staticRenderFns }