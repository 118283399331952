export default {
  currentUser: null,
  error: null,
  api_state: {},
  authTemplates: {},
  mySubscriptionsMap: {},
  subscriptionsToMeMap: {},
  connectorsMap: {},
  blueprint: {},
  blueprintsMap: {},
  installsMap: {},
  logsMap: {},
  projectsMap: {},
  project: {},
  blueprintBackup: {},
  transform: {},
  transformsMap: {},
  connectorsExamplesMap: {},
  // These versions are required for the reactivity to work with Map
  mySubscriptionsMapVersion: 1, // we start with 1 because 0 is falsy
  subscriptionsToMeMapVersion: 1,
};
