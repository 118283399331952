<template>
  <b-dropdown :text="current.text" variant="light">
    <b-dropdown-item
      v-for="(option, index) in options"
      :key="index"
      :value="option.value"
      @click="onChange(option)"
    >
      {{ option.text }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
  name: 'TimeRangeDropdown',
  props: {
    options: {
      type: Array,
      required: true,
    },
    selection: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      current: this.selection,
    };
  },
  watch: {
    selection: function () {
      this.current = this.selection;
    },
  },
  methods: {
    onChange(option) {
      this.current = option;
      this.$emit('change', option);
    },
  },
};
</script>

<style scoped></style>
