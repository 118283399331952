import * as components from '.';

export default [
  {
    path: '/repos/:repositoryId/api/:apiId',
    component: components.Api,
    name: 'api',
    props: true,
  },
  {
    path: '/repos/:repositoryId/apis',
    component: components.ApiList,
    name: 'apis',
    props: true,
  },
];
