import decode from 'jwt-decode';

const TOKEN_KEY = 'token';
const REFRESH_TOKEN_KEY = 'refreshToken';
const AUTHENTICITY_TOKEN_KEY = 'authenticityToken';

class Auth {
  static login(tokens) {
    const token = tokens ? tokens.token : null;
    const refreshToken = tokens ? tokens.refreshToken : null;
    const authenticityToken = tokens ? tokens.authenticityToken : null;
    // WARNING implicit logic, please read the comments below
    // In case successful otp first stage, token and refresh token will be cleared
    // In case of successful second stage authenticity token will be cleared
    Auth.setToken(TOKEN_KEY, token);
    Auth.setToken(REFRESH_TOKEN_KEY, refreshToken);
    Auth.setToken(AUTHENTICITY_TOKEN_KEY, authenticityToken);
  }

  static logout() {
    Auth.setToken(TOKEN_KEY, null);
    Auth.setToken(REFRESH_TOKEN_KEY, null);
  }

  static areTokensValid() {
    const token = Auth.getToken(TOKEN_KEY);
    const refreshToken = Auth.getToken(REFRESH_TOKEN_KEY);
    return (
      token && !Auth.isTokenExpired(token) && refreshToken && !Auth.isTokenExpired(refreshToken)
    );
  }

  static getToken(key) {
    return localStorage.getItem(key);
  }

  static setToken(key, token) {
    if (token) {
      localStorage.setItem(key, token);
    } else {
      localStorage.removeItem(key);
    }
  }

  static getTokenExpirationDate(encodedToken) {
    let token;
    try {
      token = decode(encodedToken);
    } catch (e) {
      return null;
    }
    if (!token.exp) {
      return null;
    }

    const date = new Date(0);
    date.setUTCSeconds(token.exp);

    return date;
  }

  static isTokenExpired(token) {
    const expirationDate = Auth.getTokenExpirationDate(token);
    return !expirationDate || expirationDate < new Date();
  }

  static getPermissions() {
    let scopes;
    let token = Auth.getToken(TOKEN_KEY);
    if (token) {
      try {
        token = decode(token);
        scopes = token.scopes;
      } catch (e) {
        console.error('failed to decode jwt-token');
        console.error(e);
      }
    }
    return new Permissions(scopes);
  }

  static getLimitations() {
    const token = Auth.getToken(TOKEN_KEY);
    if (token) {
      try {
        return new Limitations(decode(token).limitations);
      } catch (e) {
        console.error('failed to decode jwt-token');
        console.error(e);
        throw e;
      }
    }
    return null;
  }

  static get TOKEN_KEY() {
    return TOKEN_KEY;
  }

  static get REFRESH_TOKEN_KEY() {
    return REFRESH_TOKEN_KEY;
  }

  static get AUTHENTICITY_TOKEN_KEY() {
    return AUTHENTICITY_TOKEN_KEY;
  }
}

const USER_READ = 'USER_READ';
const USER_MANAGEMENT = 'USER_MANAGEMENT';
const DATA_READ = 'DATA_READ';
const DATA_MANAGEMENT = 'DATA_MANAGEMENT';
const EMPLOYEE_USER_READ = 'EMPLOYEE_USER_READ';
const EMPLOYEE_DATA_READ = 'EMPLOYEE_DATA_READ';
const INTEGRATIONS = 'INTEGRATIONS';

class Permission {
  static get USER_READ() {
    return USER_READ;
  }

  static get USER_MANAGEMENT() {
    return USER_MANAGEMENT;
  }

  static get DATA_MANAGEMENT() {
    return DATA_MANAGEMENT;
  }

  static get DATA_READ() {
    return DATA_READ;
  }

  static get EMPLOYEE_USER_READ() {
    return EMPLOYEE_USER_READ;
  }

  static get EMPLOYEE_DATA_READ() {
    return EMPLOYEE_DATA_READ;
  }

  static get INTEGRATIONS() {
    return INTEGRATIONS;
  }
}

class Permissions {
  constructor(permissions) {
    this._permissions = new Set(permissions);
  }

  has(permission) {
    return this._permissions.has(permission);
  }
}

class Limitations {
  constructor(limitations) {
    this._limitations = limitations ? limitations : {};
  }

  validateFileSize(file) {
    let size_limit_bytes = this._limitations['MAX_TABLE_SIZE_MB'];
    size_limit_bytes = size_limit_bytes ? size_limit_bytes * 1024 * 1024 : Number.MAX_SAFE_INTEGER;
    return !size_limit_bytes || file.size <= size_limit_bytes;
  }
}

const ROLE_EMPLOYEE_ADMIN = 'ROLE_EMPLOYEE_ADMIN';
const ROLE_ADMIN = 'ROLE_ADMIN';
const ROLE_INTEGRATIONS_ADMIN = 'ROLE_INTEGRATIONS_ADMIN';
const ROLE_DATA_MANAGER = 'ROLE_DATA_MANAGER';
const ROLE_USER_MANAGER = 'ROLE_USER_MANAGER';

class Role {
  static get ROLE_EMPLOYEE_ADMIN() {
    return ROLE_EMPLOYEE_ADMIN;
  }

  static get ROLE_ADMIN() {
    return ROLE_ADMIN;
  }

  static get ROLE_INTEGRATIONS_ADMIN() {
    return ROLE_INTEGRATIONS_ADMIN;
  }

  static get ROLE_DATA_MANAGER() {
    return ROLE_DATA_MANAGER;
  }

  static get ROLE_USER_MANAGER() {
    return ROLE_USER_MANAGER;
  }
}

export { Auth, Permissions, Permission, Role };
