<template>
  <div class="sd-user-list">
    <b-button-toolbar key-nav class="mb-3">
      <b-button
        variant="outline-primary"
        size="sm"
        class="mx-1 font-weight-bold"
        @click="createUser"
        >Add user
      </b-button>
      <b-dropdown
        text="User actions"
        variant="outline-primary"
        size="sm"
        class="mx-1 font-weight-bold"
        toggle-class="font-weight-bold"
      >
        <b-dropdown-item
          :disabled="!canSetStatus(Status.ENABLED)"
          @click="setStatus(Status.ENABLED)"
          >Enable
        </b-dropdown-item>
        <b-dropdown-item
          :disabled="!canSetStatus(Status.DISABLED)"
          @click="setStatus(Status.DISABLED)"
        >
          Disable
        </b-dropdown-item>
      </b-dropdown>
    </b-button-toolbar>

    <div class="row no-gutters table-header">
      <div class="col py-2">
        <div>
          <span slot="prepend" class="search"></span>
          <input type="text" v-model="filter" placeholder="Find user" class="search" />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <b-table
          hover
          bordered
          :items="users"
          :fields="COLUMNS"
          :filter="filter"
          thead-class="table-header"
          tbody-class="table-body"
          :sort-compare="sort"
        >
          <template v-slot:cell(rb)="data">
            <input type="radio" name="user" :value="data.item.referenceId" v-model="selected" />
          </template>

          <template v-slot:cell(firstNamelastName)="data">
            <router-link :to="{ path: 'users/' + data.item.referenceId }">
              {{ User.getFullName(data.item) }}
            </router-link>
          </template>

          <template v-slot:cell(role)="data">
            {{ getRoleText(data.item) }}
          </template>

          <template v-slot:cell(status)="data">
            {{ Status.get(data.item.status).text }}
          </template>
        </b-table>
      </div>
    </div>

    <div class="card-body"></div>

    <user-create ref="createUser" @ok="load"></user-create>

    <b-modal
      ref="userDetailsModal"
      title="Invite user"
      @ok="saveUser"
      @cancel="resetUser"
      no-fade
      static
    >
      <user-details ref="userDetails"></user-details>
    </b-modal>
  </div>
</template>

<script>
import { default as ApiMixin } from '../mixins/ApiMixin.vue';
import UserCreate from './UserCreate.vue';
import UserDetails from './UserDetails.vue';
import { User, Role, Status } from './users.js';
import _ from 'lodash';

const COLUMNS = [
  {
    key: 'rb',
    label: '',
    sortable: false,
    thClass: 'header-cb',
  },
  {
    key: 'firstNamelastName',
    label: 'Full name',
    sortable: true,
  },
  {
    key: 'role',
    label: 'Role',
    sortable: true,
  },
  {
    key: 'status',
    label: 'Status',
    sortable: true,
  },
];

export default {
  name: 'UserList',
  mixins: [ApiMixin],
  data() {
    return {
      users: [],
      COLUMNS: COLUMNS,
      filter: null,
      selected: null,
    };
  },
  components: {
    UserCreate,
    UserDetails,
  },
  mounted() {
    this.load();
  },
  computed: {
    Status() {
      return Status;
    },
    User() {
      return User;
    },
  },
  methods: {
    load() {
      this.userApi()
        .getUsers()
        .then((users) => (this.users = users));
    },
    createUser() {
      this.$refs.createUser.show();
    },
    saveUser() {
      this.error = null;
      const model = this.$refs.userDetails.getModel();
      this.userApi()
        .updateUser(model)
        .then(() => this.load());
    },
    resetUser() {
      this.$refs.userDetails.resetModel();
    },
    getRoleText(user) {
      const role = Role.get(user.role);
      return role ? role.text : '';
    },
    getSelectedUser() {
      const referenceId = this.selected;
      return referenceId ? _.find(this.users, ['referenceId', referenceId]) : null;
    },
    getSelectedUserIdx() {
      const referenceId = this.selected;
      return referenceId ? _.findIndex(this.users, ['referenceId', referenceId]) : null;
    },
    setStatus(status) {
      const idx = this.getSelectedUserIdx();
      const user = this.users[idx];
      user.status = status.value;
      this.userApi()
        .updateUser(user)
        .then(() => this.$set(this.users, idx, user));
    },
    sort(a, b, key) {
      if (key === 'fullName') {
        return User.getFullName(a).localeCompare(User.getFullName(b));
      }
      return null;
    },
    canSetStatus(status) {
      const user = this.getSelectedUser();
      return user && Status.get(user.status) !== status;
    },
  },
};
</script>

<style>
.table-header {
  background-color: #999;
  background-image: linear-gradient(#eee, #e0e0e0);
  /*color: #444;*/
}

span.search {
  padding-right: 0.9em;
  background: url('../../../images/search.svg') no-repeat center;
  z-index: 10;
  position: relative;
  left: 20px;
}

input.search {
  padding-left: 25px;
}

.header-cb {
  width: 1em;
}

.table-header th {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.table-body td {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
</style>
