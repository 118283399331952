<template>
  <div class="sd-account-list">
    <div class="row">
      <div class="col"><h4>Accounts</h4></div>
    </div>

    <b-button-toolbar key-nav class="mb-3">
      <b-button
        variant="outline-primary"
        size="sm"
        class="mx-1 font-weight-bold"
        @click="createNew"
      >
        Add account
      </b-button>
    </b-button-toolbar>

    <div class="row no-gutters table-header">
      <div class="col py-2">
        <div>
          <span slot="prepend" class="search"></span>
          <input type="text" v-model="filter" placeholder="Find" class="search" />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <b-table
          hover
          bordered
          :items="accounts"
          :fields="COLUMNS"
          :filter="filter"
          thead-class="table-header"
          tbody-class="table-body"
          sort-by="name"
          :sort-compare="sort"
        >
          <template v-slot:cb="data">
            <input type="radio" name="user" :value="data.item.id" v-model="selected" />
          </template>

          <template v-slot:name="data">
            <router-link :to="{ path: 'accounts/' + data.item.id }">
              {{ data.item.name }}
            </router-link>
          </template>

          <template v-slot:type="data">
            {{ data.item.type }}
          </template>
        </b-table>
      </div>
    </div>

    <modal-dlg
      ref="addAccount"
      title="Invite user"
      :submit="onCreateNew"
      :validator="$validator"
      @shown="() => this.$refs.emailInput.focus()"
      no-fade
      lazy
    >
      <div class="form-group row">
        <label class="col-2 col-form-label" for="email">Email</label>
        <div class="col">
          <input
            id="email"
            ref="emailInput"
            :class="[{ 'is-invalid': errors.has('email') }, 'form-control']"
            v-model="invite_email"
            v-validate="'required'"
            data-vv-as="Email"
            name="email"
            type="email"
          />
          <div class="invalid-feedback" v-show="errors.has('email')">
            {{ errors.first('email') }}
          </div>
        </div>
      </div>
    </modal-dlg>
  </div>
</template>

<script>
import { default as ApiMixin } from '../mixins/ApiMixin.vue';
import { default as ModalDlg } from '../components/ModalDlg.vue';
import { default as ValidationMixin } from '../mixins/ValidationMixin.vue';

const COLUMNS = {
  cb: {
    label: '',
    sortable: false,
    thClass: 'header-cb',
  },
  name: {
    label: 'Name',
    sortable: true,
  },
  type: {
    label: 'Type',
    sortable: true,
  },
};

export default {
  name: 'AccountList',
  mixins: [ApiMixin, ValidationMixin],
  data() {
    return {
      accounts: [],
      COLUMNS: COLUMNS,
      filter: null,
      selected: null,
      invite_email: 'xx',
    };
  },
  components: {
    ModalDlg,
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      const accounts = await this.accountApi().getAccounts();
      this.accounts = accounts;
    },
    createNew() {
      this.$refs.addAccount.show();
    },
    sort(a, b, key) {
      return a[key].localeCompare(b[key]);
    },
    onCreateNew() {
      return this.accountApi()
        .invite({ email: this.invite_email })
        .catch((error) => {
          this.clearApiState();
          throw error;
        });
    },
  },
};
</script>

<style>
.table-header {
  background-color: #999;
  background-image: linear-gradient(#eee, #e0e0e0);
  /*color: #444;*/
}

span.search {
  padding-right: 0.9em;
  background: url('../../../images/search.svg') no-repeat center;
  z-index: 10;
  position: relative;
  left: 20px;
}

input.search {
  padding-left: 25px;
}

.header-cb {
  width: 1em;
}

.table-header th {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.table-body td {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
</style>
