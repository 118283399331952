import path from 'path';
import { Api } from './Api';

class UserApi extends Api {
  static URL_USERS = 'users';

  constructor(callbacks) {
    super(callbacks);
  }

  /**
   * Retrieve list of users
   */
  getUsers() {
    return super.get(UserApi.URL_USERS);
  }

  /**
   * Retrieve single user by id
   */
  getUser(userId) {
    const url = path.join(UserApi.URL_USERS, '' + userId);
    return super.get(url);
  }

  /**
   * Retrieve current user
   */
  getCurrent() {
    const url = path.join(UserApi.URL_USERS, 'current');
    return super.get(url);
  }

  /**
   * Update existing user
   */
  updateUser(user) {
    const url = path.join(UserApi.URL_USERS, '' + user.referenceId);
    return super.put(url, user);
  }

  /**
   * Invite new user
   */
  invite(user) {
    return super.post(UserApi.URL_USERS, user);
  }

  /**
   *
   * Activate user
   */
  activate(token, form) {
    const url = path.join(UserApi.URL_USERS, 'activate', token);
    return super.post(url, form, false);
  }

  /**
   * Validate token
   */
  validateToken(token) {
    if (token) {
      const url = path.join(UserApi.URL_USERS, 'activate', token);
      return super.get(url, false);
    } else {
      return Promise.reject('No token provided');
    }
  }

  resetPassword(email) {
    const url = path.join(UserApi.URL_USERS, 'reset');
    return this.post(url, email, false);
  }

  async setTokenAsync() {
    const url = path.join(UserApi.URL_USERS, 'current/otp/set');
    return this.post(url, null, true);
  }

  async enableMFA() {
    const url = path.join(UserApi.URL_USERS, 'current/otp/enable');
    return this.post(url, null, true);
  }

  async disableMFA() {
    const url = path.join(UserApi.URL_USERS, 'current/otp/disable');
    return this.post(url, null, true);
  }

  async verifyOtpToken(token) {
    const url = path.join(UserApi.URL_USERS, 'current/otp/verify/', token);
    return this.post(url, null, true);
  }
}

export default UserApi;
