export class SourceType {
  static SELF_CONTAINED = 'SELF_CONTAINED';
  static DERIVED = 'DERIVED';
}

export class ValueBuilderType {
  static SELF_CONTAINED = 'SELF_CONTAINED';
  static HEAD = 'HEAD';
  static TAIL = 'TAIL';

  static all() {
    return [ValueBuilderType.HEAD, ValueBuilderType.TAIL];
  }
}

export class Matcher {
  constructor(name, text) {
    this.name = name;
    this.text = text;
  }

  static CASE_SENSITIVE = new Matcher('case_sensitive', 'Case Sensitive');
  static CASE_INSENSITIVE = new Matcher('case_insensitive', 'Case Insensitive');

  static MMDDYYYY = new Matcher('MONTH_DAY_YEAR', 'MM/DD/YYYY');
  static DDMMYYYY = new Matcher('DAY_MONTH_YEAR', 'DD/MM/YYYY');

  static PHONE = new Matcher('phone', '(###)-###-####');

  static getMatcher(name) {
    switch (name) {
      case Matcher.CASE_SENSITIVE.name:
        return Matcher.CASE_SENSITIVE;
      case Matcher.CASE_INSENSITIVE.name:
        return Matcher.CASE_INSENSITIVE;
      case Matcher.MMDDYYYY.name:
        return Matcher.MMDDYYYY;
      case Matcher.DDMMYYYY.name:
        return Matcher.DDMMYYYY;
      case Matcher.PHONE.name:
        return Matcher.PHONE;
      case Matcher.ANY_WORD_CASE_SENSITIVE.name:
        return Matcher.ANY_WORD_CASE_SENSITIVE;
      case Matcher.ANY_WORD_CASE_INSENSITIVE:
        return Matcher.ANY_WORD_CASE_INSENSITIVE;
      default:
        throw new Error('Not supported value - ' + name);
    }
  }
}

export class Type {
  static TEXT = new Type('text', 'Text');
  static DATE = new Type('date', 'Date');
  static PHONE = new Type('phone', 'Phone');
  static ANY_WORD = new Type('any_word', 'Any Word');

  static TEXT_MATCHERS = [Matcher.CASE_INSENSITIVE, Matcher.CASE_SENSITIVE];

  static DATE_MATCHERS = [Matcher.MMDDYYYY, Matcher.DDMMYYYY];

  constructor(name, text) {
    this.name = name;
    this.text = text;
  }

  static getType(typeName) {
    switch (typeName) {
      case Type.TEXT.name:
        return Type.TEXT;
      case Type.DATE.name:
        return Type.DATE;
      case Type.PHONE.name:
        return Type.PHONE;
      case Type.ANY_WORD.name:
        return Type.ANY_WORD;
      default:
        throw new Error('Not supported value - ' + typeName);
    }
  }

  static getTypes() {
    return [Type.TEXT, Type.DATE, Type.PHONE, Type.ANY_WORD];
  }

  static getMatchers(typeName) {
    switch (typeName) {
      case Type.TEXT.name:
        return Type.TEXT_MATCHERS;
      case Type.DATE.name:
        return Type.DATE_MATCHERS;
      case Type.PHONE.name:
        return [Matcher.PHONE];
      case Type.ANY_WORD.name:
        return Type.TEXT_MATCHERS;
      default:
        throw new Error('Not supported type - ' + typeName);
    }
  }

  static isMatcherOfType(matcherName, typeName) {
    return !!Type.getMatchers(typeName).find((matcher) => matcher.name === matcherName);
  }
}
