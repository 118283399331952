<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-1">
          <img class="repository-icon" :src="require('../../../images/api.png')" />
        </div>

        <div class="col-11">
          <div class="row">
            <div class="col text-left">
              <h4>{{ api.name }}</h4>
            </div>
          </div>

          <div class="row mt-3" v-if="api.owner">
            <div class="col-auto font-weight-bold">Provided by:</div>
            <div class="col">{{ api.owner.name }}</div>

            <subscription-manipulation-button
              v-if="subscription"
              :subscription="subscription"
              col-auto
              text-right
            />
          </div>

          <div class="row mt-3">
            <div class="col-auto font-weight-bold">Overview</div>
          </div>
          <div class="row mt-1">
            <div class="col">{{ api.description }}</div>
          </div>

          <div v-if="isSubscribed">
            <div class="row mt-3">
              <div class="col-auto font-weight-bold">Usage</div>
            </div>

            <div class="row mt-3">
              <div class="col-auto font-weight-bold">{{ apiUrl }}</div>
              <div class="col-auto">
                <b-link @click.prevent="showToken()">Download key</b-link>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-auto font-weight-bold">Available tables</div>
            </div>

            <div v-for="table in api.tables" class="ml-5" :key="table.referenceId">
              <div class="row mt-3">
                <div class="col-auto">{{ table.name }}</div>
              </div>
              <div v-for="field in table.fields" class="row pl-5" :key="field.id">
                <div class="col-2">
                  {{ field.name }}
                </div>
                <div class="col-auto">
                  {{ getTypeName(field.type) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <refresh-token ref="tokenModal"></refresh-token>
  </div>
</template>

<script>
import { default as ApiMixin } from '../mixins/ApiMixin.vue';
import { mapGetters } from 'vuex';
import { SubscriptionStatus } from '../data/subscriptions/statuses.js';
import * as Fields from '../data/tables/fields.js';
import SubscriptionManipulationButton from '../components/SubscriptionManipulationButton.vue';
import RefreshToken from '../components/RefreshToken.vue';

export default {
  name: 'ApiSubscriberInfo',
  mixins: [ApiMixin],
  components: {
    RefreshToken,
    SubscriptionManipulationButton,
  },
  props: {
    apiId: {
      required: true,
    },
  },
  computed: {
    ...mapGetters(['currentUser', 'myApiSubscriptionsByApi']),
    subscription: function () {
      return this.myApiSubscriptionsByApi(this.apiId);
    },
    status() {
      return this.subscription?.status;
    },
    isSubscribed() {
      return this.subscription?.status === SubscriptionStatus.APPROVED;
    },
    apiUrl() {
      return window.location.origin + '/api/' + this.api.prefix;
    },
  },
  data() {
    return {
      api: {},
      SubscriptionStatus,
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.catalogApi()
        .getApi(this.apiId)
        .then((api) => (this.api = api));
    },
    getTypeName(type) {
      return Fields.Type.getType(type).text;
    },
    showToken() {
      this.subscriptionApi()
        .getRefreshToken(this.api.subscription.identifier)
        .then((token) => this.$refs.tokenModal.open(token));
    },
  },
};
</script>
