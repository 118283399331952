<script>
import { Permission, Role } from '../auth/auth';
import { mapGetters } from 'vuex';

export default {
  name: 'PermissionMixin',
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['currentUser']),
    // permissions
    USER_READ: () => Permission.USER_READ,
    USER_MANAGEMENT: () => Permission.USER_MANAGEMENT,
    DATA_MANAGEMENT: () => Permission.DATA_MANAGEMENT,
    DATA_READ: () => Permission.DATA_READ,
    EMPLOYEE_USER_READ: () => Permission.EMPLOYEE_USER_READ,
    EMPLOYEE_DATA_READ: () => Permission.EMPLOYEE_DATA_READ,
    INTEGRATIONS: () => Permission.INTEGRATIONS,
    // roles
    ROLE_EMPLOYEE_ADMIN: () => Role.ROLE_EMPLOYEE_ADMIN,
    ROLE_ADMIN: () => Role.ROLE_ADMIN,
    ROLE_INTEGRATIONS_ADMIN: () => Role.ROLE_INTEGRATIONS_ADMIN,
    ROLE_DATA_MANAGER: () => Role.ROLE_DATA_MANAGER,
    ROLE_USER_MANAGER: () => Role.ROLE_USER_MANAGER,
  },
  methods: {
    hasPermission(permission) {
      const permissions = this.currentUser && this.currentUser.permissions;
      return permissions ? permissions.has(permission) : false;
    },
    inRole(roles) {
      return this.currentUser && roles.includes(this.currentUser.role);
    },
  },
};
</script>
