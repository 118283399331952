<template>
  <div class="col">
    <b-breadcrumb
      :items="[
        { text: 'Repositories', to: { name: 'repo-list' } },
        { text: repositoryId, to: { name: 'repo-edit' } },
        { text: 'Tables', active: true },
      ]"
    />

    <div class="row">
      <div class="col"><h4>Tables</h4></div>
      <div class="col text-right">
        <b-button @click.prevent="addTable" variant="outline-primary" size="sm">New table</b-button>
      </div>
    </div>

    <template v-if="tables && tables.length">
      <div class="card my-4" v-for="table in tables" :key="table.tableReferenceId">
        <div class="card-body">
          <div class="card-title row py-1 align-items-center">
            <div class="col-auto text-left mr-3">
              <img class="icon" :src="require('../../../../images/table.png')" />
            </div>

            <div class="col text-left">
              <router-link
                :to="{
                  name: 'table',
                  params: { repositoryId: repositoryId, tableReferenceId: table.referenceId },
                }"
              >
                {{ table.name }}
              </router-link>
            </div>

            <div class="col-auto text-right">
              <b-dropdown variant="link" text="Actions">
                <b-dd-item
                  :to="{
                    name: 'table',
                    params: { repositoryId: repositoryId, tableReferenceId: table.referenceId },
                  }"
                >
                  edit
                </b-dd-item>
                <b-dd-item @click="deleteTable(table.referenceId)">delete</b-dd-item>
                <b-dd-item disabled>disable</b-dd-item>
              </b-dropdown>
            </div>
          </div>

          <div v-if="isDataUploaded(table)">
            <stat-card text="Size" :value="getSize(table)"></stat-card>
            <stat-card text="Records" :value="table.rowCount"></stat-card>
            <stat-card text="Indexes" :value="table.indexes.length"></stat-card>
          </div>
          <div v-else class="row">
            <div class="col">
              <router-link
                :to="{
                  name: 'table',
                  params: { repositoryId: repositoryId, tableReferenceId: table.referenceId },
                }"
              >
                Upload data
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </template>

    <div v-if="!(tables && tables.length)" class="row">
      <div class="col">The list is empty</div>
    </div>

    <b-modal
      ref="modalDetails"
      title="Table"
      @ok="saveDetails"
      @cancel="resetDetails"
      @shown="() => this.$refs.tableDetails.shown()"
      no-fade
      static
    >
      <table-details ref="tableDetails"></table-details>
    </b-modal>

    <route-button text="Back" :route="{ name: 'repo-list' }"></route-button>
  </div>
</template>

<script>
import { default as ApiMixin } from '../../mixins/ApiMixin.vue';
import { mapGetters } from 'vuex';
import TableDetails from './TableDetails.vue';
import StatCard from '../../components/StatCard.vue';
import RouteButton from '../../components/RouteButton.vue';
import { BBreadcrumb } from 'bootstrap-vue';

import filesize from 'filesize';

export default {
  name: 'TableList',
  mixins: [ApiMixin],
  props: {
    repositoryId: {
      required: true,
    },
  },
  data() {
    return {
      tables: null,
    };
  },
  computed: mapGetters(['user']),
  components: {
    TableDetails,
    StatCard,
    RouteButton,
    BBreadcrumb,
  },
  mounted() {
    this.load();
  },
  methods: {
    load: function () {
      this.dataTableApi()
        .getTables(this.repositoryId)
        .then((tables) => (this.tables = tables));
    },
    addTable() {
      this.$refs.tableDetails.setModel(null);
      this.$refs.modalDetails.show();
    },
    saveDetails() {
      const model = this.$refs.tableDetails.getModel();
      this.dataTableApi()
        .createTable(this.repositoryId, model)
        .then(() => this.load())
        .catch(() => (this.error = 'Error saving data'));
    },
    resetDetails() {
      this.$refs.tableDetails.resetModel();
    },
    deleteTable: function (tableReferenceId) {
      this.dataTableApi()
        .deleteTable(this.repositoryId, tableReferenceId)
        .then(() => this.load());
    },
    getSize(table) {
      return filesize(table.size);
    },
    isDataUploaded(table) {
      return table && table.fields && table.fields.length;
    },
  },
};
</script>

<style scoped>
.icon {
  width: 30px;
}
</style>
