<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col text-left">
          <h2>{{ title }}</h2>
        </div>
      </div>

      <table class="table table-hover">
        <thead>
          <tr>
            <th>API</th>
            <th>Created by</th>
            <th>Created on</th>
            <th v-if="!mySubscriptions">Subscriber</th>
            <th>Used</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(subscription, idx) in orderedSubscriptions" :key="idx">
            <td v-if="mySubscriptions">
              <router-link
                :to="{ name: 'api-info', params: { apiId: subscription.api.referenceId } }"
              >
                {{ subscription.api.name }}
              </router-link>
            </td>
            <td v-else>
              <router-link
                :to="{
                  name: 'api',
                  params: {
                    repositoryId: subscription.api.repositoryReferenceId,
                    apiId: subscription.api.referenceId,
                  },
                }"
              >
                {{ subscription.api.name }}
              </router-link>
            </td>
            <td>{{ subscription.createdBy.firstName }} {{ subscription.createdBy.lastName }}</td>
            <td>{{ formatInstant(subscription.created) }}</td>
            <td v-if="!mySubscriptions">{{ subscription.subscriber }}</td>
            <td>{{ getUsage(subscription) }}</td>
            <td>
              <subscription-manipulation-button :subscription="subscription" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { formatInstant } from '../../dates.js';
import SubscriptionManipulationButton from '../../components/SubscriptionManipulationButton.vue';
import { orderBy } from 'lodash';

export default {
  name: 'ApiSubscriptionList',
  components: {
    SubscriptionManipulationButton,
  },
  props: {
    subscriptions: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    mySubscriptions: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    orderedSubscriptions: function () {
      return orderBy([...this.subscriptions].sort(), 'created', 'desc');
    },
  },
  methods: {
    formatInstant,
    getUsage(subscription) {
      return subscription.used;
    },
  },
};
</script>
