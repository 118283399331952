<template>
  <div class="my-4 mx-auto fixed-width">
    <div class="row justify-content-center">
      <h3>Two-factor authentication</h3>
    </div>

    <b-alert :show="error" variant="danger"> Incorrect authentication code </b-alert>

    <div class="card">
      <div id="otp-login-form" class="card-body">
        <form @submit.prevent="onOtpLogin" novalidate>
          <div class="form-group">
            <input
              id="authenticityToken"
              :class="[{ 'is-invalid': errors.has('authenticityToken') }, 'form-control']"
              name="authenticityToken"
              v-model="authenticityToken"
              type="hidden"
            />
          </div>

          <div class="form-group">
            <label for="OTP" class="font-weight-bold">Authentication code</label>
            <input
              ref="otpInput"
              name="OTP"
              :class="[{ 'is-invalid': errors.has('OTP') }, 'form-control']"
              type="text"
              v-model="otp"
              v-validate="'digits:6'"
              autocomplete="off"
              placeholder="6-digit code"
              @input="onOtpInput"
            />
            <div class="invalid-feedback" v-show="errors.has('OTP')">
              {{ errors.first('OTP') }}
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { default as ApiMixin } from '../mixins/ApiMixin.vue';

export default {
  name: 'OtpLogin',
  mixins: [ApiMixin],
  mounted() {
    this.$refs.otpInput.focus();
  },
  computed: {
    authenticityToken: function () {
      return this.authApi().getAuthenticityTokenForOtp();
    },
  },
  data() {
    return {
      otp: '',
      error: false,
    };
  },
  methods: {
    ...mapActions(['setCurrentUser', 'initStore']),
    async onOtpInput() {
      if (this.otp.length == 6) {
        try {
          await this.authApi().otpLoginAsync(this.authenticityToken, this.otp);
          const user = await this.userApi().getCurrent();
          this.setCurrentUser(user);
          // redirect might be set previously in router.beforeEach guard
          const redirect = this.$router.currentRoute.query.redirect;
          const location = redirect ? { path: redirect } : { name: 'home' };
          this.initStore();
          this.$router.push(location);
        } catch (err) {
          console.error(err);
          this.error = true;
        }
      }
    },
  },
};
</script>

<style scoped>
.fixed-width {
  width: 25rem;
  min-width: 20rem;
}

.low-button {
  width: 48%;
}
</style>
