<template>
  <div v-if="table">
    <b-breadcrumb
      :items="[
        { text: 'Repositories', to: { name: 'repo-list' } },
        { text: repositoryId, to: { name: 'repo-edit' } },
        { text: 'Tables', to: { name: 'tables' } },
        { text: tableReferenceId, active: true },
      ]"
    />

    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col text-left">
            <h2>Table</h2>
          </div>
          <div class="col w-50 text-right">
            <b-button @click.prevent="editDetails" variant="outline-primary" size="sm">
              Edit
            </b-button>
          </div>
        </div>

        <table-details :table="table" readonly />
      </div>
    </div>

    <b-modal
      ref="modalDetails"
      title="Table"
      @ok="saveDetails"
      @cancel="resetDetails"
      no-fade
      static
    >
      <table-details ref="tableEdit" :table="table" />
    </b-modal>

    <div class="card my-4" v-if="isFieldsAvailable">
      <div class="card-body">
        <div class="row">
          <div class="col text-left">
            <h2>Fields</h2>
          </div>
        </div>

        <field-list v-model="table.fields" :readonly="true" />
      </div>
    </div>

    <div class="my-4" v-if="isFieldsAvailable">
      <index-list
        :indexes="table.indexes"
        :field_list="table.fields"
        :new_index_enabled="newIndexEnabled"
        @add="indexAdd"
        @delete="indexDelete"
      >
      </index-list>
    </div>

    <table-up
      v-show="!isFileUploaded && localTasks.length === 0"
      :repository-id="repositoryId"
      :table-reference-id="tableReferenceId"
      @start="onLocalTaskStart"
      @finish="onLocalTaskFinish"
      @error="onLocalTaskError"
      @progress="onLocalTaskProgress"
    >
    </table-up>

    <div class="card my-4 task" v-if="activeTasks.length > 0">
      <div v-for="(task, index) in activeTasks" :key="index">
        <progress-bar :model="task" />
      </div>
    </div>

    <div class="row my-4">
      <div class="col">
        <button
          class="btn btn-primary"
          @click.prevent="$router.push({ name: 'tables', params: { repositoryId: repositoryId } })"
        >
          Back
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { default as ApiMixin } from '../../mixins/ApiMixin.vue';
import { mapGetters } from 'vuex';
import IndexList from './IndexList.vue';
import TableDetails from './TableDetails.vue';
import ProgressBar from './ProgressBar.vue';
import { Tasks } from './tasks.js';
import TableUp from './TableUp.vue';
import { LocalTask } from './tasks';
import FieldList from './FieldList.vue';
import { BBreadcrumb } from 'bootstrap-vue';

async function sleep(timeout) {
  await new Promise((resolve) => {
    setTimeout(resolve, timeout);
  });
}

export default {
  name: 'Table',
  mixins: [ApiMixin],
  props: {
    repositoryId: {
      required: true,
    },
    tableReferenceId: {
      required: true,
    },
  },
  components: {
    FieldList,
    IndexList,
    TableDetails,
    ProgressBar,
    TableUp,
    BBreadcrumb,
  },
  data() {
    return {
      table: null,
      tasks: [],
      expectedCompletedTasks: 0,
      localTasks: [],
      runPolling: false,
    };
  },
  computed: {
    ...mapGetters(['currentUser']),
    activeTasks() {
      const tasks = [];
      tasks.push(...Tasks.getActive(this.localTasks));
      tasks.push(...Tasks.getActive(this.tasks));
      return tasks;
    },
    allTasksCompleted() {
      const localDone =
        Tasks.filterByStates(this.localTasks, Tasks.STATE_DONE).length === this.localTasks.length;
      const remoteDone = this.isTasksCompleted;
      console.debug('allTasksCompleted: ', localDone, remoteDone);
      return localDone && remoteDone;
    },
    isTasksCompleted() {
      return (
        Tasks.filterByStates(this.tasks, Tasks.STATE_DONE).length === this.expectedCompletedTasks
      );
    },
    isFileUploaded() {
      return this.isFieldsAvailable;
    },
    isFieldsAvailable() {
      return this.table && this.table.fields && this.table.fields.length;
    },
    newIndexEnabled() {
      return this.allTasksCompleted;
    },
  },
  mounted() {
    this.load();
  },
  beforeUnmount() {
    this.stopTaskPolling();
  },
  methods: {
    async load() {
      this.table = await this.dataTableApi().getTable(this.repositoryId, this.tableReferenceId);
      this.tasks = await this.getTasks();

      this.expectedCompletedTasks = this.tasks.length;
      if (!this.allTasksCompleted) {
        this.startTaskPolling();
      }
    },
    async save(model) {
      this.table = await this.dataTableApi().updateTable(
        this.repositoryId,
        this.tableReferenceId,
        model,
      );
    },
    editDetails: function () {
      this.$refs.modalDetails.show();
    },
    saveDetails() {
      const model = this.$refs.tableEdit.getModel();
      this.save(model);
    },
    resetDetails() {
      this.$refs.tableEdit.resetModel();
    },
    async indexAdd(index) {
      if (!this.table.indexes) {
        this.table.indexes = [];
      }
      this.table = await this.dataTableApi().createIndex(
        this.repositoryId,
        this.tableReferenceId,
        index,
      );
      this.expectedCompletedTasks++;
      this.startTaskPolling();
    },
    async indexDelete(idx) {
      await this.dataTableApi().deleteIndex(
        this.repositoryId,
        this.tableReferenceId,
        this.table.indexes[idx].name,
      );
      this.table.indexes.splice(idx, 1);
      this.table = await this.dataTableApi().getTable(this.repositoryId, this.tableReferenceId);
    },
    async onLocalTaskStart(task) {
      this.localTasks.push(new LocalTask(task));
      this.startTaskPolling();
    },
    onLocalTaskFinish(task, table) {
      const index = this.localTasks.findIndex((t) => t.taskType === task);
      this.localTasks.splice(index, 1);
      if (table) {
        this.table = table;
      }
    },
    onLocalTaskError(task) {
      const index = this.localTasks.findIndex((t) => t.taskType === task);
      this.localTasks.splice(index, 1);
    },
    onLocalTaskProgress(task, loaded, total) {
      this.localTasks.find((t) => t.taskType === task).update(loaded, total);
    },
    async getTasks() {
      try {
        const tasks = await this.taskApi().getTasks(this.repositoryId, this.tableReferenceId);
        return Tasks.prepare(tasks);
      } catch (e) {
        console.error('Error retrieving tasks', e);
        return [];
      }
    },
    async startTaskPolling() {
      if (!this.runPolling) {
        if (this.expectedCompletedTasks === 0) {
          this.expectedCompletedTasks = 2;
        }
        this.runPolling = true;
        this.pollState();
      }
    },
    stopTaskPolling() {
      this.runPolling = false;
    },
    async pollState() {
      while (this.runPolling) {
        this.tasks = await this.getTasks();

        if (this.allTasksCompleted) {
          this.runPolling = false;
        }
        if (this.runPolling) {
          await sleep(2000);
        }
      }
      this.table = await this.dataTableApi().getTable(this.repositoryId, this.tableReferenceId);
    },
  },
};
</script>
