<template>
  <div>
    <div v-if="!!project" class="col card-style m-0">
      <div class="p-4 col justify-content-center align-items-center h-75">
        <div :class="`status ${project.isActive ? '' : 'disabled'}`">
          ▪ {{ project.isActive ? 'Active' : 'Inactive' }}
        </div>
        <div class="text-center" style="padding-top: 24px"><strong>Name:</strong></div>
        <div class="text-center">{{ project.name }}</div>
        <div>
          <img src="../../../../images/newproj.png" alt="" />
        </div>
      </div>
      <hr />
      <div class="p-2 row justify-content-center">
        <router-link :to="{ name: 'blueprints', params: { id: project.id } }" v-if="project">
          <button name="edit-project" id="edit-project" class="button">
            <span>Edit project</span>
          </button>
        </router-link>
      </div>
    </div>
    <div v-else class="col card-style m-0">
      <div class="p-4 row justify-content-center h-75">
        <div class="p-4">
          <img src="../../../../images/newproj.png" alt="" />
        </div>
        <h3><strong>Create a New Project</strong></h3>
        <p>Build a new app, plugin or integration by starting a project</p>
      </div>
      <hr />
      <div class="p-2 row justify-content-center">
        <button name="add-new-project" id="add-new-project" @click.prevent="createNewProject">
          <span>Add new project</span>
        </button>
        <span class="padded">or</span>
        <button name="import-project" id="import-project" @click.prevent="selectProjectFile">
          <span>Import project</span>
        </button>
        <input
          id="import-project-input"
          type="file"
          ref="projectImport"
          accept="application/json"
          @change="importProject"
          hidden
        />
      </div>
    </div>
  </div>
</template>

<script>
import { default as ApiMixin } from '../../mixins/ApiMixin.vue';

export default {
  name: 'ProjectCard',
  mixins: [ApiMixin],
  props: {
    project: {
      type: Object,
      required: false,
    },
  },
  emits: ['createProject', 'newProjectImported'],
  methods: {
    createNewProject: function () {
      this.$emit('createProject');
    },
    selectProjectFile: function () {
      this.$refs.projectImport.click();
    },
    importProject: async function () {
      try {
        const fileContent = await this.readProjectFile(this.$refs.projectImport.files[0]);
        const { projectId } = await this.projectsApi().importProject(JSON.parse(fileContent));
        const project = await this.projectsApi().getProject(projectId);
        this.$emit('newProjectImported', project);
      } catch (e) {
        let message = 'Failed to import project.';
        message += e.message ? ` Error: ${e.message}.` : '';
        message += e?.response?.data?.error
          ? ` Additional information: ${e.response.data.error}.`
          : '';
        this.setFailure(null, message);
        throw e;
      } finally {
        this.clearImportState();
      }
    },
    clearImportState: function () {
      this.$refs.projectImport.value = null;
    },
    readProjectFile: function (file) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = function () {
          resolve(reader.result);
        };
        reader.readAsText(file);
      });
    },
  },
};
</script>

<style>
.status {
  display: flex;
  position: absolute;
  top: 16px;
  right: 24px;
  color: #7ed321;
}

.status disabled {
  color: #999999;
}

.card-style {
  margin-top: 16px;
  height: 50vh;
  min-height: 320px;
  background-color: white;
  border-radius: 4px;
  border: 2px solid #e9e9e9;
}

button {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border-width: 0;
  min-width: 64px;
  background-color: #354e68;
  color: #ffffff;
}

span.padded {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
</style>
