<template>
  <div>
    <div v-show="user" style="width: 50%">
      <div>
        <b-card-group deck class="mb-3">
          <b-card header="This is a dashboard" class="text-center">
            <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </b-card>
        </b-card-group>

        <b-card-group deck class="mb-3">
          <b-card header="Panel here" class="text-center">
            <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </b-card>
          <b-card header="Panel there" class="text-center">
            <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </b-card>
        </b-card-group>
      </div>
    </div>

    <div v-show="!user" style="width: 100%">
      <b-card-group deck class="mb-3">
        <b-card title="Safe-digit: Safe home for&nbsp<b>your</b>&nbspdata." class="text-left">
          <h5 class="card-title"></h5>
          <ul>
            <li>
              Monetize your data without compromising security and compliance
              <a href="#">learn more</a>
            </li>
            <li>Create customizable API in 5 minutes! No coding skills required</li>
            <li>Manage and control the access to your data</li>
            <li>Run algorithms and extract insights from your data</li>
          </ul>
        </b-card>
      </b-card-group>
      <b-card-group deck class="mb-3">
        <b-card>
          <p class="card-text">Create customizable API in 5 minutes!</p>
          <p class="card-text">No coding skills required!</p>
          <a href="#" class="card-link">See how</a>
        </b-card>

        <b-card>
          <p>Manage and control the access to your data</p>
          <a href="#">See how</a>
        </b-card>

        <b-card>
          <p>Run algorithms and extract insights from your data</p>
          <p>Comming soon</p>
        </b-card>
      </b-card-group>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { default as ApiMixin } from '../mixins/ApiMixin.vue';

export default {
  name: 'dashboard',
  mixins: [ApiMixin],
  computed: mapGetters(['user']),
  data() {
    return {};
  },
};
</script>
