/**
 * Subscription statuses utility class
 */
export class SubscriptionStatus {
  static NONE = 'NONE';
  static PENDING = 'PENDING';
  static APPROVED = 'APPROVED';
  static DENIED = 'DENIED';
  static REVOKED = 'REVOKED';
  static CANCELLED = 'CANCELLED';

  /**
   * Check if subscription status can be set to another one
   */
  static canBeSet(statusFrom, ownerAccountId, statusTo, accountId) {
    statusFrom = statusFrom ? statusFrom : SubscriptionStatus.NONE;

    switch (statusFrom) {
      case SubscriptionStatus.NONE:
        return statusTo === SubscriptionStatus.PENDING;
      case SubscriptionStatus.PENDING:
        return ownerAccountId === accountId
          ? statusTo === SubscriptionStatus.APPROVED || statusTo === SubscriptionStatus.DENIED
          : statusTo === SubscriptionStatus.CANCELLED;
      case SubscriptionStatus.APPROVED:
        return ownerAccountId === accountId
          ? statusTo === SubscriptionStatus.REVOKED
          : statusTo === SubscriptionStatus.CANCELLED;
      case SubscriptionStatus.DENIED:
        return ownerAccountId === accountId
          ? statusTo === SubscriptionStatus.APPROVED
          : statusTo === SubscriptionStatus.PENDING;
      case SubscriptionStatus.REVOKED:
        return ownerAccountId === accountId
          ? statusTo === SubscriptionStatus.APPROVED
          : statusTo === SubscriptionStatus.PENDING;
      case SubscriptionStatus.CANCELLED:
        return ownerAccountId === accountId ? false : statusTo === SubscriptionStatus.PENDING;
      default:
        throw new Error('Unexpected status ' + statusFrom);
    }
  }

  /**
   * Check if user should have a refresh token available
   */
  static hasRefreshToken(subscription) {
    return subscription && subscription.refreshTokenAvailable;
  }

  /**
   * Check if subscription is in a given status
   */
  static isStatus(subscription, status) {
    return subscription && subscription.status === status;
  }

  /**
   * Check if subscription API owner is a given account
   */
  static isOwner(subscription, accountId) {
    return subscription && subscription.owner && subscription.owner.id === accountId;
  }
}
