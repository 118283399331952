import * as components from '.';

export default [
  {
    path: '/catalog',
    component: components.Catalog,
    name: 'catalog',
    props: true,
  },
  {
    path: '/catalog/apis/:apiId',
    component: components.ApiSubscriberInfo,
    name: 'api-info',
    props: true,
  },
  {
    path: '/catalog/repos/:repositoryId',
    component: components.RepositorySubscriberInfo,
    name: 'repository-info',
    props: true,
  },
];
