import axios from 'axios';
import { Auth } from '../auth/auth';
import _ from 'lodash';

export class ApiAjax {
  static URL_REFRESH_TOKEN = 'auth/token';

  constructor(base_url) {
    this._base_url = base_url;
  }

  _instance() {
    if (!this._ajaxInstance) {
      this._ajaxInstance = axios.create(this._defaultConfig());
    }
    return this._ajaxInstance;
  }

  async requestAsync(config, authenticate) {
    if (!authenticate) {
      return await this._request(config);
    } else {
      try {
        const token = await ApiAjax._getToken(Auth.TOKEN_KEY);
        return await this._request(config, token);
      } catch (error) {
        const status = _.get(error, ['response', 'status']);
        if (status === 401 || status === 403) {
          const token = await this._refresh();
          return await this._request(config, token);
        } else {
          throw error;
        }
      }
    }
  }

  async _request(config, token) {
    const requestConfig = ApiAjax._addHeaders(config, token);
    try {
      const response = await this._instance().request(requestConfig);
      return response.data;
    } catch (error) {
      const status = _.get(error, ['response', 'status']);
      if (!status) {
        _.set(error, ['response', 'status'], 500);
      }
      throw error;
    }
  }

  _refresh() {
    return ApiAjax._getToken(Auth.REFRESH_TOKEN_KEY).then((refreshToken) => {
      console.log('Refreshing auth token...');
      const config = ApiAjax._addHeaders(
        { method: 'GET', url: ApiAjax.URL_REFRESH_TOKEN },
        refreshToken,
      );
      return this._instance()
        .request(config)
        .then((response) => {
          const token = response.data.token;
          Auth.setToken(Auth.TOKEN_KEY, token);
          return token;
        });
    });
  }

  _defaultConfig() {
    return {
      baseURL: this._base_url,
      headers: { 'X-Requested-With': 'XMLHttpRequest' },
    };
  }

  static _addHeaders(config, token) {
    const headers = {};
    if (token) {
      headers['Authorization'] = 'Bearer ' + token;
    }
    if (typeof config.data === 'string') {
      headers['Content-Type'] = 'text/plain';
    }
    return _.merge(config, { headers });
  }

  static _getToken(key) {
    const token = Auth.getToken(key);
    if (!token) {
      return Promise.reject(ApiAjax.errorResponse(401, "Token '" + key + "' is missing"));
    }
    if (Auth.isTokenExpired(token)) {
      Auth.setToken(key, null);
      return Promise.reject(ApiAjax.errorResponse(401, "Token '" + key + "' expired"));
    }
    return Promise.resolve(token);
  }

  static errorResponse(status, message) {
    return {
      response: { status, data: message },
    };
  }
}
