<template>
  <div>
    <modal-dlg
      id="modal"
      ref="modal"
      :title="`Edit ${connectorType} connector`"
      :submit="updateBlueprintConnector"
      :validator="$validator"
      @shown="onShow"
      @cancel="clearState"
      size="lg"
      no-fade
      lazy
      ok-title="Save"
    >
      <div style="min-height: 50vh" class="font__color">
        <div class="form-group col pb-2">
          <div class="row">
            <label>Choose {{ connectorType }} connector</label>
          </div>
          <div class="row">
            <select key="selectAuthType" class="w-100 form-control" v-model="connectorId">
              <option
                v-for="(connector, index) in filteredConnectors"
                :value="connector.id"
                :key="index"
              >
                {{ connector.name }}
              </option>
            </select>
          </div>
        </div>
        <b-tabs v-if="!!selectedConnector" content-class="mt-3">
          <b-tab title="Form" active>
            <connector-input
              v-for="key in Object.keys(selectedConnector)"
              :fields="selectedConnector[key]"
              :key-path="connectorType.toLowerCase() + 'Configuration.' + key"
              :key="selectedConnector[key].name"
            />
          </b-tab>
          <b-tab title="JSON">
            <CodeEditor v-model="blueprint[connectorType + 'Configuration']" :height="300" />
          </b-tab>
        </b-tabs>
      </div>
    </modal-dlg>
  </div>
</template>

<script>
import { BTab, BTabs } from 'bootstrap-vue';
import { mapGetters, mapMutations } from 'vuex';
import ApiMixin from '../../mixins/ApiMixin.vue';
import ModalDlg from '../../components/ModalDlg.vue';
import ConnectorInput from './ConnectorInput';
import CodeEditor from '../../components/CodeEditor.vue';

export default {
  name: 'EditConnector',
  mixins: [ApiMixin],
  components: {
    BTab,
    BTabs,
    ModalDlg,
    ConnectorInput,
    CodeEditor,
  },
  data() {
    return {
      connectorId: '',
      connectorType: '',
      selectedConnector: {},
    };
  },
  computed: {
    ...mapGetters(['connectors', 'blueprintBackup', 'blueprint']),
    filteredConnectors() {
      return this.connectors.filter(
        (connector) =>
          connector.type.toLowerCase() === this.connectorType.toLowerCase() ||
          (this.connectorType === 'destination' && connector.type === 'TARGET'),
      );
    },
  },
  watch: {
    connectorId(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (
          this.blueprintBackup[this.connectorType.toLowerCase() + 'ConnectorId'] !==
          this.connectorId
        ) {
          this.setBlueprint({
            ...this.blueprint,
            [this.connectorType.toLowerCase() + 'Configuration']: {},
            [this.connectorType.toLowerCase() + 'ConnectorId']: this.connectorId,
          });
        } else {
          this.revertBlueprint();
        }
        this.loadConnector(newVal);
      }
    },
  },
  methods: {
    ...mapMutations(['setBlueprint', 'revertBlueprint']),
    onShow: function () {
      this.connectorId = this.blueprint[this.connectorType + 'ConnectorId'];
      this.loadConnector(this.connectorId);
    },
    loadConnector: async function (id) {
      this.selectedConnector = await this.integrationApi().getConnectorTemplate(id);
    },
    show: function (connectorType) {
      this.connectorType = connectorType;
      this.$refs.modal.show();
    },
    updateBlueprintConnector: function () {
      this.$emit('updateConnector');
      this.$refs.modal.hide();
    },
    clearState: function () {
      this.connectorId = this.blueprintBackup[this.connectorType.toLowerCase() + 'ConnectorId'];
      this.connectorType = '';
      this.selectedConnector = '';
      this.revertBlueprint();
    },
  },
};
</script>
