export default class FileParser {
  constructor(file, from = 0, to) {
    this._file = file;
    this._from = from;
    this._to = to;
  }

  _readFile() {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => resolve(event.target.result);
      reader.onerror = (error) => reject(error);
      const blob = this._file.slice(this._from, this._to);
      reader.readAsText(blob);
    });
  }

  firstLine() {
    return this._readFile().then((data) => FileParser._getFirstLine(data));
  }

  static _getFirstLine(data) {
    const it = FileParser.lineIterator(data);
    for (let next = it.next(); !next.done; next = it.next()) {
      const line = next.value;
      if (!line.startsWith('#')) {
        return line;
      }
    }
    return null;
  }

  static *lineIterator(text) {
    const lines = text.split(/\r\n|\r|\n/g);
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];
      if (line && line.length) {
        yield line;
      }
    }
  }
}
