<template>
  <div class="p-4">
    <div class="pb-4">
      <div class="d-flex justify-content-between">
        <div>
          <span slot="prepend" class="search"></span>
          <input type="text" v-model="filter" placeholder="Find" class="search" />
        </div>
        <div>
          <b-button @click="refresh">
            <i class="bi bi-arrow-repeat" />
          </b-button>
        </div>
      </div>
    </div>
    <b-table
      hover
      bordered
      :items="logs || []"
      :fields="COLUMNS"
      :filter="filter"
      sort-by="runTimestamp"
      sort-desc
      thead-class="table-header"
      tbody-class="table-body"
    >
      <template #cell(status)="data">
        <div class="text-center">
          <b-icon-check-circle-fill v-if="data.item.status === 'SUCCESS'" variant="success" />
          <b-icon-x-circle-fill v-else-if="data.item.status === 'FAILURE'" variant="danger" />
          <b-icon-file-earmark-check-fill v-else-if="data.item.status === 'DATA'" variant="info" />
        </div>
      </template>
      <template #cell(actions)="data">
        <b-dropdown
          class="button_style"
          id="project-actions-dropdown"
          variant="outline-primary"
          dropup
          right
          no-caret
          toggle-class="dropdown__class"
        >
          <template #button-content>
            <div>
              <i class="bi bi-three-dots" />
            </div>
          </template>
          <b-dropdown-item id="audit-data" @click="getData(data.item.runId, data.item.id)">
            Get&nbsp;Data
          </b-dropdown-item>
          <b-dropdown-item
            v-if="data.item.status === 'DATA' && !!data.item.sourceTransformId"
            id="source-transform"
            @click="
              $router.push({
                name: 'editTransform',
                params: {
                  transformId: data.item.sourceTransformId,
                  blueprintId: data.item.blueprintId,
                  type: 'source',
                },
                query: {
                  runId: data.item.runId,
                },
              })
            "
          >
            Test Source Transform
          </b-dropdown-item>
          <b-dropdown-item
            v-if="data.item.status === 'DATA' && !!data.item.destinationTransformId"
            id="destination-transform"
            @click="
              () =>
                $router.push({
                  name: 'editTransform',
                  params: {
                    transformId: data.item.destinationTransformId,
                    blueprintId: data.item.blueprintId,
                    type: 'destination',
                  },
                  query: {
                    runId: data.item.runId,
                  },
                })
            "
          >
            Test Destination Transform
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
    <div ref="scrollTargetRef">
      <span v-if="!isLastPage">Loading...</span>
      <span v-else-if="!logs.length">There are no logs yet</span>
      <span v-else>All logs loaded</span>
    </div>
    <b-modal
      ref="modalDetails"
      title="Logs Details"
      @ok="closeModal"
      ok-only
      static
      scrollable
      size="lg"
    >
      <CodeEditor v-if="jsonData" :model-value="jsonData" readonly />
    </b-modal>
  </div>
</template>

<script>
import { BIconXCircleFill, BIconCheckCircleFill, BIconFileEarmarkCheckFill } from 'bootstrap-vue';
import { default as ApiMixin } from '../mixins/ApiMixin';
import { mapActions, mapGetters } from 'vuex';
import { formatInstant } from '../dates';
import CodeEditor from '../components/CodeEditor.vue';

const COLUMNS = [
  {
    key: 'runId',
    label: 'Run ID',
  },
  {
    key: 'status',
    label: 'State',
  },
  {
    key: 'runTimestamp',
    label: 'Date Created',
    formatter: (value) => formatInstant(value),
  },
  {
    key: 'blueprintId',
    label: 'Tenant Id',
  },
  {
    key: 'blueprintName',
    label: 'Name',
  },
  {
    key: 'actions',
    label: 'Actions',
  },
];

const ROWS_PER_PAGE = 20;

export default {
  name: 'Logs',
  mixins: [ApiMixin],
  components: {
    BIconXCircleFill,
    BIconCheckCircleFill,
    BIconFileEarmarkCheckFill,
    CodeEditor,
  },
  props: {
    projectId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      COLUMNS: COLUMNS,
      filter: null,
      jsonData: null,
      isLastPage: false,
      offset: 0,
    };
  },
  computed: {
    ...mapGetters(['logs']),
  },
  mounted() {
    this.intersectionObserver = new IntersectionObserver(async (entries) => {
      if (this.isLastPage || entries[0].intersectionRatio <= 0) return;
      await this.loadLogs({
        projectId: this.projectId,
        limit: ROWS_PER_PAGE,
        offset: this.offset,
      });
      this.offset += ROWS_PER_PAGE;
      this.isLastPage = !this.logs || this.logs.length < this.offset;
    });
    this.intersectionObserver.observe(this.$refs.scrollTargetRef);
  },
  methods: {
    ...mapActions(['loadLogs', 'refreshLogs', 'loadProjectLogData']),
    async getData(runId, id) {
      this.jsonData = await this.loadProjectLogData({ projectId: this.projectId, runId, id });
      this.$refs.modalDetails.show();
    },
    refresh() {
      this.offset = 0;
      this.refreshLogs({
        projectId: this.projectId,
        limit: ROWS_PER_PAGE,
        offset: this.offset,
      });
    },
    closeModal() {
      this.$refs.modalDetails.hide();
      this.jsonData = null;
    },
  },
  beforeUnmount() {
    this.intersectionObserver.disconnect();
  },
};
</script>
<style scoped>
pre {
  color: #212529;
  font-style: italic;
}

.table-header {
  background-color: #999;
  background-image: linear-gradient(#eee, #e0e0e0);
  /*color: #444;*/
}

span.search {
  padding-right: 0.9em;
  background: url('../../../images/search.svg') no-repeat center;
  z-index: 10;
  position: relative;
  left: 20px;
}

input.search {
  padding-left: 25px;
}

.table-header th {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.table-body td {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
</style>
