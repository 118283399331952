<template>
  <div>
    <modal-dlg
      id="modal"
      ref="modal"
      title="New Key"
      :ok-disabled="!this.selectedBlueprints.length && !this.newKeyName"
      :submit="createKey"
      :validator="$validator"
      @hidden="clearState"
      @shown="() => this.$refs.newKeyName.focus()"
      @cancel="clearState"
      no-fade
      lazy
    >
      <div
        v-show="!selectedBlueprints.length"
        class="message alert alert-info position-relative"
        role="alert"
      >
        At least one blueprint should be selected
      </div>
      <div class="form-group col pb-2">
        <label class="col pb-1">Name</label>
        <div class="row">
          <input
            ref="newKeyName"
            v-validate="'required'"
            :class="[{ 'is-invalid': errors.has('newKeyName') }, 'form-control']"
            name="newKeyName"
            v-model="newKeyName"
            data-vv-as="Name"
            type="text"
            :placeholder="'New Key Name'"
          />
          <div class="invalid-feedback" v-show="errors.has('newKeyName')">
            {{ errors.first('newKeyName') }}
          </div>
        </div>
      </div>
      <div class="form-group col pb-2" v-if="blueprints">
        <label class="col pb-1">Blueprints</label>
        <div class="row">
          <MultiSelect
            open-direction="bottom"
            v-model="selectedBlueprints"
            :options="blueprints"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Pick some"
            label="name"
            track-by="name"
            :preselect-first="false"
          >
            <template v-slot:selection="{ values, isOpen }">
              <span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">
                {{ values.length }} blueprints selected
              </span>
            </template>
          </MultiSelect>
        </div>
      </div>
    </modal-dlg>
  </div>
</template>

<script>
import { default as ApiMixin } from '../../../mixins/ApiMixin.vue';
import { default as ModalDlg } from '../../../components/ModalDlg.vue';
import MultiSelect from 'vue-multiselect';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'NewKeyDialog',
  mixins: [ApiMixin],
  components: {
    MultiSelect,
    ModalDlg,
  },
  props: {
    projectId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      newKeyName: '',
      selectedBlueprints: [],
    };
  },
  computed: {
    ...mapGetters(['blueprints']),
  },
  async mounted() {
    if (!this.blueprints.length) {
      await this.loadBlueprints(this.projectId);
    }
  },
  methods: {
    ...mapActions(['loadBlueprints']),
    clearState: function () {
      this.newKey = '';
      this.newKeyName = '';
      this.selectedBlueprints = [];
    },
    show: function () {
      this.$refs.modal.show();
    },
    createKey: async function () {
      try {
        const valid = (await this.$validator.validate()) && !!this.selectedBlueprints;
        if (valid) {
          const keyForm = {
            name: this.newKeyName,
            blueprints: this.selectedBlueprints.map((blueprint) => blueprint.id),
            projectId: this.projectId,
          };
          const newKey = await this.keysApi().createAccessKey(keyForm);
          this.$emit('newKeyCreated', newKey);
        }
        this.clearState();
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
<style>
@import 'https://unpkg.com/vue-multiselect@2.1.0/dist/vue-multiselect.min.css';
</style>
