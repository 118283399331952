<template>
  <div class="col">
    <div class="row">
      <div class="col"><h4>Catalog</h4></div>
      <div class="col text-right">
        <div class="col text-right">
          <input type="text" placeholder="search" class="w-100" v-model="filter" />
        </div>
      </div>
    </div>

    <b-tabs content-class="mt-3">
      <b-tab title="Source" active>
        <div class="card my-4" v-for="api in filteredApis" :key="api.referenceId">
          <catalog-api-entry :api="api" />
        </div>
      </b-tab>
      <b-tab title="References">
        <div
          class="card my-4"
          v-for="repository in filteredRepositories"
          :key="repository.referenceId"
        >
          <catalog-repository-entry :repository="repository" />
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BTab, BTabs } from 'bootstrap-vue';
import { mapGetters } from 'vuex';
import { default as ApiMixin } from '../mixins/ApiMixin.vue';
import CatalogApiEntry from './CatalogApiEntry.vue';
import CatalogRepositoryEntry from './CatalogRepositoryEntry.vue';

export default {
  name: 'Catalog',
  mixins: [ApiMixin],
  data() {
    return {
      apis: [],
      repositories: [],
      filter: '',
    };
  },
  computed: {
    ...mapGetters(['currentUser']),
    filteredApis() {
      return !this.filter
        ? this.apis
        : this.apis.filter((api) => api.name.match(new RegExp(this.filter, 'i')));
    },
    filteredRepositories() {
      return !this.filter
        ? this.repositories
        : this.repositories.filter((repository) =>
            repository.name.match(new RegExp(this.filter, 'i')),
          );
    },
  },
  components: {
    BTab,
    BTabs,
    CatalogApiEntry,
    CatalogRepositoryEntry,
  },
  mounted() {
    this.load();
  },
  methods: {
    load: async function () {
      this.apis = await this.catalogApi().getApis();
      this.repositories = await this.catalogApi().getRepositories();
    },
  },
};
</script>
