<template>
  <div class="form-group col pb-2">
    <label class="row pb-1">{{ label }}</label>
    <div class="row">
      <input
        ref="httpPathInput"
        :class="[{ 'is-invalid': errors.has(name) }, 'form-control']"
        @input="$emit('changeValue', $event.target)"
        :value="item"
        v-validate="'required'"
        :data-vv-as="label"
        :name="name"
        type="text"
      />
      <div class="invalid-feedback" v-show="errors.has(name)">
        {{ errors.first(name) }}
      </div>
    </div>
  </div>
</template>

<script>
import { default as ApiMixin } from '../../mixins/ApiMixin.vue';

export default {
  name: 'ModalInput',
  mixins: [ApiMixin],
  props: {
    label: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    item: {
      type: String,
      required: true,
    },
  },
};
</script>
