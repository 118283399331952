export class SubscriptionMethod {
  constructor(title, value, description) {
    this._title = title;
    this._value = value;
    this._description = description;
  }

  get title() {
    return this._title;
  }

  get value() {
    return this._value;
  }

  get description() {
    return this._description;
  }
  // API
  static SEARCH = new SubscriptionMethod(
    'Search',
    'SEARCH',
    'Search and retrieve records from the database',
  );
  static MATCH = new SubscriptionMethod(
    'Match',
    'MATCH',
    'Search for matching records. No data will be retrieved',
  );

  // Repository
  static CLONE = new SubscriptionMethod('Clone', 'CLONE', 'Ability to clone reference repository');

  static allApiMethods() {
    return [SubscriptionMethod.SEARCH, SubscriptionMethod.MATCH];
  }

  static allRepositoryMethods() {
    return [SubscriptionMethod.CLONE];
  }
}
