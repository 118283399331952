<template>
  <div>
    <modal-dlg
      id="modal"
      ref="modal"
      title="New Project"
      :ok-disabled="!this.selectedPlatform || !newProjectName"
      :submit="createNewProject"
      :validator="$validator"
      @hidden="clearState"
      @shown="() => this.$refs.projectNameInput.focus()"
      @cancel="clearState"
      size="lg"
      no-fade
      lazy
    >
      <div style="min-height: 50vh">
        <div
          v-show="!selectedPlatform"
          class="message alert alert-info position-relative"
          role="alert"
        >
          Platform is required
        </div>

        <div class="form-group col">
          <label class="row">Project Name</label>
          <div class="row">
            <input
              ref="projectNameInput"
              :class="[{ 'is-invalid': errors.has('projectName') }, 'form-control']"
              v-model="newProjectName"
              v-validate="'required'"
              data-vv-as="Name"
              name="projectName"
              type="text"
            />
            <div class="invalid-feedback" v-show="errors.has('projectName')">
              {{ errors.first('projectName') }}
            </div>
          </div>
        </div>

        <div class="form-group col">
          <div class="row">
            <label>Platforms</label>
            <div class="pl-2">
              <img src="../../../../images/info.png" alt="" width="24px" />
            </div>
          </div>
          <div class="row">
            <select key="selectProject" v-model="selectedPlatform" class="w-100 form-control">
              <option disabled>Select platform</option>
              <option v-for="(platform, index) in platforms" :key="index">
                {{ platform.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </modal-dlg>
  </div>
</template>
<script>
import { default as ApiMixin } from '../../mixins/ApiMixin.vue';
import { default as ModalDlg } from '../../components/ModalDlg.vue';

export default {
  name: 'NewProject',
  mixins: [ApiMixin],
  components: {
    ModalDlg,
  },
  data() {
    return {
      newProjectName: '',
      platforms: [],
      selectedPlatform: null,
      newProjectApiMethods: [],
      validationError: false,
    };
  },
  async mounted() {
    this.platforms = [
      {
        name: 'NONE',
      },
    ];
  },
  methods: {
    clearState() {
      this.newProjectName = '';
      this.selectedPlatform = null;
    },
    show() {
      this.$refs.modal.show();
    },
    async createNewProject() {
      try {
        const valid = (await this.$validator.validate()) && !!this.selectedPlatform;

        if (valid) {
          const projectForm = {
            name: this.newProjectName,
            platform: this.selectedPlatform,
          };
          const newProject = await this.projectsApi().createProject(projectForm);
          this.$emit('newProjectCreated', newProject);
        }
        this.clearState();
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
