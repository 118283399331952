class Callbacks {
  constructor() {}

  /**
   * @abstract
   */
  onPending() {
    throw new Error('Abstract method call');
  }

  /**
   * @abstract
   */
  onSuccess() {
    throw new Error('Abstract method call');
  }

  /**
   * @abstract
   */
  onFailure() {
    throw new Error('Abstract method call');
  }
}

export default Callbacks;
