<template>
  <div :id="'repo-card-' + repository.name" class="card-body">
    <div class="card-title row py-1 align-items-center">
      <div class="col-auto text-left mr-3">
        <img class="repository-icon" :src="require('../../../../images/repository.png')" />
      </div>
      <div class="col text-left">
        <a
          :id="'repo-link-' + repository.name"
          class="card-link"
          href="#"
          @click.prevent="editRepository(repository)"
          >{{ repository.name }}</a
        >
      </div>
      <toggle-button
        v-if="repository.type === 'Reference'"
        @change="togglePublishState(repository.referenceId)"
        :value="repository.published"
        :labels="{ checked: 'Public', unchecked: 'Private' }"
        :sync="true"
        :height="30"
        :width="80"
        :color="{ checked: '#007bff', unchecked: '#6c757d', disabled: '#CCCCCC' }"
      />

      <div class="col-auto text-right">
        <b-dropdown :id="'actions-dd-' + repository.name" variant="link" text="Actions">
          <b-dd-item :id="'edit-' + repository.name" @click="editRepository(repository)">
            Edit
          </b-dd-item>
          <b-dd-item :id="'delete-' + repository.name" @click="deleteRepository(repository)">
            Delete
          </b-dd-item>
        </b-dropdown>
      </div>
    </div>

    <stat-card
      text="Tables"
      :value="repository.tableCount"
      :route="{ name: 'tables', params: { repositoryId: repository.referenceId } }"
    >
    </stat-card>

    <stat-card
      text="APIs"
      :value="repository.apiCount"
      :route="{ name: 'apis', params: { repositoryId: repository.referenceId } }"
    >
    </stat-card>

    <stat-card
      text="Subscriptions"
      :value="repository.subscriptionCount"
      :route="{
        name: 'repository-subscriptions',
        params: { repositoryId: repository.referenceId },
      }"
    >
    </stat-card>

    <stat-card
      text="Tokens"
      :value="repository.tokensCount"
      :route="{ name: 'repo-tokens', params: { repositoryId: repository.referenceId } }"
    >
    </stat-card>
  </div>
</template>

<script>
import { default as ApiMixin } from '../../mixins/ApiMixin.vue';
import StatCard from '../../components/StatCard.vue';

export default {
  name: 'RepoListRow',
  mixins: [ApiMixin],
  components: {
    StatCard,
  },
  props: {
    repository: {
      type: Object,
      required: true,
    },
    editRepository: {
      type: Function,
      required: true,
    },
    deleteRepository: {
      type: Function,
      required: true,
    },
  },
  methods: {
    async togglePublishState(referenceId) {
      const newPublished = !this.repository.published;
      this.repository.published = newPublished;
      try {
        await this.dataRepositoryApi().updateRepositoryVisibility(referenceId, newPublished);
      } catch (error) {
        setTimeout(() => (this.repository.published = !newPublished), 500);
      }
    },
  },
};
</script>
