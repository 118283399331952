<template>
  <div v-if="project">
    <div class="row header__body">
      <div class="col-8 pt-5 pl-5">
        <h2 class="row">Project: {{ project.name }}</h2>
      </div>
      <div class="col-4 row d-flex justify-content-end h-25 pt-5 pr-3">
        <b-dropdown
          class="button_style"
          id="project-actions-dropdown"
          variant="outline-primary"
          dropup
          right
          no-caret
          toggle-class="dropdown__class"
        >
          <template #button-content>
            <div>
              <i class="bi bi-three-dots" />
            </div>
          </template>
          <b-dropdown-item id="project-action-export" @click="exportProject(project)"
            >Export project</b-dropdown-item
          >
          <b-dropdown-item id="project-action-rename" @click="$refs.renameProject.show()"
            >Rename project</b-dropdown-item
          >
        </b-dropdown>
      </div>
      <div class="row col-8 mt-auto">
        <router-link
          active-class="border_class"
          :to="{ name: 'blueprints' }"
          class="col-2 text-center"
        >
          Blueprints
        </router-link>
        <router-link
          active-class="border_class"
          :to="{ name: 'projectInstalls' }"
          class="col-2 text-center"
        >
          Installs
        </router-link>
        <router-link active-class="border_class" :to="{ name: 'logs' }" class="col-2 text-center">
          Logs
        </router-link>
        <router-link active-class="border_class" :to="{ name: 'keys' }" class="col-2 text-center">
          Keys
        </router-link>
        <router-link
          active-class="border_class"
          :to="{ name: 'variables' }"
          class="col-2 text-center"
        >
          Variables
        </router-link>
      </div>
    </div>
    <router-view v-if="project.id" :projectId="projectId" />
    <RenameEntityModal
      v-if="Object.keys(project).length"
      ref="renameProject"
      entity="Project"
      @update-name="updateProjectName"
      :current-name="project.name"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { default as ApiMixin } from '../mixins/ApiMixin.vue';
import RenameEntityModal from '../components/RenameEntityModal.vue';

export default {
  name: 'IntegrationProjectPage',
  mixins: [ApiMixin],
  components: {
    RenameEntityModal,
  },
  computed: {
    ...mapGetters(['project']),
  },
  props: {
    projectId: {
      type: String,
      required: true,
    },
  },
  async mounted() {
    if (this.projectId) {
      await this.loadProject(this.projectId);
    }
  },
  methods: {
    ...mapActions(['loadProject']),
    ...mapMutations(['setProject']),
    async updateProjectName(name) {
      const updatedProject = await this.projectsApi().updateProject({
        ...this.project,
        name,
      });
      this.setProject(updatedProject);
    },
    exportProject: async function (project) {
      try {
        const result = await this.projectsApi().exportProject(project.id);
        if (!result?.project?.id) {
          throw new Error('Result does not contain project ID');
        }
        this.downloadAsFile(result, project.name + '.json');
      } catch (e) {
        let message = `Failed to export project '${project.name}'.`;
        message += e.message ? ` Error: ${e.message}.` : '';
        message += e?.response?.data?.error
          ? ` Additional information: ${e?.response?.data?.error}.`
          : '';
        this.setFailure(null, message);
        throw e;
      }
    },
    downloadAsFile: function (data, filename) {
      const text = JSON.stringify(data, null, 2);
      const element = document.createElement('a');
      element.setAttribute(
        'href',
        'data:application/json;charset=utf-8,' + encodeURIComponent(text),
      );
      element.setAttribute('download', filename);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
  },
  unmounted() {
    this.setProject(null);
  },
};
</script>
<style>
.header__body {
  height: 20vh;
  min-height: 144px;
  padding: 0;
  margin: 0;
  background-color: #ffffff;
  border-bottom: #e9e9e9 solid 2px;
}

.button_style {
  color: #8a8a8a;
  min-width: 24px;
  margin-left: 16px;
}
</style>
