import format from 'string-format';
import { Api } from './Api';

class AuditApi extends Api {
  static URL_API_USAGE = 'repositories/{repositoryId}/apis/{apiId}/audit';
  static URL_API_USAGE_SUMMARY = 'repositories/{repositoryId}/apis/{apiId}/audit/summary';
  static URL_AUDIT_LOGS = 'integrations/projects';

  /**
   * Search for an API usage
   */
  getApiUsage(repositoryId, apiId, query) {
    const url = format(AuditApi.URL_API_USAGE, { repositoryId, apiId });
    return super.post(url, query);
  }

  /**
   * Get API usage summary
   */
  getApiUsageSummary(repositoryId, apiId) {
    const url = format(AuditApi.URL_API_USAGE_SUMMARY, { repositoryId, apiId });
    return super.get(url);
  }

  getAuditByBlueprintLogs(projectId, blueprintId, limit, offset) {
    return this.get(
      `${AuditApi.URL_AUDIT_LOGS}/${projectId}/audit/blueprint/${blueprintId}?limit=${limit}&offset=${offset}`,
    );
  }

  getProjectLogData(projectId, runId, id) {
    return this.get(`${AuditApi.URL_AUDIT_LOGS}/${projectId}/audit/data/${runId}?id=${id}`);
  }
}

export default AuditApi;
