import path from 'path';
import format from 'string-format';
import { Api } from './Api';
import DataRepositoryApi from './DataRepositoryApi';

class DataApiApi extends Api {
  static URL_APIS = path.join(DataRepositoryApi.URL_REPOSITORY, 'apis');
  static URL_API = path.join(DataApiApi.URL_APIS, '{apiId}');
  static URL_API_CREATE_GET = 'integrations/blueprints/{blueprintId}/apis';
  static URL_API_PUT_DELETE = 'integrations/apis/{apiId}';

  constructor(callbacks) {
    super(callbacks);
  }

  /**
   * Get list of apis for given repository
   */
  getApis(repositoryId) {
    const url = format(DataApiApi.URL_APIS, { repositoryId });
    return super.get(url);
  }

  getApisByBlueprintId(blueprintId) {
    const url = format(DataApiApi.URL_API_CREATE_GET, { blueprintId });
    return super.get(url);
  }

  /**
   * Get api for given repository and api IDs
   */
  getApi(repositoryId, apiId) {
    const url = format(DataApiApi.URL_API, { repositoryId, apiId });
    return super.get(url);
  }

  /**
   * Create a new api for given repository
   */
  createApi(repositoryId, api) {
    const url = format(DataApiApi.URL_APIS, { repositoryId });
    return super.post(url, api);
  }

  /**
   * Update api for given repository
   */
  updateApi(repositoryId, apiId, api) {
    const url = format(DataApiApi.URL_API, { repositoryId, apiId });
    return super.put(url, api);
  }

  update(api) {
    const url = format(DataApiApi.URL_API_PUT_DELETE, { apiId: api.id });
    return super.put(url, api);
  }

  updateApiVisibility(repositoryId, apiId, isPublic) {
    const url = path.join(format(DataApiApi.URL_API, { repositoryId, apiId }), 'visibility');
    const visibility = { isPublic: isPublic };
    return super.put(url, visibility);
  }

  createNewApi(blueprintId, api) {
    const url = format(DataApiApi.URL_API_CREATE_GET, { blueprintId });
    return this.post(url, api);
  }

  softDeleteApi(apiId) {
    const url = format(DataApiApi.URL_API_PUT_DELETE, { apiId });
    return super.delete(url);
  }

  /**
   * Delete API
   */
  deleteApi(repositoryId, apiId) {
    const url = format(DataApiApi.URL_API, { repositoryId, apiId });
    return super.delete(url);
  }
}

export default DataApiApi;
