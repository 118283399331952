<template>
  <router-link :to="route" v-if="route" class="main-menu__item" append>
    <div class="main-menu__icon-container">
      <img v-if="icon" class="menu-icon" :src="icon" alt="" />
      <i v-if="iconClass" :class="imgClass" />
    </div>
    <span class="main-menu__label">{{ text }}</span>
  </router-link>

  <a href="#" class="main-menu__item" v-else>
    <div class="main-menu__icon-container fa-lg">
      <img v-if="icon" class="menu-icon" :src="icon" alt="" />
      <i v-if="iconClass" :class="imgClass" />
    </div>
    <span class="main-menu__label">{{ text }}</span>
  </a>
</template>

<script>
export default {
  name: 'MainMenuItem',
  computed: {
    imgClass: function () {
      return 'menu-icon fa-lg' + this.iconClass;
    },
  },
  props: {
    icon: {
      type: String,
      required: false,
    },
    iconClass: {
      type: String,
      required: false,
      default: '',
    },
    text: {
      type: String,
      required: false,
    },
    route: {
      required: false,
    },
  },
};
</script>

<style>
.menu-icon {
  width: 24px;
  font-size: 24px;
  color: rgba(27, 31, 72, 0.97);
}

.main-menu__icon-container {
  width: var(--nav-icon-width);
  height: var(--nav-icon-width);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.main-menu__label {
  white-space: nowrap;
}

.main-menu__item {
  display: flex;
  width: inherit;
  margin-top: 0;
  align-items: center;
  height: var(--nav-icon-width);
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}
</style>
