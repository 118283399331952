<template>
  <div class="my-4 mx-auto fixed-width">
    <div class="row justify-content-center">
      <h3>Reset your password</h3>
    </div>

    <div class="card mx-auto">
      <div v-if="!sent" class="card-body">
        <form v-on:submit.prevent="onSend" novalidate>
          <div class="form-group">
            <div class="col-form-label">
              Enter your email address and we will send you a link to reset your password
            </div>
          </div>
          <div class="form-group space-for-feedback">
            <input
              id="email"
              :class="[{ 'is-invalid': errors.has('email') }, 'form-control']"
              name="email"
              type="email"
              v-model="email"
              v-validate="'required'"
              data-vv-as="email"
              placeholder="Enter your email address"
            />
            <div class="invalid-feedback">{{ errors.first('email') }}</div>
          </div>

          <div class="form-group justify-content-center mb-0">
            <b-button type="submit" class="w-100" variant="outline-primary">
              Send password reset email
            </b-button>
          </div>
        </form>
      </div>

      <div v-if="sent" class="card-body">
        <div class="form-group">
          Check your email for a link to reset your password. If it doesn’t appear within a few
          minutes, check your spam folder
        </div>

        <div class="form-group mb-0">
          <b-button to="login" class="w-100" variant="outline-primary">Return to sign in</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { default as ApiMixin } from '../mixins/ApiMixin.vue';

export default {
  name: 'ResetPassword',
  mixins: [ApiMixin],
  data() {
    return {
      email: null,
      sent: false,
    };
  },
  methods: {
    onSend() {
      this.error = false;

      this.$validator.errors.clear();
      this.$validator.validateAll().then((allValid) => {
        if (allValid) {
          this.userApi()
            .resetPassword(this.email)
            .then(() => (this.sent = true));
        }
      });
    },
  },
};
</script>

<style scoped>
.fixed-width {
  width: 25rem;
  min-width: 20rem;
}
.space-for-feedback {
  min-height: 4em;
}
</style>
