import { default as accounts } from './accounts/routes.js';
import { default as users } from './users/routes.js';
import { default as layout } from './layout/routes.js';
import { default as data } from './data/routes.js';
import { default as auth } from './auth/routes.js';
import { default as requests } from './data/subscriptions/routes.js';
import { default as apis } from './data/apis/routes.js';
import { default as catalog } from './catalog/routes.js';
import { default as integrations } from './integrations/routes.js';

export default [
  ...accounts,
  ...users,
  ...layout,
  ...data,
  ...auth,
  ...requests,
  ...apis,
  ...catalog,
  ...integrations,
  { path: '*', redirect: '/' },
];
