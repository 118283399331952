<template>
  <div class="mx-2 my-1 d-flex justify-content-between align-items-center">
    <div v-if="isSelfContained" class="row w-100 align-items-center">
      <div class="col field-name">{{ field.name }}</div>

      <div class="col">
        <span v-if="readonly">{{ getTypeText(field.type) }}</span>
        <b-form-select
          v-else
          v-model="field.type"
          :options="types"
          value-field="name"
          text-field="text"
        >
        </b-form-select>
      </div>

      <div class="col" v-if="field.type">
        <span v-if="readonly">{{ getMatcherText(field.matcher) }}</span>
        <b-form-select
          v-else
          v-model="field.matcher"
          :options="matchers"
          value-field="name"
          text-field="text"
        >
        </b-form-select>
      </div>
    </div>

    <div v-else class="row w-100 align-items-center">
      <div class="col">
        <span v-if="readonly">{{ field.name }}</span>
        <input
          v-else
          type="text"
          class="ml-4"
          :value="field.name"
          placeholder="Field name"
          @change="onNameChange"
        />
      </div>

      <div class="col">
        <span v-if="readonly">{{ field.fieldValueBuilderType }}</span>
        <b-form-select v-else v-model="field.fieldValueBuilderType" :options="builderTypes">
        </b-form-select>
      </div>

      <div class="col">
        <span v-if="readonly">{{ field.fieldValueBuilderArgument }}</span>
        <input
          v-else
          type="text"
          class="w-100"
          v-model="field.fieldValueBuilderArgument"
          placeholder="Parameter"
        />
      </div>
    </div>

    <div v-if="!readonly">
      <b-button
        v-if="isSelfContained && isSupportDerivatives"
        class="plus-minus"
        variant="outline-primary"
        @click="onPlus"
        >&plus;</b-button
      >
      <b-button
        v-if="!isSelfContained"
        class="plus-minus"
        variant="outline-primary"
        @click="onMinus"
        >&minus;</b-button
      >
    </div>
  </div>
</template>

<script>
import { Type, Matcher, SourceType, ValueBuilderType } from './fields.js';

export default {
  name: 'TableField',
  props: {
    field: {
      required: true,
    },
    readonly: {
      type: Boolean,
    },
  },
  computed: {
    matchers() {
      return this.field && this.field.type ? Type.getMatchers(this.field.type) : [];
    },
    types() {
      return Type.getTypes();
    },
    builderTypes() {
      return ValueBuilderType.all();
    },
    isSelfContained() {
      return !this.field || this.field.fieldSourceType === SourceType.SELF_CONTAINED;
    },
    isSupportDerivatives() {
      return this.field.type === Type.TEXT.name;
    },
  },
  watch: {
    matchers() {
      this.field.matcher = this.matchers[0].name;
    },
    field: {
      deep: true,
      handler() {
        this.$emit('change');
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    onNameChange(event) {
      this.field.name = event.target.value;
    },
    onPlus() {
      this.$emit('add');
    },
    onMinus() {
      this.$emit('remove');
    },
    getTypeText(value) {
      return Type.getType(value).text;
    },
    getMatcherText(value) {
      return Matcher.getMatcher(value).text;
    },
  },
};
</script>

<style scoped>
.plus-minus {
  width: 1.5em !important;
  line-height: 1.5em !important;
  padding: 0;
}
</style>
