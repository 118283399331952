var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-dropdown',{attrs:{"variant":"link","size":"sm"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('span',{staticClass:"font-weight-bold text-capitalize"},[_vm._v(_vm._s(_vm.subscription.status))])]},proxy:true}])},[(
        _vm.canBeSet(
          _vm.subscription.status,
          _vm.subscription.owner.id,
          _vm.SubscriptionStatus.PENDING,
          _vm.currentUser.accountId,
        )
      )?_c('b-dd-item',{on:{"click":function($event){$event.preventDefault();return _vm.setStatus(_vm.SubscriptionStatus.PENDING)}}},[_vm._v(" Request access ")]):_vm._e(),(
        _vm.canBeSet(
          _vm.subscription.status,
          _vm.subscription.owner.id,
          _vm.SubscriptionStatus.CANCELLED,
          _vm.currentUser.accountId,
        )
      )?_c('b-dd-item',{on:{"click":function($event){$event.preventDefault();return _vm.setStatus(_vm.SubscriptionStatus.CANCELLED)}}},[_vm._v(" Cancel subscription ")]):_vm._e(),(
        _vm.canBeSet(
          _vm.subscription.status,
          _vm.subscription.owner.id,
          _vm.SubscriptionStatus.APPROVED,
          _vm.currentUser.accountId,
        )
      )?_c('b-dd-item',{on:{"click":function($event){$event.preventDefault();return _vm.approve()}}},[_vm._v(" Approve ")]):_vm._e(),(
        _vm.canBeSet(
          _vm.subscription.status,
          _vm.subscription.owner.id,
          _vm.SubscriptionStatus.DENIED,
          _vm.currentUser.accountId,
        )
      )?_c('b-dd-item',{on:{"click":function($event){$event.preventDefault();return _vm.setStatus(_vm.SubscriptionStatus.DENIED)}}},[_vm._v(" Deny ")]):_vm._e(),(
        _vm.canBeSet(
          _vm.subscription.status,
          _vm.subscription.owner.id,
          _vm.SubscriptionStatus.REVOKED,
          _vm.currentUser.accountId,
        )
      )?_c('b-dd-item',{on:{"click":function($event){$event.preventDefault();return _vm.setStatus(_vm.SubscriptionStatus.REVOKED)}}},[_vm._v(" Revoke ")]):_vm._e(),(
        !_vm.isOwner(_vm.subscription, _vm.currentUser.accountId) &&
        _vm.isStatus(_vm.subscription, _vm.SubscriptionStatus.APPROVED) &&
        _vm.hasRefreshToken(_vm.subscription)
      )?_c('b-dd-item',{on:{"click":function($event){$event.preventDefault();return _vm.showTokenModal()}}},[_vm._v(" Download key ")]):_vm._e(),(
        !_vm.isOwner(_vm.subscription, _vm.currentUser.accountId) &&
        _vm.isStatus(_vm.subscription, _vm.SubscriptionStatus.APPROVED) &&
        !_vm.hasRefreshToken(_vm.subscription)
      )?_c('b-dd-item',{on:{"click":function($event){$event.preventDefault();return _vm.resetSubscription()}}},[_vm._v(" Request key ")]):_vm._e()],1),_c('owner-key-dialog',{ref:"modalKey",attrs:{"title":"Approve subscription","key-prompt":"Provide the key that was used to encrypt your data","subscription":true,"subscriptionType":_vm.subscriptionType},on:{"ok":_vm.onOwnerKeyProvided}}),_c('refresh-token',{ref:"tokenModal"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }