import * as projectComponents from '.';

export default [
  {
    path: 'blueprints',
    component: projectComponents.Blueprints,
    name: 'blueprints',
  },
  {
    path: 'installs',
    component: projectComponents.Installs,
    name: 'projectInstalls',
  },
  {
    path: 'logs',
    component: projectComponents.Logs,
    name: 'logs',
  },
  {
    path: 'blueprints/:blueprintId',
    component: projectComponents.EditBlueprint,
    name: 'editBlueprint',
    props: true,
  },
  {
    path: 'blueprints/:blueprintId/transforms/:transformId/:type',
    component: projectComponents.EditTransform,
    name: 'editTransform',
    props: true,
  },
  {
    path: 'keys',
    component: projectComponents.IntegrationsKeys,
    name: 'keys',
  },
  {
    path: 'variables',
    component: projectComponents.Variables,
    name: 'variables',
  },
];
