import * as Api from '../api.js';
import { ApiCallbacks, ApiState } from '../apiHelpers';

export default {
  setCurrentUser({ commit }, currentUser) {
    commit('setCurrentUser', currentUser);
  },
  setApiState({ commit }, payload) {
    commit('setApiState', payload);
  },
  removeApiState({ commit }, payload) {
    commit('removeApiState', payload);
  },
  loadMySubscriptions(store) {
    if (store.state.mySubscriptionsMapVersion > 1) return;
    const subscriptionApi = new Api.SubscriptionApi(
      new ApiCallbacks(new ApiState(store, 'Vuex'), null),
    );
    subscriptionApi
      .getSubscriptions(false)
      .then((subscriptions) => store.commit('setMySubscriptions', subscriptions));
  },
  loadSubscriptionsToMe(store) {
    if (store.state.subscriptionsToMeMapVersion > 1) return;
    const subscriptionApi = new Api.SubscriptionApi(
      new ApiCallbacks(new ApiState(store, 'Vuex'), null),
    );
    subscriptionApi
      .getSubscriptions(true)
      .then((subscriptions) => store.commit('setSubscriptionsToMe', subscriptions));
  },
  async loadConnectors(store) {
    const integrationsApi = new Api.IntegrationsApi(
      new ApiCallbacks(new ApiState(store, 'Vuex'), null),
    );
    const connectors = await integrationsApi.getConnectors();
    await store.commit(
      'setConnectors',
      connectors.sort((a, b) => a.name.localeCompare(b.name)),
    );
  },
  async loadBlueprint(store, blueprintId) {
    const blueprintsApi = new Api.BlueprintsApi(
      new ApiCallbacks(new ApiState(store, 'Vuex'), null),
    );
    const blueprint = await blueprintsApi.getBlueprint(blueprintId);
    await store.commit('setBlueprint', blueprint);
  },
  async loadProjects(store) {
    const projectsApi = new Api.ProjectsApi(new ApiCallbacks(new ApiState(store, 'Vuex'), null));
    const projects = await projectsApi.getProjects();
    await store.commit('setProjects', projects);
  },
  async loadProject(store, projectId) {
    const projectsApi = new Api.ProjectsApi(new ApiCallbacks(new ApiState(store, 'Vuex'), null));
    const project = await projectsApi.getProject(projectId);
    await store.commit('setProject', project);
  },
  async loadInstalls(store, projectId) {
    const projectsApi = new Api.ProjectsApi(new ApiCallbacks(new ApiState(store, 'Vuex'), null));
    const installs = await projectsApi.getProjectInstalls(projectId);
    await store.commit('setInstalls', installs);
  },
  async loadBlueprints(store, projectId) {
    const projectsApi = new Api.ProjectsApi(new ApiCallbacks(new ApiState(store, 'Vuex'), null));
    const blueprints = await projectsApi.getBlueprintsByProjectId(projectId);
    await store.commit(
      'setBlueprints',
      blueprints.sort((a, b) => a.name.localeCompare(b.name)),
    );
  },
  async loadLogs(store, { projectId, limit, offset }) {
    const projectsApi = new Api.ProjectsApi(new ApiCallbacks(new ApiState(store, 'Vuex'), null));
    const logs = await projectsApi.getProjectLogs(projectId, limit, offset);
    await store.commit('setLogs', logs);
  },
  async refreshLogs(store, { projectId, limit, offset }) {
    store.commit('clearLogs');
    const projectsApi = new Api.ProjectsApi(new ApiCallbacks(new ApiState(store, 'Vuex'), null));
    const logs = await projectsApi.getProjectLogs(projectId, limit, offset);
    store.commit('setLogs', logs);
  },
  async loadProjectLogData(store, { projectId, runId, id }) {
    const auditApi = new Api.AuditApi(new ApiCallbacks(new ApiState(store, 'Vuex'), null));
    return await auditApi.getProjectLogData(projectId, runId, id);
  },
  // It is not used now, but when we need multitransform edit, it will be useful
  async loadTransforms(store) {
    const integrationsApi = new Api.IntegrationsApi(
      new ApiCallbacks(new ApiState(store, 'Vuex'), null),
    );
    const transformsMap = {};
    for (const blueprint of store.getters.blueprints) {
      transformsMap[blueprint.id] = await integrationsApi.getTransforms(blueprint.id);
    }
    store.commit('setTransforms', transformsMap);
  },
  async loadTransform(store, ids) {
    const integrationsApi = new Api.IntegrationsApi(
      new ApiCallbacks(new ApiState(store, 'Vuex'), null),
    );
    const transform = await integrationsApi.getTransform(ids.blueprintId, ids.transformId);
    await store.commit('setTransform', transform);
  },
  async loadAuthTemplates(store) {
    const integrationsApi = new Api.IntegrationsApi(
      new ApiCallbacks(new ApiState(store, 'Vuex'), null),
    );
    const templates = await integrationsApi.getAuthTemplates();
    await store.commit('setAuthTemplates', templates);
  },
  async initStore(store) {
    const currentUser = store.state.currentUser;
    if (currentUser.permissions.has('DATA_MANAGEMENT')) {
      await store.dispatch('loadMySubscriptions');
      await store.dispatch('loadSubscriptionsToMe');
    }

    if (currentUser.permissions.has('INTEGRATIONS')) {
      await store.dispatch('loadConnectors');
      await store.dispatch('loadProjects');
    }
  },
  resetStore({ commit }) {
    commit('reset');
  },
};
