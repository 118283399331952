import { Api } from './Api';

class VariablesApi extends Api {
  static projectVariablesPath = (referenceId) => `integrations/projects/${referenceId}/variables`;

  getVariables(referenceId) {
    const variablesUrl = VariablesApi.projectVariablesPath(referenceId);
    return super.get(variablesUrl);
  }

  updateVariables(referenceId, variables) {
    const variablesUrl = VariablesApi.projectVariablesPath(referenceId);
    return super.put(variablesUrl, variables);
  }
}

export default VariablesApi;
