<template>
  <div class="card w-50 mx-auto">
    <div class="card-body" v-if="!submitted">
      <div class="alert alert-danger text-center" v-if="error">
        Something went wrong. Please try again
      </div>

      <h2 class="card-title">New account</h2>

      <form @submit.prevent="submit" class="needs-validation" novalidate>
        <div class="form-group row">
          <label class="col-3 col-form-label" for="account-name">Account name</label>
          <div class="col">
            <input
              id="account-name"
              :class="['form-control', { 'is-invalid': errors.has('accountName') }]"
              v-model="form.accountName"
              v-validate="'required'"
              data-vv-as="Account name"
              name="accountName"
              type="text"
              required
            />
            <div v-show="errors.has('accountName')" class="invalid-feedback">
              {{ errors.first('accountName') }}
            </div>
          </div>
          <info-icon>New account name</info-icon>
        </div>

        <div class="form-group row">
          <label class="col-3 col-form-label" for="account-businessInfo">Company information</label>
          <div class="col">
            <textarea
              id="account-businessInfo"
              class="form-control"
              v-model="form.accountBusinessInfo"
              data-vv-as="ID"
              name="accountBusinessInfo"
              rows="5"
              cols="50"
              maxlength="1000"
            ></textarea>
          </div>
          <info-icon>Basic company information, like address, phone number etc</info-icon>
        </div>

        <h2 class="card-title">New user</h2>

        <div class="form-group row">
          <label class="col-3 col-form-label" for="first-name">First name</label>
          <div class="col">
            <input
              id="first-name"
              :class="['form-control', { 'is-invalid': errors.has('firstName') }]"
              v-model="form.firstName"
              v-validate="'required'"
              data-vv-as="First Name"
              name="firstName"
              type="text"
            />
            <div v-show="errors.has('firstName')" class="invalid-feedback">
              {{ errors.first('firstName') }}
            </div>
          </div>
          <info-icon>User first name</info-icon>
        </div>

        <div class="form-group row">
          <label class="col-3 col-form-label" for="last-name">Last name</label>
          <div class="col">
            <input
              id="last-name"
              :class="['form-control', { 'is-invalid': errors.has('lastName') }]"
              v-model="form.lastName"
              v-validate="'required'"
              data-vv-as="Last Name"
              name="lastName"
              type="text"
            />
            <div v-show="errors.has('lastName')" class="invalid-feedback">
              {{ errors.first('lastName') }}
            </div>
          </div>
          <info-icon>User last name</info-icon>
        </div>

        <div class="form-group row">
          <label class="col-3 col-form-label" for="email">Email</label>
          <div class="col">
            <input
              id="email"
              name="email"
              v-model="form.email"
              v-validate="'required|email'"
              data-vv-as="Email"
              :class="['form-control', { 'is-invalid': errors.has('email') }]"
            />
            <div v-show="errors.has('email')" class="invalid-feedback">
              {{ errors.first('email') }}
            </div>
          </div>
          <info-icon>User email</info-icon>
        </div>

        <div class="d-flex justify-content-center">
          <button type="button" class="btn btn-primary mx-2" @click.prevent="$router.back()">
            Cancel
          </button>
          <button type="submit" class="btn btn-primary mx-2">Create</button>
        </div>
      </form>
    </div>

    <div class="card-body" v-if="submitted">
      <div class="message" style="margin: 10px 0">
        <p>Thanks for signing up with us!</p>
        <p>Please check your email for confirmation link. See you there.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { default as ApiMixin } from '../mixins/ApiMixin.vue';
import { default as InfoIcon } from '../components/InfoIcon.vue';
import { forOwn } from 'lodash';

export default {
  name: 'SignupAccount',
  mixins: [ApiMixin],
  components: {
    InfoIcon,
  },
  data() {
    return {
      form: {
        accountName: '',
        accountBusinessInfo: '',
        firstName: '',
        lastName: '',
        email: '',
      },
      error: false,
      submitted: false,
    };
  },
  async mounted() {
    if (this.$route.params.token) {
      try {
        await this.accountApi().validateToken(this.$route.params.token);
        this.error = null;
      } catch (_) {
        this.error = 'It looks like you clicked on an invalid link. Please try again';
        this.clearApiState();
      }
    }
  },
  methods: {
    async submit() {
      this.error = false;
      this.$validator.errors.clear();
      const isValid = await this.$validator.validateAll();

      if (!isValid) {
        return;
      }

      this.submit_();
    },
    cancel() {
      this.$router.push({ name: 'home' });
    },
    async submit_() {
      try {
        await this.accountApi().signup(this.form);
        this.submitted = true;
      } catch (error) {
        this.clearApiState();
        const data = error.response.data;
        const errors = data ? data.fields : null;

        if (errors) {
          const validatorErrors = this.$validator.errors;

          forOwn(errors, function (value, key) {
            if (validatorErrors.has(key)) {
              validatorErrors.remove(key);
            }
            validatorErrors.add(key, value);
          });
        } else {
          this.error = true;
        }
      }
    },
  },
};
</script>
