<template>
  <div>
    <div class="row">
      <div class="col">
        <h4>{{ title }}</h4>
      </div>
    </div>
    <ul class="nav justify-content-center">
      <router-link
        :to="{ name: 'integration-source', params: { blueprintId: blueprintId } }"
        class="nav-link"
        >Source</router-link
      >
      <router-link :to="{ name: 'integration-transform' }" class="nav-link">Transform</router-link>
      <router-link :to="{ name: 'integration-target' }" class="nav-link">Target</router-link>
      <li class="nav-item">
        <a class="nav-link disabled" href="#">API</a>
      </li>
      <li class="nav-item">
        <a class="nav-link disabled" href="#">Jobs</a>
      </li>
    </ul>
    <router-view name="helper"></router-view>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'IntegrationDetails',
  props: {
    blueprintId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['getBlueprint', 'connectors', 'getConnector']),
    title() {
      return this.blueprint?.name;
    },
    blueprint() {
      return this.getBlueprint(this.blueprintId);
    },
  },
};
</script>
