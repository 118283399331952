<template>
  <div>
    <div class="row header__body mr-0 align-content-center">
      <h4 class="p-4 m-4">Choose a project or start a new one</h4>
    </div>

    <div class="row p-4 m-4">
      <input type="text" placeholder="Search" class="col-3" v-model="filter" />
      <b-button
        @click.prevent="createNewProject"
        variant="outline-primary"
        size="xs"
        style="margin-left: 16px"
      >
        ＋
      </b-button>
    </div>

    <div class="row ml-4">
      <div
        class="col-lg-4 col-md-6 col-sm-10 mb-3"
        v-for="project in filteredProjects"
        v-bind:key="project.id"
      >
        <project-card :project="project" />
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 mb-3">
        <project-card
          @createProject="createNewProject"
          @newProjectImported="showImportProjectDialog"
        />
      </div>
    </div>

    <new-project ref="NewProjectDialog" @newProjectCreated="onNewProjectCreated" />

    <import-project
      ref="ImportProjectDialog"
      @newProjectImportSuccess="updateProjectList"
      :project="project"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import ProjectCard from '../project/components/ProjectCard';
import NewProject from '../project/components/NewProject';
import ImportProject from '../project/components/ImportProject.vue';
import { default as ApiMixin } from '../mixins/ApiMixin';

export default {
  name: 'Projects',
  mixins: [ApiMixin],
  components: {
    ProjectCard,
    NewProject,
    ImportProject,
  },
  computed: {
    ...mapGetters(['projects']),
    filteredProjects() {
      return !this.filter
        ? this.projects
        : this.projects.filter(
            (project) =>
              project.name.match(new RegExp(this.filter, 'i')) ||
              project.platform.match(new RegExp(this.filter, 'i')),
          );
    },
  },
  data() {
    return {
      filter: '',
      project: {},
    };
  },
  methods: {
    ...mapMutations(['setProjects']),
    createNewProject: function () {
      this.$refs.NewProjectDialog.show();
    },
    onNewProjectCreated(project) {
      this.updateProjectList(project);
      this.$router.push({ name: 'blueprints', params: { id: project.id } });
    },
    updateProjectList(project) {
      this.setProjects([...this.projects, project]);
    },
    showImportProjectDialog(project) {
      this.project = project;
      this.$refs.ImportProjectDialog.show();
    },
  },
};
</script>
<style></style>
