<template>
  <div>
    <modal-dlg
      id="modal"
      ref="modal"
      title="New Blueprint"
      :ok-disabled="!this.name || !this.integrationType || !this.httpMethod"
      :submit="createBlueprint"
      :validator="$validator"
      size="md"
      no-fade
      lazy
      @hidden="clearState"
      @shown="() => this.$refs.newBlueprint"
      @cancel="clearState"
    >
      <div style="min-height: 50vh">
        <div class="form-group col pb-3">
          <label class="row">Blueprint Name</label>
          <div class="row">
            <input
              ref="blueprintNameInput"
              :class="[{ 'is-invalid': errors.has('blueprintName') }, 'form-control']"
              v-model="name"
              v-validate="'required'"
              data-vv-as="Name"
              name="blueprintName"
              type="text"
            />
            <div class="invalid-feedback" v-show="errors.has('blueprintName')">
              {{ errors.first('blueprintName') }}
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label>Integration type</label>
          </div>
          <div class="row">
            <select
              key="selectIntegrationType"
              v-model="integrationType"
              class="w-100 form-control"
            >
              <option disabled>Select method</option>
              <option v-for="(type, index) in integrationTypes" :key="index">{{ type }}</option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label>HTTP Method for source configuration</label>
          </div>
          <div class="row">
            <select key="selectHTTPMethod" v-model="httpMethod" class="w-100 form-control">
              <option disabled>Select method</option>
              <option v-for="(method, index) in httpMethods" :key="index">{{ method }}</option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <b-form-checkbox
              id="new-blueprint-checkbox"
              v-model="isSingleton"
              name="new-blueprint-checkbox"
            >
              Is Singleton?
            </b-form-checkbox>
          </div>
        </div>
      </div>
    </modal-dlg>
  </div>
</template>
<script>
import { default as ApiMixin } from '../../mixins/ApiMixin.vue';
import { default as ModalDlg } from '../../components/ModalDlg.vue';
import { mapGetters } from 'vuex';

const httpMethods = ['POST', 'PUT', 'GET', 'DELETE'];

const integrationTypes = ['API', 'APPLICATION', 'WEBHOOK'];

export default {
  name: 'BlancBlueprint',
  mixins: [ApiMixin],
  components: {
    ModalDlg,
  },
  data() {
    return {
      name: '',
      httpMethod: '',
      integrationType: '',
      httpMethods,
      integrationTypes,
      isSingleton: false,
    };
  },
  computed: {
    ...mapGetters(['connectors']),
  },
  methods: {
    clearState: function () {
      this.httpMethod = '';
      this.integrationType = '';
      this.name = '';
      this.isSingleton = false;
    },
    show: function () {
      this.$refs.modal.show();
    },
    createBlueprint: function () {
      const sourceConnector = this.connectors.find(
        (connector) => connector.name === 'API source' && connector.type === 'SOURCE',
      );
      const destinationConnector = this.connectors.find(
        (connector) => connector.name === 'Echo' && connector.type === 'TARGET',
      );

      const newBlueprint = {
        name: this.name,
        integrationType: this.integrationType,
        sourceConnectorId: sourceConnector.id, // api source
        destinationConnectorId: destinationConnector.id, // echo
        sourceConfiguration: {
          httpMethod: this.httpMethod,
        },
      };

      if (this.isSingleton) {
        newBlueprint.scope = 'SINGLETON';
      }
      this.$emit('newBlueprint', newBlueprint);
      this.clearState();
    },
  },
};
</script>
