<template>
  <div class="card h-100 col-2 px-0">
    <div class="card-body">
      <div class="px-0 text-center">
        <img :src="connectorLogoSrc" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import format from 'string-format';
export default {
  name: 'IntegrationSource',
  computed: {
    ...mapGetters(['getBlueprint', 'connectors', 'getConnector']),
    blueprintId() {
      // For some reason the props did not work with nested routes
      return this.$route.params.blueprintId;
    },
    connector() {
      return this.getConnector(this.getBlueprint(this.blueprintId).sourceConnectorId);
    },
    connectorLogoSrc() {
      return format('/public/{fileName}.png', { fileName: this.connector.name.replace(' ', '-') });
    },
  },
};
</script>

<style>
img {
  max-width: 100%;
  max-height: 100%;
}
</style>
