<template>
  <div class="">
    <b-breadcrumb
      :items="[
        { text: 'Repositories', to: { name: 'repo-list' } },
        { text: repositoryId, to: { name: 'repo-edit' } },
        { text: 'APIs', to: { name: 'apis' } },
        { text: apiId, active: true },
      ]"
    />
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col text-left">
            <h2>API</h2>
          </div>
          <div class="col w-50 text-right">
            <b-button @click.prevent="editDetails" variant="outline-primary" size="sm">
              Edit
            </b-button>
          </div>
        </div>
        <api-details :api="api" readonly></api-details>
      </div>
    </div>

    <b-modal ref="modalDetails" title="API" @ok="saveDetails" @cancel="resetDetails" no-fade static>
      <api-details ref="apiEdit" :api="api" />
    </b-modal>
    <api-usage :repository-id="repositoryId" :api-id="apiId" class="my-2"></api-usage>
    <div class="row my-4">
      <div class="col">
        <button
          class="btn btn-primary"
          @click.prevent="
            $router.push({ name: 'repo-edit', params: { repositoryId: repositoryId } })
          "
        >
          Back
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { default as ApiMixin } from '../../mixins/ApiMixin.vue';
import { mapGetters } from 'vuex';
import ApiDetails from './ApiDetails.vue';
import ApiUsage from './ApiUsage.vue';

export default {
  name: 'Api',
  mixins: [ApiMixin],
  props: {
    repositoryId: {
      required: true,
    },
    apiId: {
      required: true,
    },
  },
  components: {
    ApiDetails,
    ApiUsage,
  },
  data() {
    return {
      api: {},
    };
  },
  computed: mapGetters(['currentUser']),
  mounted() {
    this.load();
  },
  methods: {
    load: function () {
      this.dataApiApi()
        .getApi(this.repositoryId, this.apiId)
        .then((api) => (this.api = api));
    },
    save: function (model) {
      this.dataApiApi()
        .updateApi(this.repositoryId, this.apiId, model)
        .then((response) => (this.api = response));
    },
    editDetails: function () {
      this.$refs.modalDetails.show();
      this.$refs.apiEdit.show();
    },
    saveDetails() {
      const model = this.$refs.apiEdit.getModel();
      this.save(model);
    },
    resetDetails() {
      this.$refs.apiEdit.resetModel();
    },
  },
};
</script>
