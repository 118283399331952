var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-4"},[_c('div',{staticClass:"pb-4"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[_c('span',{staticClass:"search",attrs:{"slot":"prepend"},slot:"prepend"}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filter),expression:"filter"}],staticClass:"search",attrs:{"type":"text","placeholder":"Find"},domProps:{"value":(_vm.filter)},on:{"input":function($event){if($event.target.composing)return;_vm.filter=$event.target.value}}})]),_c('div',[_c('b-button',{on:{"click":_vm.refresh}},[_c('i',{staticClass:"bi bi-arrow-repeat"})])],1)])]),_c('b-table',{attrs:{"hover":"","bordered":"","items":_vm.logs || [],"fields":_vm.COLUMNS,"filter":_vm.filter,"sort-by":"runTimestamp","sort-desc":"","thead-class":"table-header","tbody-class":"table-body"},scopedSlots:_vm._u([{key:"cell(status)",fn:function(data){return [_c('div',{staticClass:"text-center"},[(data.item.status === 'SUCCESS')?_c('b-icon-check-circle-fill',{attrs:{"variant":"success"}}):(data.item.status === 'FAILURE')?_c('b-icon-x-circle-fill',{attrs:{"variant":"danger"}}):(data.item.status === 'DATA')?_c('b-icon-file-earmark-check-fill',{attrs:{"variant":"info"}}):_vm._e()],1)]}},{key:"cell(actions)",fn:function(data){return [_c('b-dropdown',{staticClass:"button_style",attrs:{"id":"project-actions-dropdown","variant":"outline-primary","dropup":"","right":"","no-caret":"","toggle-class":"dropdown__class"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',[_c('i',{staticClass:"bi bi-three-dots"})])]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"id":"audit-data"},on:{"click":function($event){return _vm.getData(data.item.runId, data.item.id)}}},[_vm._v(" Get Data ")]),(data.item.status === 'DATA' && !!data.item.sourceTransformId)?_c('b-dropdown-item',{attrs:{"id":"source-transform"},on:{"click":function($event){return _vm.$router.push({
              name: 'editTransform',
              params: {
                transformId: data.item.sourceTransformId,
                blueprintId: data.item.blueprintId,
                type: 'source',
              },
              query: {
                runId: data.item.runId,
              },
            })}}},[_vm._v(" Test Source Transform ")]):_vm._e(),(data.item.status === 'DATA' && !!data.item.destinationTransformId)?_c('b-dropdown-item',{attrs:{"id":"destination-transform"},on:{"click":() =>
              _vm.$router.push({
                name: 'editTransform',
                params: {
                  transformId: data.item.destinationTransformId,
                  blueprintId: data.item.blueprintId,
                  type: 'destination',
                },
                query: {
                  runId: data.item.runId,
                },
              })}},[_vm._v(" Test Destination Transform ")]):_vm._e()],1)]}}])}),_c('div',{ref:"scrollTargetRef"},[(!_vm.isLastPage)?_c('span',[_vm._v("Loading...")]):(!_vm.logs.length)?_c('span',[_vm._v("There are no logs yet")]):_c('span',[_vm._v("All logs loaded")])]),_c('b-modal',{ref:"modalDetails",attrs:{"title":"Logs Details","ok-only":"","static":"","scrollable":"","size":"lg"},on:{"ok":_vm.closeModal}},[(_vm.jsonData)?_c('CodeEditor',{attrs:{"model-value":_vm.jsonData,"readonly":""}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }