<template>
  <div>
    <modal-dlg
      id="modal"
      ref="modal"
      title="Configure installation"
      :submit="updateInstall"
      :validator="$validator"
      @cancel="clearState"
      @hidden="clearState"
      size="lg"
      no-fade
      lazy
    >
      <h5>Configuration</h5>
      <div style="min-height: 50vh">
        <CodeEditor v-if="install" v-model="install.configuration" :height="300" />
      </div>
      <h5>Data</h5>
      <div style="min-height: 50vh">
        <CodeEditor v-if="install" v-model="install.data" :height="300" />
      </div>
    </modal-dlg>
  </div>
</template>

<script>
import { default as ApiMixin } from '../../mixins/ApiMixin.vue';
import { default as ModalDlg } from '../../components/ModalDlg.vue';
import CodeEditor from '../../components/CodeEditor.vue';

export default {
  name: 'EditInstallConfiguration',
  mixins: [ApiMixin],
  components: {
    ModalDlg,
    CodeEditor,
  },
  data() {
    return {
      install: null,
      data: null,
    };
  },
  methods: {
    updateInstall: function () {
      console.log('inst');
      this.$emit('updateInstallation', this.install);
    },
    show: function (install) {
      this.install = install;
      this.$refs.modal.show();
    },
    clearState: function () {
      this.install = null;
    },
  },
};
</script>
