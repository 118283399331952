<template>
  <div>
    <b-modal
      ref="modalDlg"
      :title="title"
      @shown="() => this.$refs.keyInput.focus()"
      @ok="ok"
      @cancel="onCancel"
      no-fade
      size="lg"
    >
      <div class="alert alert-info text-center mb-4">
        <div class="align-middle">
          {{ keyPrompt }}
        </div>
      </div>

      <div class="form-group row mb-4">
        <div class="col">
          <input
            id="key"
            ref="keyInput"
            :class="[{ 'is-invalid': errors.has('key') }, 'form-control']"
            v-model="key"
            v-validate="`required|alpha_dash|length:${keySize}`"
            data-vv-as="key"
            name="key"
            type="text"
            :minlength="keySize"
            :maxlength="keySize"
          />

          <button v-if="generate" type="button" class="btn btn-link" @click="key = generateKey()">
            Generate it for me
          </button>

          <div class="invalid-feedback" v-show="errors.has('key')">
            {{ errors.first('key') }}
          </div>
        </div>
      </div>

      <div v-if="subscription">
        <span>{{ isApi ? 'API methods' : 'Repository operations' }}</span>
        <input
          hidden
          value="api_methods"
          v-validate="'methods'"
          name="api_methods"
          class="form-control"
          :class="{ 'is-invalid': errors.has('api_methods') }"
        />

        <label v-for="(method, idx) in allMethods" :key="idx" class="row px-4 align-items-center">
          <input type="checkbox" :value="method.value" class="col-auto" @change="onMethodChange" />
          <span class="font-weight-bold col-2">{{ method.title }}</span>
          <span class="col-8">{{ method.description }}</span>
        </label>

        <div class="invalid-feedback">At least one method must be selected</div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue';
import { SubscriptionMethod } from '../data/apis/SubscriptionMethod';

const KEY_SIZE = 32;

export default {
  name: 'OwnerKeyDialog',
  props: {
    title: {
      type: String,
      required: true,
    },
    keyPrompt: {
      type: String,
      required: true,
    },
    generate: {
      default: false,
    },
    subscription: {
      default: false,
    },
    subscriptionType: {
      type: String,
      default: 'Api',
    },
  },
  data() {
    return {
      key: null,
      api_methods: [],
    };
  },
  computed: {
    keySize: () => KEY_SIZE,
    isApi() {
      return this.subscriptionType === 'Api';
    },
    allMethods() {
      return this.isApi
        ? SubscriptionMethod.allApiMethods()
        : SubscriptionMethod.allRepositoryMethods();
    },
  },
  methods: {
    open() {
      Vue.set(this, 'key', null);
      this.$refs.modalDlg.show();
      this.$validator.reset();
      this.$validator.extend('methods', (value, args) => {
        console.log('validating', value, args, this.api_methods.length > 0);
        return this.api_methods.length > 0;
      });
    },
    getModel() {
      return this.key;
    },
    generateKey: function () {
      let text = '';
      const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

      for (let i = 0; i < KEY_SIZE; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      }

      return text;
    },
    async ok(event) {
      event.preventDefault();

      const valid = await this.$validator.validate();
      if (valid) {
        this.$emit('ok', this.key, this.api_methods);
        this.$refs.modalDlg.hide();
      }
      console.log(this.errors);
    },
    onCancel() {
      this.$emit('cancel');
    },
    async onMethodChange(event) {
      const checkbox = event.target;
      if (checkbox.checked) {
        this.api_methods.push(checkbox.value);
      } else {
        const idx = this.api_methods.findIndex((m) => m === checkbox.value);
        if (idx > -1) {
          this.api_methods.splice(idx, 1);
        }
      }
      await this.$validator.validate('api_methods');
    },
  },
};
</script>
