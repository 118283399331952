<template>
  <div class="float-left">
    <div class="card text-center mr-4">
      <div class="card-body">
        <div class="value">{{ value }}</div>

        <div class="link" v-if="route">
          <router-link :to="route">
            {{ text }}
          </router-link>
        </div>

        <div v-else>
          {{ text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StatCard',
  props: {
    value: {
      required: true,
      default: '...',
    },
    text: {
      type: String,
      required: true,
    },
    route: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.card {
  min-width: 10em;
}

.value {
  font-size: 1.5rem;
}

.link {
}
</style>
