<template>
  <modal-dlg
    id="modal"
    ref="modal"
    title="New Install"
    :ok-disabled="!this.name || !this.tenantId"
    :submit="createInstall"
    :validator="$validator"
    size="md"
    no-fade
    lazy
    @hidden="clearState"
    @shown="() => this.$refs.newBlueprint"
    @cancel="clearState"
  >
    <div style="min-height: 50vh">
      <div class="form-group col pb-3">
        <label class="row">Install Name</label>
        <div class="row">
          <input
            :class="[{ 'is-invalid': errors.has('name') }, 'form-control']"
            v-model="name"
            v-validate="'required'"
            data-vv-as="Name"
            name="name"
            type="text"
          />
          <div class="invalid-feedback" v-show="errors.has('name')">
            {{ errors.first('name') }}
          </div>
        </div>
      </div>
      <div class="form-group col pb-3">
        <label class="row">Tenant ID</label>
        <div class="row">
          <input
            :class="[{ 'is-invalid': errors.has('tenantId') }, 'form-control']"
            v-model="tenantId"
            v-validate="'required'"
            data-vv-as="Tenant ID"
            name="tenantId"
            type="text"
          />
          <div class="invalid-feedback" v-show="errors.has('tenantId')">
            {{ errors.first('tenantId') }}
          </div>
        </div>
      </div>
      <div class="form-group col pb-3">
        <label class="row">Configuration</label>
        <CodeEditor v-model="configuration" :height="300" />
      </div>
    </div>
  </modal-dlg>
</template>

<script>
import ApiMixin from '../../mixins/ApiMixin.vue';
import ModalDlg from '../../components/ModalDlg.vue';
import CodeEditor from '../../components/CodeEditor.vue';

export default {
  name: 'NewInstall',
  mixins: [ApiMixin],
  components: {
    ModalDlg,
    CodeEditor,
  },
  data() {
    return {
      name: '',
      tenantId: '',
      configuration: {},
    };
  },
  methods: {
    clearState() {
      this.name = '';
      this.tenantId = '';
    },
    show() {
      this.$refs.modal.show();
    },
    createInstall() {
      const newInstall = {
        projectId: this.$route.params.id,
        name: this.name,
        tenantId: this.tenantId,
        configuration: this.configuration,
        data: {},
      };

      this.$emit('create', newInstall);
      this.clearState();
    },
  },
};
</script>
