<script>
export default {
  name: 'ValidatorProviderMixin',
  methods: {
    getValidator() {
      return this.$validator;
    },
  },
};
</script>
