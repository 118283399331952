<template>
  <div>
    <div class="form-group row">
      <label class="col-3 col-form-label" for="firstName">First Name</label>
      <div class="col-9">
        <input
          id="firstName"
          :class="[{ 'is-invalid': errors.has('firstName') }, 'form-control']"
          v-model="userIn.firstName"
          v-validate="'required'"
          data-vv-as="First Name"
          name="firstName"
          type="text"
        />
        <div class="invalid-feedback" v-show="errors.has('firstName')">
          {{ errors.first('firstName') }}
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-3 col-form-label" for="lastName">Last Name</label>
      <div class="col-9">
        <input
          id="lastName"
          :class="[{ 'is-invalid': errors.has('lastName') }, 'form-control']"
          v-model="userIn.lastName"
          v-validate="'required'"
          data-vv-as="Last Name"
          name="lastName"
          type="text"
        />
        <div class="invalid-feedback" v-show="errors.has('lastName')">
          {{ errors.first('lastName') }}
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-3 col-form-label" for="status">Status</label>
      <div class="col-9">
        <b-form-select
          id="status"
          v-model="user.status"
          :options="Status.ALL"
          class=""
          :disabled="isProfileView"
        />
      </div>
    </div>

    <div class="form-group row">
      <label class="col-3 col-form-label" for="email">Email</label>
      <div class="col-9">
        <input
          id="email"
          :class="[{ 'is-invalid': errors.has('email') }, 'form-control']"
          v-model="userIn.email"
          v-validate="'required|email'"
          readonly
          data-vv-as="Email"
          name="email"
          type="email"
        />
        <div class="invalid-feedback" v-show="errors.has('email')">
          {{ errors.first('email') }}
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-3 col-form-label" for="role">Role</label>
      <div class="col-9">
        <b-form-select
          id="role"
          v-model="userIn.role"
          :options="assignableRoles"
          :disabled="isProfileView"
        />
      </div>
    </div>

    <div class="row pb-5" :hidden="!isProfileView">
      <label class="d-flex justify-content-start col-6 col-form-label"
        >Two-factor authentication</label
      >
      <div class="d-flex justify-content-end col-6">
        <button class="btn btn-primary btn-sm mx-2" @click.prevent="onMfaButtonClick">
          {{ currentUser.mfaenabled ? 'Disable' : 'Enable' }}
        </button>
      </div>
    </div>
    <mfa-dlg ref="mfaDlg" :qr="this.qr" @success="onMfaSuccess" />
  </div>
</template>
<script>
import { default as ApiMixin } from '../mixins/ApiMixin.vue';
import { default as ValidatorProviderMixin } from '../mixins/ValidatorProviderMixin.vue';
import { cloneDeep } from 'lodash';
import { Role, Status } from './users.js';
import { mapGetters } from 'vuex';
import MfaDlg from './MfaDlg.vue';

export default {
  name: 'UserDetails',
  components: {
    MfaDlg,
  },
  mixins: [ApiMixin, ValidatorProviderMixin],
  inject: {
    $validator: '$validator',
  },
  props: {
    user: {
      type: Object,
      required: false,
      default: function () {
        return {};
      },
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    Role() {
      return Role;
    },
    Status() {
      return Status;
    },
    isProfileView() {
      return this.currentUser.id === this.userIn.id;
    },
    assignableRoles() {
      const role = Role.get(this.currentUser.role);
      return role.canAssign;
    },
    ...mapGetters(['currentUser']),
  },
  data() {
    return {
      userIn: cloneDeep(this.user),
      error: null,
      qr: '',
    };
  },
  watch: {
    user: function () {
      this.resetModel();
    },
  },
  methods: {
    getModel() {
      return this.userIn;
    },
    setModel(model) {
      return (this.userIn = cloneDeep(model));
    },
    resetModel() {
      this.userIn = cloneDeep(this.user);
    },
    async onMfaButtonClick() {
      if (this.currentUser.mfaenabled) {
        await this.userApi().disableMFA();
        this.currentUser.mfaenabled = false;
      } else {
        this.qr = await this.userApi().setTokenAsync();
        this.$refs.mfaDlg.show();
      }
    },
    onMfaSuccess() {
      this.currentUser.mfaenabled = true;
    },
  },
};
</script>
