<template>
  <div class="col">
    <div class="card-body">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>Token</th>
            <!-- Token name -->
            <th>Status</th>
            <th>Created on</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="token in this.tokens" :key="token.id">
            <td>{{ token.name }}</td>
            <td>{{ token.status }}</td>
            <td>{{ formatInstant(token.createdOn) }}</td>
            <td>
              <b-button-group>
                <b-button
                  :id="'cancel-btn-' + token.id"
                  :disabled="token.status === 'Canceled'"
                  variant="outline-primary"
                  style="float: left"
                  @click="showWarningModal(token)"
                  v-b-tooltip.hover
                  :title="token.status !== 'Canceled' ? 'Cancel token' : 'The token is canceled'"
                >
                  <b-icon-x-circle-fill aria-hidden="true" variant="outline-primary" />
                </b-button>

                <b-button
                  :id="'download-btn-' + token.id"
                  :disabled="!token.token || token.status === 'Canceled'"
                  variant="outline-primary"
                  @click="$emit('showTokenClicked', token.token)"
                  v-b-tooltip.hover
                  :title="token.token ? 'Download Token' : 'Download is not available'"
                >
                  <b-icon-download aria-hidden="true" variant="outline-primary" />
                </b-button>
              </b-button-group>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <b-modal id="warning-dialog" title="Warning" @ok="cancelToken()">
      <p class="my-4">The token {{ tokenInFocus.name }} will be Canceled. Press Ok to confirm.</p>
    </b-modal>

    <b-modal id="download-token-dialog" title="Token" @ok="this.tokenInFocus = {}">
      <p class="my-4">The token will be available for download for 24 hours</p>
      <p>{{ tokenInFocus.token }}</p>
    </b-modal>
  </div>
</template>

<script>
import { formatInstant } from '../dates.js';
import { BButtonGroup, BModal, VBTooltip, BIconDownload, BIconXCircleFill } from 'bootstrap-vue';
import { default as ApiMixin } from '../mixins/ApiMixin.vue';

export default {
  name: 'TokensTable',
  mixins: [ApiMixin],
  components: {
    BButtonGroup,
    BModal,
    BIconDownload,
    BIconXCircleFill,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    tokens: {
      required: true,
    },
  },
  data() {
    return {
      tokenInFocus: {},
    };
  },
  methods: {
    formatInstant,
    clearState: function () {
      this.tokenInFocus = {};
    },
    showWarningModal: function (token) {
      this.tokenInFocus = token;
      this.$bvModal.show('warning-dialog');
    },
    cancelToken: async function () {
      try {
        await this.tokensApi().cancelToken(this.tokenInFocus.id);
        this.tokenInFocus.status = 'Canceled';
        this.tokenInFocus = {};
      } catch {
        alert('Something is wrong');
      }
    },
    alert: function (message) {
      alert(message);
    },
  },
};
</script>

<style scoped>
td {
  height: 50px;
  width: 50px;
  text-align: left;
  vertical-align: middle;
}
</style>
