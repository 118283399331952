<template>
  <b-modal ref="modal" title="Use Google authenticator to scan QR code" @hidden="onHidden">
    <qrcode-vue :value="qr" size="300" level="H" />
    <div id="otpForm" class="form-group">
      <label for="OTP" class="font-weight-bold">Authentication code</label>
      <input
        id="OTP"
        name="OTP"
        v-model="otpValue"
        :class="[{ 'is-invalid': errors.has('OTP') }, 'form-control']"
        :disabled="otpPass"
        data-vv-as="OTP"
        type="text"
        v-validate="'digits:6'"
        autocomplete="off"
        placeholder="6-digit code"
        @input="onOtpInput"
      />
      <div class="invalid-feedback" v-show="errors.has('OTP')">
        {{ errors.first('OTP') }}
      </div>
    </div>
    <template #modal-footer>
      <div class="w-100"></div>
    </template>
  </b-modal>
</template>

<script>
import QrcodeVue from 'qrcode.vue';
import { default as ApiMixin } from '../mixins/ApiMixin.vue';
import { default as ValidatorProviderMixin } from '../mixins/ValidatorProviderMixin.vue';

export default {
  name: 'MfaDlg',
  mixins: [ApiMixin, ValidatorProviderMixin],
  inject: {
    $validator: '$validator',
  },
  components: {
    QrcodeVue,
  },
  data() {
    return {
      otpValue: '',
      otpPass: false,
    };
  },
  props: {
    qr: {
      type: String,
      required: true,
    },
  },
  methods: {
    show() {
      this.$refs.modal.show();
    },
    hide() {
      this.$refs.modal.hide();
    },
    reset() {
      this.firstOtp = '';
      this.firstOtpPass = false;
    },
    onHidden() {
      this.reset();
    },
    async onOtpInput() {
      if (this.otpValue.length == 6) {
        try {
          await this.userApi().verifyOtpToken(this.otpValue);
          this.userApi().enableMFA();
          this.$emit('success');
          this.hide();
        } catch (error) {
          this.otpValue = '';
          this.errors.add({
            field: 'OTP',
            msg: ' Two-factor code verification failed. Please try again.',
          });
        }
      }
    },
  },
};
</script>
