import format from 'string-format';
import { Api } from './Api';

class BlueprintsApi extends Api {
  static URL_BLUEPRINTS_GET_PUT_DELETE = 'integrations/blueprints/{uuid}';
  static URL_BLUEPRINTS_POST = 'integrations/blueprints';

  removeBlueprint(uuid) {
    const deleteBlueprintUrl = format(BlueprintsApi.URL_BLUEPRINTS_GET_PUT_DELETE, { uuid });
    return super.delete(deleteBlueprintUrl);
  }

  updateBlueprint(blueprint) {
    const updateBlueprintUrl = format(BlueprintsApi.URL_BLUEPRINTS_GET_PUT_DELETE, {
      uuid: blueprint.id,
    });
    return super.put(updateBlueprintUrl, blueprint);
  }

  getBlueprint(uuid) {
    const updateBlueprintUrl = format(BlueprintsApi.URL_BLUEPRINTS_GET_PUT_DELETE, { uuid });
    return super.get(updateBlueprintUrl);
  }

  createBlueprint(blueprint) {
    return super.post(BlueprintsApi.URL_BLUEPRINTS_POST, blueprint);
  }
}

export default BlueprintsApi;
