var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-3 col-form-label",attrs:{"for":"repoName"}},[_vm._v("Name")]),_c('div',{staticClass:"col"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.repoIn.name),expression:"repoIn.name"},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],ref:"repoNameInput",class:[
          { 'is-invalid': _vm.errors.has('repoName') },
          _vm.readonly ? 'form-control-plaintext' : 'form-control',
        ],attrs:{"id":"repoName","readonly":_vm.readonly,"data-vv-as":"Name","name":"repoName","type":"text"},domProps:{"value":(_vm.repoIn.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.repoIn, "name", $event.target.value)}}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('repoName')),expression:"errors.has('repoName')"}],staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('repoName'))+" ")])])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-3 col-form-label",attrs:{"for":"Type"}},[_vm._v("Type")]),_c('div',{staticClass:"col"},[_c('b-form-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:[
          { 'is-invalid': _vm.errors.has('type') },
          _vm.readonly ? 'form-control-plaintext' : 'form-control',
        ],attrs:{"id":"type","options":_vm.types,"data-vv-validate-on":"change","data-vv-as":"Type","data-vv-name":"type"},model:{value:(_vm.repoIn.type),callback:function ($$v) {_vm.$set(_vm.repoIn, "type", $$v)},expression:"repoIn.type"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('type')),expression:"errors.has('type')"}],staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('type'))+" ")])],1)]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-3 col-form-label",attrs:{"for":"repoDescription"}},[_vm._v("Description")]),_c('div',{staticClass:"col"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.repoIn.description),expression:"repoIn.description"}],class:[_vm.readonly ? 'form-control-plaintext' : 'form-control'],attrs:{"id":"repoDescription","readonly":_vm.readonly,"data-vv-as":"Description","name":"repoDescription","rows":"3","cols":"50","maxlength":"1000"},domProps:{"value":(_vm.repoIn.description)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.repoIn, "description", $event.target.value)}}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }