<template>
  <div>
    <modal-dlg
      id="modal"
      ref="modal"
      title="New Token"
      :submit="createToken"
      :validator="$validator"
      @hidden="clearState"
      @shown="() => this.$refs.tokenNameInput.focus()"
      @cancel="clearState"
      no-fade
      lazy
    >
      <div class="form-group row">
        <label class="col-3 col-form-label">Name</label>
        <div class="col">
          <input
            ref="tokenNameInput"
            :class="[{ 'is-invalid': errors.has('tokenName') }, 'form-control']"
            v-model="newTokenName"
            v-validate="'required'"
            data-vv-as="Name"
            name="tokenName"
            type="text"
          />
          <div class="invalid-feedback" v-show="errors.has('tokenName')">
            {{ errors.first('tokenName') }}
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-3 col-form-label">Key</label>
        <div class="col">
          <input
            id="key"
            ref="keyInput"
            :class="[{ 'is-invalid': errors.has('key') }, 'form-control']"
            v-model="newTokenKey"
            v-validate="`required|alpha_dash|length:${keySize}`"
            data-vv-as="key"
            name="key"
            type="text"
            :minlength="keySize"
            :maxlength="keySize"
          />
          <div class="invalid-feedback" v-show="errors.has('key')">
            {{ errors.first('key') }}
          </div>
        </div>
      </div>

      <div>
        <span>API methods</span>
        <input
          hidden
          value="newTokenApiMethods"
          v-validate="'methods'"
          name="newTokenApiMethods"
          class="form-control"
          :class="{ 'is-invalid': errors.has('newTokenApiMethods') }"
        />

        <label
          v-for="method in allApiMethods"
          class="row px-4 align-items-center"
          :key="method.value"
        >
          <input type="checkbox" :value="method.value" class="col-auto" @change="onMethodChange" />
          <span class="font-weight-bold col-2">{{ method.title }}</span>
          <span class="col-8">{{ method.description }}</span>
        </label>

        <div class="invalid-feedback">At least one of the API methods should be selected</div>
      </div>
    </modal-dlg>
  </div>
</template>

<script>
import { default as ApiMixin } from '../mixins/ApiMixin.vue';
import { default as ModalDlg } from '../components/ModalDlg.vue';
import { SubscriptionMethod } from '../data/apis/SubscriptionMethod';
export default {
  name: 'NewDataTokenDialog',
  mixins: [ApiMixin],
  components: {
    ModalDlg,
  },
  props: {
    repositoryId: {
      required: true,
    },
  },
  async mounted() {
    //this.$validator.reset();
    this.$validator.extend('methods', (value, args) => {
      console.log('validating', value, args, this.newTokenApiMethods.length > 0);
      return this.newTokenApiMethods.length > 0;
    });
  },
  computed: {
    keySize: () => 32,
    allApiMethods() {
      return SubscriptionMethod.allApiMethods();
    },
  },
  data() {
    return {
      newTokenKey: '',
      newTokenName: '',
      newTokenApiMethods: [],
    };
  },
  methods: {
    clearState: function () {
      this.newTokenKey = '';
      this.newTokenName = '';
      this.newTokenApiMethods = [];
    },
    show: function () {
      this.$refs.modal.show();
    },
    createToken: async function () {
      try {
        const valid = await this.$validator.validate();
        if (valid) {
          const tokenForm = {
            name: this.newTokenName,
            key: this.newTokenKey,
            methods: this.newTokenApiMethods,
          };
          const newToken = await this.tokensApi().createDataToken(this.repositoryId, tokenForm);
          this.$emit('newTokenCreated', newToken);
        }
        this.clearState();
      } catch (error) {
        console.error(error);
        alert('something is wrong, try again');
      }
    },
    async onMethodChange(event) {
      const checkbox = event.target;
      if (checkbox.checked) {
        this.newTokenApiMethods.push(checkbox.value);
      } else {
        const idx = this.newTokenApiMethods.findIndex((m) => m === checkbox.value);
        if (idx > -1) {
          this.newTokenApiMethods.splice(idx, 1);
        }
      }
      await this.$validator.validate('newTokenApiMethods');
    },
  },
};
</script>
