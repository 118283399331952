<template>
  <div class="p-4">
    <div class="pb-4">
      <span slot="prepend" class="search"></span>
      <input type="text" v-model="filter" placeholder="Find" class="search" />
      <button class="ml-3" @click="$refs.newInstall.show()">+ Install</button>
    </div>
    <b-table
      hover
      bordered
      :items="installs || []"
      :fields="COLUMNS"
      :filter="filter"
      :sort-compare="sort"
      thead-class="table-header"
      tbody-class="table-body"
      sort-by="dateCreated"
    >
      <template #cell(actions)="data">
        <div class="d-flex flex-row justify-content-center">
          <b-button
            class="mr-3"
            :id="'configure-btn-' + data.item.referenceId"
            variant="outline-primary"
            style="float: left"
            @click="configureInstall(data.item)"
          >
            Configure
            <i class="bi bi-gear" />
          </b-button>
          <b-button
            class="mr-3"
            :id="'remove-btn-' + data.item.referenceId"
            variant="outline-danger"
            style="float: left"
            @click="removeInstall(data.item)"
          >
            Remove
            <i class="bi bi-trash" />
          </b-button>
          <b-button
            class="mr-3"
            :id="'show-key-btn-' + data.item.referenceId"
            variant="outline-primary"
            style="float: left"
            @click="showKey(data.item.tenantId)"
          >
            Show key
            <i class="bi bi-key" />
          </b-button>
        </div>
      </template>
    </b-table>
    <span v-if="isLoading">Loading...</span>
    <span v-else-if="!installs.length">There are no installs yet</span>
    <key-view ref="keyView" />
    <edit-install ref="editInstall" @updateInstallation="updateInstallation" />
    <new-install ref="newInstall" @create="createInstall" />
    <remove-install ref="removeInstall" @removeInstallation="removeInstallation" />
  </div>
</template>

<script>
import { default as ApiMixin } from '../mixins/ApiMixin';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { formatInstant } from '../dates';
import KeyView from './components/integrationKeys/KeyView.vue';
import EditInstall from './components/EditInstallConfiguration.vue';
import NewInstall from './components/NewInstall.vue';
import RemoveInstall from './components/RemoveInstallConfiguration.vue';

const COLUMNS = [
  {
    key: 'name',
    label: 'Name',
    sortable: false,
  },
  {
    key: 'tenantId',
    label: 'Tenant Id',
    sortable: false,
  },
  {
    key: 'dateCreated',
    label: 'Date Created',
    sortable: false,
    formatter: (value) => formatInstant(value),
  },
  {
    key: 'actions',
    label: 'Actions',
    sortable: false,
  },
];

export default {
  name: 'Installs',
  mixins: [ApiMixin],
  components: {
    KeyView,
    EditInstall,
    NewInstall,
    RemoveInstall,
  },
  props: {
    projectId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      blueprints: [],
      COLUMNS: COLUMNS,
      filter: null,
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters(['installs']),
  },
  async mounted() {
    if (this.projectId) {
      await this.loadInstalls(this.projectId);
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions(['loadInstalls']),
    ...mapMutations(['setInstalls']),
    sort(a, b, key) {
      return a[key].localeCompare(b[key]);
    },
    configureInstall(install) {
      this.$refs.editInstall.show(install);
    },
    async createInstall(install) {
      const createdInstall = await this.projectsApi().createProjectInstall(this.projectId, install);
      this.setInstalls([createdInstall, ...this.installs]);
    },
    removeInstall(install) {
      this.$refs.removeInstall.show(install);
    },
    async updateInstallation(install) {
      const updatedInstall = await this.projectsApi().updateProjectInstall(this.projectId, install);
      this.setInstalls(
        this.installs.map((installation) => {
          if (installation.referenceId === updatedInstall.referenceId) {
            return updatedInstall;
          }
          return installation;
        }),
      );
    },
    async removeInstallation(install) {
      await this.projectsApi().deleteProjectInstall(this.projectId, install);
      this.setInstalls(
        this.installs.filter((installation) => installation.referenceId !== install.referenceId),
      );
    },
    async showKey(tenantId) {
      const keys = await this.keysApi().getIntegrationsKeysByTenantId(this.projectId, tenantId);
      const foundKey = keys.find((key) => key.tenantId === tenantId);

      if (!foundKey) {
        alert('Key not found');
      }

      this.$refs.keyView.show(foundKey);
    },
  },
};
</script>

<style scoped>
.table-header {
  background-color: #999;
  background-image: linear-gradient(#eee, #e0e0e0);
}

span.search {
  padding-right: 0.9em;
  background: url('../../../images/search.svg') no-repeat center;
  z-index: 10;
  position: relative;
  left: 20px;
}

input.search {
  padding-left: 25px;
}

.table-header th {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.table-body td {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
</style>
