import format from 'string-format';
import { Api } from './Api';

class IntegrationsApi extends Api {
  static URL_GET_CONNECTORS = '/integrations/connectors';
  static URL_GET_CONNECTORS_TEMPLATE = '/integrations/connectors/{connectorId}/blueprint-config';
  static URL_GET_CONNECTORS_EXAMPLES = '/integrations/connectors' + '/{connectorId}/examples';
  static URL_GET_BLUEPRINTS = '/integrations/blueprints';
  static URL_GET_TRANSFORMS = IntegrationsApi.URL_GET_BLUEPRINTS + '/{blueprintId}/transforms';
  static URL_GET_TRANSFORM =
    IntegrationsApi.URL_GET_BLUEPRINTS + '/{blueprintId}/transforms/{transformId}';
  static URL_PUT_TRANSFORM = '/integrations/transforms/{transformId}';
  static URL_GET_INSTANCES = IntegrationsApi.URL_GET_BLUEPRINTS + '/{blueprintId}/instances';
  static URL_TEST_TRANSFORM = 'integrations/transforms/test';
  static URL_GET_AUTH_TEMPLATES = 'integrations/auth';

  async getConnectors() {
    return await super.get(IntegrationsApi.URL_GET_CONNECTORS);
  }
  getConnectorTemplate(connectorId) {
    const url = format(IntegrationsApi.URL_GET_CONNECTORS_TEMPLATE, { connectorId });
    return super.get(url);
  }
  async getExamples(connectorId) {
    const url = format(IntegrationsApi.URL_GET_CONNECTORS_EXAMPLES, { connectorId });
    return await super.get(url);
  }

  async getBlueprints() {
    return await super.get(IntegrationsApi.URL_GET_BLUEPRINTS);
  }

  async getTransforms(blueprintId) {
    const url = format(IntegrationsApi.URL_GET_TRANSFORMS, { blueprintId });
    return await super.get(url);
  }

  async getTransform(blueprintId, transformId) {
    const url = format(IntegrationsApi.URL_GET_TRANSFORM, { blueprintId, transformId });
    return await super.get(url);
  }

  async createTransform(transform, blueprintId) {
    const url = format(IntegrationsApi.URL_GET_TRANSFORMS, { blueprintId });
    return super.post(url, transform);
  }

  async updateTransform(transform) {
    const url = format(IntegrationsApi.URL_PUT_TRANSFORM, { transformId: transform.id });
    return await super.put(url, transform);
  }

  async testTransform(transform, object) {
    return await super.post(IntegrationsApi.URL_TEST_TRANSFORM, { transform, object });
  }

  async getInstances(blueprintId) {
    const url = format(IntegrationsApi.URL_GET_INSTANCES, { blueprintId });
    return await super.get(url);
  }

  async getAuthTemplates() {
    return await super.get(IntegrationsApi.URL_GET_AUTH_TEMPLATES);
  }
}

export default IntegrationsApi;
