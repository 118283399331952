import * as components from '.';

export default [
  {
    path: '/users/activate/:token',
    component: components.UserActivate,
    props: {
      existing: false,
    },
    meta: { requiresAuth: false },
  },
  {
    path: '/users/reset/:token',
    component: components.UserActivate,
    props: {
      existing: true,
    },
    meta: { requiresAuth: false },
  },
  {
    path: '/users',
    component: components.UserList,
    name: 'users',
  },
  {
    name: 'profile',
    path: '/users/currentProfile',
    component: components.Profile,
  },
  {
    path: '/users/:id',
    component: components.UserEdit,
    props: true,
  },
];
