import * as components from '.';

export default [
  {
    path: '/account',
    component: components.Account,
    name: 'account',
  },
  {
    path: '/accounts',
    component: components.AccountList,
    name: 'account-list',
  },
  {
    path: '/accounts/create',
    component: components.AccountEdit,
    name: 'account-create',
  },
  {
    path: '/accounts/update',
    component: components.AccountEdit,
    name: 'account-update',
    props: true,
  },
  {
    path: '/accounts/:accountId',
    component: components.Account,
    props: true,
  },
];
