<script>
import { forOwn, get } from 'lodash';

export default {
  name: 'ValidateMixin',
  methods: {
    validateInput(validator) {
      validator = validator ? validator : this.$validator;
      if (!validator) {
        return Promise.resolve();
      }
      validator.errors.clear();

      return validator.validateAll().then((allValid) => {
        console.debug('Validated to: %s', allValid);
        return allValid ? Promise.resolve() : Promise.reject(validator.errors);
      });
    },
    onSubmitError(validator, error) {
      if (!error) {
        return;
      }
      validator = validator ? validator : this.$validator;
      if (!validator) {
        return;
      }
      const fields = get(error, 'response.data.fields', null);
      if (fields) {
        forOwn(fields, (value, key) => {
          if (validator.fields.find(key)) {
            validator.errors.add({ field: key, msg: value });
          }
        });
      }
    },
  },
};
</script>
