import { Validator } from 'vee-validate';
import _ from 'lodash';

const passwordMatch = {
  getMessage() {
    return "Password doesn't match";
  },
  validate(value, args) {
    const id = _.first(args);
    if (!id) {
      console.error('passwordMatch validator should have a password id in it');
    }
    const el = document.getElementById(id);
    return el && el.value === value;
  },
};

const passwordRegex = new RegExp('^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})');

const passwordStrong = {
  getMessage() {
    return 'The password should be at least 8 characters and contain a mix of letters, numbers & symbols';
  },
  validate(value) {
    return passwordRegex.test(value);
  },
};

Validator.extend('passwordMatch', passwordMatch);
Validator.extend('passwordStrong', passwordStrong);
