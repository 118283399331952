<template>
  <div class="card-body">
    <div class="row">
      <div class="col-1">
        <img class="repository-icon" :src="require('../../../images/api.png')" />
      </div>

      <div class="col-11">
        <div class="row mb-1">
          <div class="col text-left">
            <router-link
              :to="{ name: 'repository-info', params: { repositoryId: repository.referenceId } }"
            >
              <h4>{{ repository.name }}</h4>
            </router-link>
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-auto font-weight-bold">Provided by:</div>
          <div class="col">{{ repository.owner.name }}</div>
          <subscription-manipulation-button
            v-if="subscription"
            :subscription="subscription"
            col-auto
            text-right
          />
        </div>

        <div class="row">
          <div class="col">
            {{ repository.description }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubscriptionManipulationButton from '../components/SubscriptionManipulationButton.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'CatalogRepositoryEntry',
  components: {
    SubscriptionManipulationButton,
  },
  props: {
    repository: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['currentUser', 'myRepositorySubscriptionsByRepository']),
    subscription: function () {
      const val = this.myRepositorySubscriptionsByRepository(this.repository.referenceId);
      return val;
    },
  },
};
</script>
