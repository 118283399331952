<template>
  <div>
    <div class="row" h-100 d-inline-block>
      <div class="col-4">
        <div class="row m-0">
          <h3 class="col-6">Example</h3>
          <div class="dropdown col-6 text-right px-0">
            <button
              class="btn btn-secondary btn-sm dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              {{ exampleSelected || 'No examples' }}
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <button
                class="dropdown-item"
                v-for="exampleName in examplesNames"
                :key="exampleName"
                @click="onExampleChange(exampleName)"
              >
                {{ exampleName }}
              </button>
            </div>
          </div>
        </div>
        <div class="row m-0">
          <prism-editor
            id="example"
            class="my-editor height-100"
            v-model="exampleSelectedCode"
            :highlight="highlighter"
            line-numbers
          ></prism-editor>
        </div>
      </div>
      <div class="col-4">
        <div class="row m-0">
          <h3 class="col-6">Transform</h3>
          <div class="dropdown col-6 text-right px-0">
            <button
              class="btn btn-secondary btn-sm dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              {{ transformSelected || 'No transform' }}
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <button
                class="dropdown-item"
                v-for="transformName in transformNames"
                :key="transformName"
                @click="onTransformChange(transformName)"
              >
                {{ transformName }}
              </button>
            </div>
          </div>
        </div>
        <div class="row m-0">
          <prism-editor
            id="transform"
            class="my-editor px-1 height-100"
            v-model="transformSelectedCode"
            :highlight="highlighter"
            line-numbers
          ></prism-editor>
        </div>
      </div>
      <div class="col-4">
        <div class="row m-0">
          <h3 class="col-6">Transform</h3>
          <div class="col-6 text-right px-0">
            <button
              id="evaluateButton"
              class="btn btn-secondary btn-sm"
              type="button"
              @click="onEvaluate()"
            >
              Evaluate
            </button>
          </div>
        </div>
        <div class="row m-0">
          <prism-editor
            id="result"
            class="my-editor px-1 height-100"
            v-model="resultCode"
            :highlight="highlighter"
            line-numbers
          ></prism-editor>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import Prism Editor
import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css'; // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism-tomorrow.css'; // import syntax highlighting styles
import { default as ApiMixin } from '../mixins/ApiMixin.vue';
export default {
  name: 'IntegrationTransform',
  mixins: [ApiMixin],
  computed: {
    ...mapGetters(['getBlueprint', 'getTransforms', 'getConnector', 'getConnectorExamples']),
    blueprintId() {
      // For some reason the props did not work with nested routes
      return this.$route.params.blueprintId;
    },
    blueprint() {
      return this.getBlueprint(this.blueprintId);
    },
    examples() {
      return this.getConnectorExamples(this.blueprint?.sourceConnectorId)?.reduce((acc, el) => {
        acc[el.name] = el.code;
        return acc;
      }, {});
    },
    examplesNames() {
      const examplesNames = Object.keys(this.examples || []);
      if (!this.exampleSelected && examplesNames.length) {
        [this.exampleSelected] = examplesNames;
      }
      return examplesNames;
    },
    transforms() {
      return this.getTransforms(this.blueprintId).reduce((acc, el) => {
        acc[el.versionName] = el.code;
        return acc;
      }, {});
    },
    transformNames() {
      const transformNames = Object.keys(this.transforms);
      if (!this.transformSelected && transformNames.length) {
        [this.transformSelected] = transformNames;
      }
      return transformNames;
    },
  },
  components: {
    PrismEditor,
  },
  data: () => ({
    exampleSelected: '',
    exampleSelectedCode: '',
    transformSelected: '',
    transformSelectedCode: '',
    resultCode: '',
  }),
  mounted() {
    this.load();
  },
  methods: {
    load: function () {
      //this.examples = this.getConnectorExamples(this.blueprint?.sourceConnectorId);
    },
    highlighter(code) {
      return highlight(code, languages.javascript); // languages.<insert language> to return html with markup
    },
    onExampleChange(option) {
      this.exampleSelected = option;
    },
    onTransformChange(option) {
      this.transformSelected = option;
    },
    async onEvaluate() {
      this.resultCode = await this.evaluate();
    },
    async evaluate() {
      let transform = null;
      let example = null;
      if (!this.transformSelectedCode) {
        return 'Error: Transform is empty';
      }
      if (!this.exampleSelectedCode) {
        return 'Error: Example is empty';
      }
      try {
        transform = JSON.parse(this.transformSelectedCode);
      } catch (err) {
        return 'Error while parsing transform json: \n' + err.message;
      }

      try {
        example = JSON.parse(this.exampleSelectedCode);
      } catch (err) {
        return 'Error while parsing example json: \n' + err.message;
      }
      const evaluatedObject = await this.integrationApi().testTransform(transform, example);
      return await JSON.stringify(evaluatedObject, null, 2);
    },
  },
  watch: {
    exampleSelected: function () {
      this.exampleSelectedCode = this.exampleSelected
        ? JSON.stringify(JSON.parse(this.examples[this.exampleSelected]), null, 2)
        : '{}';
    },
    transformSelected: function () {
      this.transformSelectedCode = this.transformSelected
        ? JSON.stringify(JSON.parse(this.transforms[this.transformSelected]), null, 2)
        : '{}';
    },
  },
};
</script>

<style>
.height-80 {
  height: 80vh;
}
/* required class */
.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #2c2c2c;

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}

pre {
  color: #ccc;
}
</style>
