<template>
  <div></div>
</template>

<script>
import { mapGetters } from 'vuex';
import { default as PermissionMixin } from '../mixins/PermissionMixin.vue';

export default {
  name: 'Home',
  mixins: [PermissionMixin],
  computed: mapGetters(['currentUser']),
  data() {
    return {};
  },
  mounted() {
    this.$router.push({ name: this.getRouteName() });
  },
  methods: {
    getRouteName() {
      let name;
      if (this.hasPermission(this.USER_MANAGEMENT)) {
        name = 'account';
      } else if (this.hasPermission(this.DATA_MANAGEMENT)) {
        name = 'repo-list';
      } else {
        name = 'login';
        console.error('Not supported user permissions of %o', this.currentUser);
      }
      return name;
    },
  },
};
</script>

<style scoped></style>
