<template>
  <div class="p-4">
    <div class="pb-4 row">
      <span slot="prepend" class="search"></span>
      <input type="text" v-model="filter" placeholder="Find" class="search" />
      <button class="ml-3" @click="$refs.newBlueprint.show()">+ Blank Blueprint</button>
    </div>
    <b-table
      hover
      bordered
      :items="blueprints || []"
      :fields="COLUMNS"
      :filter="filter"
      fixed
      thead-class="table-header"
      tbody-class="table-body"
    >
      <template #cell(action)="data">
        <div class="d-flex flex-row justify-content-center">
          <b-button
            variant="outline-primary"
            class="mx-1 font-weight-bold"
            @click="
              $router.push({
                name: 'editBlueprint',
                params: { blueprintId: data.item.id },
              })
            "
            style="width: 164px; right: 3rem"
          >
            Edit
          </b-button>
          <b-dropdown
            id="actions-dropdown"
            dropup
            right
            no-caret
            variant="link"
            toggle-class="dropdown__class"
          >
            <template #button-content>
              <div>
                <i class="bi bi-three-dots" />
              </div>
            </template>
            <b-dropdown-item @click="() => {}">Connect blueprint</b-dropdown-item>
            <b-dropdown-item @click="removeBlueprint(data.item.id)">
              Remove blueprint
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>
    <span v-if="isLoading">Loading...</span>
    <span v-else-if="!blueprints.length">There are no blueprints yet</span>
    <blanc-blueprint ref="newBlueprint" @newBlueprint="createBlancBlueprint" />
  </div>
</template>

<script>
import { default as ApiMixin } from '../mixins/ApiMixin';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { formatInstant } from '../dates';
import BlancBlueprint from './components/BlancBlueprint';

const COLUMNS = [
  {
    key: 'name',
    label: 'Blueprint Name',
  },
  {
    key: 'createdOn',
    label: 'Date Created',
    formatter: (value) => formatInstant(value),
  },
  {
    key: 'description',
    label: 'Description',
  },
  {
    key: 'action',
    label: 'Actions',
  },
];

export default {
  name: 'Projects',
  mixins: [ApiMixin],
  components: {
    BlancBlueprint,
  },
  props: {
    projectId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      COLUMNS: COLUMNS,
      filter: null,
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters(['currentUser', 'blueprints']),
  },
  async mounted() {
    if (this.projectId) {
      await this.loadBlueprints(this.projectId);
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions(['loadBlueprints']),
    ...mapMutations(['setBlueprints']),
    createBlancBlueprint: async function (blueprint) {
      const createdBlueprint = await this.blueprintsApi().createBlueprint({
        ...blueprint,
        projectId: this.projectId,
      });
      this.setBlueprints({
        ...this.blueprints,
        createdBlueprint,
      });
      await this.$router.push({
        name: 'editBlueprint',
        params: { blueprintId: createdBlueprint.id },
      });
    },
    removeBlueprint: async function (uuid) {
      await this.blueprintsApi().removeBlueprint(uuid);
      this.setBlueprints(this.blueprints.filter((blueprint) => blueprint.id !== uuid));
    },
  },
};
</script>
<style scoped>
pre {
  color: #212529;
  font-style: italic;
}

.table-header {
  background-color: #999;
  background-image: linear-gradient(#eee, #e0e0e0);
}

span.search {
  padding-right: 0.9em;
  background: url('../../../images/search.svg') no-repeat center;
  z-index: 10;
  position: relative;
  left: 20px;
}

input.search {
  padding-left: 25px;
}

.table-header th {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.table-body td {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
</style>
