<template>
  <div class="card w-75">
    <div class="card-body">
      <h4 class="card-title">Account details</h4>

      <account-details ref="accountDetails" :readonly="false"></account-details>

      <div class="row justify-content-center">
        <b-button @click="onSave" variant="outline-primary" size="sm" class="mx-1 font-weight-bold">
          Save
        </b-button>
        <b-button
          @click="onCancel"
          variant="outline-primary"
          size="sm"
          class="mx-1 font-weight-bold"
        >
          Cancel
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { default as ApiMixin } from '../mixins/ApiMixin.vue';
import { default as ValidationMixin } from '../mixins/ValidationMixin.vue';
import AccountDetails from './AccountDetails.vue';

export default {
  name: 'AccountEdit',
  mixins: [ApiMixin, ValidationMixin],
  components: {
    AccountDetails,
  },
  data() {
    return {
      account: {},
    };
  },
  computed: mapGetters(['currentUser']),
  methods: {
    onSave: function () {
      const validator = this.$refs.accountDetails.getValidator();
      this.validateInput(validator)
        .then(() => {
          const model = this.$refs.accountDetails.getModel();
          return this.accountApi().updateAccount(model);
        })
        .then(() => this.back())
        .catch((error) => {
          this.onSubmitError(validator, error);
          console.error(error);
        });
    },
    onCancel() {
      this.back();
    },
    back() {
      this.$router.push({ name: 'account' });
    },
  },
};
</script>
