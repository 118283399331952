<template>
  <div>
    <b-dropdown variant="link" size="sm">
      <template v-slot:button-content>
        <span class="font-weight-bold text-capitalize">{{ subscription.status }}</span>
      </template>

      <b-dd-item
        v-if="
          canBeSet(
            subscription.status,
            subscription.owner.id,
            SubscriptionStatus.PENDING,
            currentUser.accountId,
          )
        "
        @click.prevent="setStatus(SubscriptionStatus.PENDING)"
      >
        Request access
      </b-dd-item>

      <b-dd-item
        v-if="
          canBeSet(
            subscription.status,
            subscription.owner.id,
            SubscriptionStatus.CANCELLED,
            currentUser.accountId,
          )
        "
        @click.prevent="setStatus(SubscriptionStatus.CANCELLED)"
      >
        Cancel subscription
      </b-dd-item>

      <b-dd-item
        v-if="
          canBeSet(
            subscription.status,
            subscription.owner.id,
            SubscriptionStatus.APPROVED,
            currentUser.accountId,
          )
        "
        @click.prevent="approve()"
      >
        Approve
      </b-dd-item>

      <b-dd-item
        v-if="
          canBeSet(
            subscription.status,
            subscription.owner.id,
            SubscriptionStatus.DENIED,
            currentUser.accountId,
          )
        "
        @click.prevent="setStatus(SubscriptionStatus.DENIED)"
      >
        Deny
      </b-dd-item>

      <b-dd-item
        v-if="
          canBeSet(
            subscription.status,
            subscription.owner.id,
            SubscriptionStatus.REVOKED,
            currentUser.accountId,
          )
        "
        @click.prevent="setStatus(SubscriptionStatus.REVOKED)"
      >
        Revoke
      </b-dd-item>

      <b-dd-item
        v-if="
          !isOwner(subscription, currentUser.accountId) &&
          isStatus(subscription, SubscriptionStatus.APPROVED) &&
          hasRefreshToken(subscription)
        "
        @click.prevent="showTokenModal()"
      >
        Download key
      </b-dd-item>

      <b-dd-item
        v-if="
          !isOwner(subscription, currentUser.accountId) &&
          isStatus(subscription, SubscriptionStatus.APPROVED) &&
          !hasRefreshToken(subscription)
        "
        @click.prevent="resetSubscription()"
      >
        Request key
      </b-dd-item>
    </b-dropdown>

    <owner-key-dialog
      ref="modalKey"
      title="Approve subscription"
      key-prompt="Provide the key that was used to encrypt your data"
      :subscription="true"
      :subscriptionType="subscriptionType"
      @ok="onOwnerKeyProvided"
    />

    <refresh-token ref="tokenModal" />
  </div>
</template>

<script>
import OwnerKeyDialog from './OwnerKeyDialog.vue';
import { default as RefreshToken } from './RefreshToken.vue';
import { SubscriptionStatus } from '../data/subscriptions/statuses.js';
import { default as ApiMixin } from '../mixins/ApiMixin.vue';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'SubscriptionManipulationButton',
  mixins: [ApiMixin],
  components: {
    OwnerKeyDialog,
    RefreshToken,
  },
  props: {
    subscription: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['currentUser']),
    subscriptionType: function () {
      return this.subscription.api ? 'Api' : 'Repository';
    },
    subcriptionResourceId: function () {
      return this.subscriptionType === 'Api'
        ? this.subscription.api.referenceId
        : this.subscription.repository.referenceId;
    },
  },
  data() {
    return {
      SubscriptionStatus,
    };
  },
  methods: {
    ...mapMutations(['updateSubscription']),
    canBeSet: SubscriptionStatus.canBeSet,
    hasRefreshToken: SubscriptionStatus.hasRefreshToken,
    isStatus: SubscriptionStatus.isStatus,
    isOwner: SubscriptionStatus.isOwner,
    async setStatus(status) {
      const newSubscription =
        this.subscriptionType === 'Api'
          ? await this.subscriptionApi().setApiSubscriptionStatus(
              this.subcriptionResourceId,
              this.subscription.identifier,
              status,
            )
          : await this.subscriptionApi().setRepositorySubscriptionStatus(
              this.subcriptionResourceId,
              this.subscription.identifier,
              status,
            );
      this.updateSubscription(newSubscription);
    },
    async resetSubscription() {
      const newSubscription = await this.subscriptionApi().resetSubscription(
        this.subcriptionResourceId,
        this.subscription.identifier,
      );
      this.updateSubscription(newSubscription);
    },
    approve() {
      this.$refs.modalKey.open();
    },
    async onOwnerKeyProvided(key, methods) {
      const newSubscription =
        this.subscriptionType === 'Api'
          ? await this.subscriptionApi().approveApiSubscription(
              this.subcriptionResourceId,
              this.subscription.identifier,
              key,
              methods,
            )
          : await this.subscriptionApi().approveRepositorySubscription(
              this.subcriptionResourceId,
              this.subscription.identifier,
              key,
              methods,
            );
      this.updateSubscription(newSubscription);
    },
    async showTokenModal() {
      const token = await this.subscriptionApi().getRefreshToken(this.subscription.identifier);
      this.$refs.tokenModal.open(token);
    },
  },
};
</script>
