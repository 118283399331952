import format from 'string-format';
import { Api } from './Api';

class SubscriptionApi extends Api {
  static URL_REPOSITORY_SUBSCRIPTIONS = 'repositories/{repositoryId}/subscriptions';
  static URL_API_SUBSCRIPTIONS = 'apis/{apiId}/subscriptions';

  /**
   * Create API access request
   */
  createApiSubscription(apiId) {
    const url = format(SubscriptionApi.URL_API_SUBSCRIPTIONS, { apiId });
    return super.post(url);
  }

  createRepositorySubscription(repositoryId) {
    const url = format(SubscriptionApi.URL_REPOSITORY_SUBSCRIPTIONS, { repositoryId });
    return super.post(url);
  }

  /**
   * Set subscription status
   */
  setApiSubscriptionStatus(apiId, subscriptionId, status) {
    const url = format(SubscriptionApi.URL_API_SUBSCRIPTIONS + '/{subscriptionId}', {
      apiId,
      subscriptionId,
    });
    return super.post(url, status);
  }

  setRepositorySubscriptionStatus(repositoryId, subscriptionId, status) {
    const url = format(SubscriptionApi.URL_REPOSITORY_SUBSCRIPTIONS + '/{subscriptionId}', {
      repositoryId,
      subscriptionId,
    });
    return super.post(url, status);
  }

  /**
   * Approve API access request
   */
  approveApiSubscription(apiId, subscriptionId, key, methods) {
    const url = format(SubscriptionApi.URL_API_SUBSCRIPTIONS + '/{subscriptionId}/approve', {
      apiId,
      subscriptionId,
    });
    return super.post(url, { key, methods });
  }

  inviteToSubscribe(apiId, dataKey, methods, email) {
    const url = format(SubscriptionApi.URL_API_SUBSCRIPTIONS + '/invite', { apiId });
    const inviteForm = {
      email,
      dataKey,
      methods,
    };
    return super.post(url, inviteForm);
  }

  /**
   * Approve Repository access request
   */
  approveRepositorySubscription(repositoryId, subscriptionId, key, methods) {
    const url = format(SubscriptionApi.URL_REPOSITORY_SUBSCRIPTIONS + '/{subscriptionId}/approve', {
      repositoryId,
      subscriptionId,
    });
    return super.post(url, { key, methods });
  }

  /**
   * get account subscriptions
   */
  getSubscriptions(owned, statuses) {
    let url = format('subscriptions?owned={owned}', { owned });
    if (statuses) {
      url += '&statuses=' + statuses;
    }
    return super.get(url);
  }

  /**
   * get API subscriptions for all APIs in the source repository or repository subscriptions for reference repository
   */
  getRepositorySubscriptions(repositoryId) {
    const url = format(SubscriptionApi.URL_REPOSITORY_SUBSCRIPTIONS, { repositoryId });
    return super.get(url);
  }

  /**
   * get API subscriptions
   */
  getApiSubscriptions(repositoryId, apiId) {
    const url = format(SubscriptionApi.URL_API_SUBSCRIPTIONS, { repositoryId, apiId });
    return super.get(url);
  }

  /**
   * get RefreshToken
   */
  getRefreshToken(subscriptionIdentifier) {
    const url = format('subscriptions/{subscriptionIdentifier}/token', { subscriptionIdentifier });
    return super.get(url);
  }

  /**
   * Reset subscription
   */
  resetSubscription(apiId, subscriptionIdentifier) {
    const url = format(SubscriptionApi.URL_SUBSCRIPTIONS + '/{subscriptionIdentifier}/reset', {
      apiId,
      subscriptionIdentifier,
    });
    return super.post(url);
  }
}

export default SubscriptionApi;
