<template>
  <div class="col">
    <div class="row">
      <div class="col"><h4>Tokens</h4></div>
      <div class="col text-right">
        <b-button @click.prevent="showAddTokenDialog" variant="outline-primary" size="sm"
          >New token</b-button
        >
      </div>
    </div>

    <tokens-table :tokens="tokens" @showTokenClicked="showToken" />
    <new-integration-token-dialog ref="newTokenDialog" @newTokenCreated="onNewTokenCreated" />
    <refresh-token ref="tokenModal" />
  </div>
</template>

<script>
import { default as ApiMixin } from '../mixins/ApiMixin.vue';
import TokensTable from '../tokens/TokensTable.vue';
import RefreshToken from '../components/RefreshToken.vue';
import NewIntegrationTokenDialog from '../tokens/NewIntegrationToken.vue';
export default {
  name: 'IntegrationsTokens',
  mixins: [ApiMixin],
  components: {
    NewIntegrationTokenDialog,
    TokensTable,
    RefreshToken,
  },
  data() {
    return {
      tokens: null,
    };
  },
  async mounted() {
    this.tokens = await this.tokensApi().getIntegrationsTokens();
  },
  methods: {
    showAddTokenDialog: function () {
      this.$refs.newTokenDialog.show();
    },
    onNewTokenCreated: function (newToken) {
      this.tokens.push(newToken);
      this.showToken(newToken.token);
    },
    showToken(token) {
      this.$refs.tokenModal.open(token);
    },
  },
};
</script>
