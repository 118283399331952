import _ from 'lodash';
import * as Api from './api.js';

class ApiCallbacks extends Api.Callbacks {
  constructor(state, router) {
    super();
    this._state = state;
    this._store = state._store;
    this._name = state._component;
    this._router = router;
  }

  onPending(method, url) {
    console.log('ApiCallbacks.onPending "%s", "%s", "%s"', this._name, method, url);
    this._state.setPending(method, url);
  }

  onSuccess(method, url) {
    console.log('ApiCallbacks.onSuccess "%s", "%s", "%s"', this._name, method, url);
    this._state.clear();
  }

  onFailure(error) {
    console.log('ApiCallbacks.onFailure "%s", %o', this._name, error);
    this.clear();
    const status = _.get(error, ['response', 'status'], 401);
    const url = _.get(error, ['config', 'url'], 'auth/login/otp');
    if (status === 401 && url !== 'auth/login/otp') {
      this._store.dispatch('setCurrentUser', null);
      if (this._router) this._router.push({ name: 'login' });
    } else {
      this._state.setFailure(error.response);
    }
  }

  clear() {
    this._state.clear();
  }
}

/**
 * API state manipulations in Vuex store
 */
class ApiState {
  static Type = {
    PENDING: 'PENDING',
    FAILURE: 'FAILURE',
  };

  static Action = {
    SET_STATE: 'setApiState',
    REMOVE_STATE: 'removeApiState',
  };

  constructor(store, component) {
    this._store = store;
    this._component = component;
    this._clean = true;
  }

  setPending(method, url) {
    this.clear(); // clear errors before setting PENDING state
    const state = {
      method,
      url,
    };
    this._setState(ApiState.Type.PENDING, state);
  }

  setFailure(response) {
    const state = {
      status: response.status,
      data: response.data,
    };
    this._setState(ApiState.Type.FAILURE, state);
  }

  clear() {
    if (!this._clean) {
      this._store.dispatch(ApiState.Action.REMOVE_STATE, this._component);
      this._clean = true;
    }
  }

  _setState(type, state) {
    const payload = {
      type,
      component: this._component,
      state,
    };
    this._store.dispatch(ApiState.Action.SET_STATE, payload);
    this._clean = false;
  }
}

export { ApiCallbacks, ApiState };
