<template>
  <div class="card h-100">
    <div class="card-body">
      <div class="row">
        <div class="col-2 px-0" />
        <router-link
          class="card-title text-center col-8 px-0"
          :to="{ name: 'integration-edit', params: { blueprintId: blueprint.id } }"
        >
          {{ blueprint.name }}
        </router-link>

        <div class="dropdown col-2 text-right px-0">
          <div class="btn-group dropright px-0">
            <i
              type="button"
              class="bi bi-three-dots-vertical"
              data-toggle="dropdown"
              aria-expanded="false"
            />
            <div class="dropdown-menu">
              <router-link
                class="dropdown-item"
                :to="{ name: 'integration-edit', params: { blueprintId: blueprint.id } }"
              >
                Settings
              </router-link>
              <a class="dropdown-item disabled" href="#">Dupplicate</a>
              <a class="dropdown-item disabled" href="#">Pause</a>
              <a class="dropdown-item disabled" href="#">Delete</a>
            </div>
          </div>
        </div>
      </div>

      <p class="card-text text-center">{{ connectedUsers.length }} connected users</p>
      <p class="card-text text-center">Active</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { default as ApiMixin } from '../mixins/ApiMixin.vue';
export default {
  name: 'IntegrationTile',
  mixins: [ApiMixin],
  props: {
    blueprint: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['connectors', 'getConnector']),
    sourceName() {
      return this.getConnector(this.blueprint.sourceConnectorId)?.name;
    },
    destinationName() {
      return this.getConnector(this.blueprint.destinationConnectorId)?.name;
    },
  },
  data() {
    return {
      connectedUsers: [],
    };
  },
  async mounted() {
    await this.load();
  },
  methods: {
    async load() {
      this.connectedUsers = await this.integrationApi().getInstances(this.blueprint.id);
    },
  },
};
</script>
