<template>
  <div>
    <b-breadcrumb
      :items="[
        { text: 'Users', to: { name: 'account' } },
        { text: User.getFullName(user), active: true },
      ]"
    />

    <div class="card w-50">
      <form v-on:submit.prevent="update">
        <div class="card-body">
          <user-details ref="userDetails" :user="user"></user-details>

          <div class="row justify-content-center">
            <button type="submit" class="btn btn-primary mx-2">Save</button>
            <button class="btn btn-primary mx-2" @click.prevent="$router.back()">Cancel</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { default as ApiMixin } from '../mixins/ApiMixin.vue';
import UserDetails from './UserDetails.vue';
import { User } from './users.js';
import { default as ValidationMixin } from '../mixins/ValidationMixin.vue';

export default {
  name: 'UserEdit',
  mixins: [ApiMixin, ValidationMixin],
  components: {
    UserDetails,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      user: {},
    };
  },
  computed: {
    User() {
      return User;
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.userApi()
        .getUser(this.id)
        .then((user) => (this.user = user));
    },
    update() {
      const userDetails = this.$refs.userDetails;
      const validator = userDetails.getValidator();
      this.validateInput(validator)
        .then(() => {
          const model = userDetails.getModel();
          return this.userApi().updateUser(model);
        })
        .then(() => this.back())
        .catch((error) => {
          this.onSubmitError(validator, error);
          console.error(error);
        });
    },
    back() {
      this.$router.push({ name: 'account' });
    },
  },
};
</script>

<style scoped></style>
