<template>
  <div>
    <modal-dlg
      id="modal"
      ref="modal"
      title="New Token"
      :submit="createToken"
      :validator="$validator"
      @hidden="clearState"
      @shown="() => this.$refs.tokenNameInput.focus()"
      @cancel="clearState"
      no-fade
      lazy
    >
      <div class="form-group row">
        <label class="col-3 col-form-label">Name</label>
        <div class="col">
          <input
            ref="tokenNameInput"
            :class="[{ 'is-invalid': errors.has('tokenName') }, 'form-control']"
            v-model="newTokenName"
            v-validate="'required'"
            data-vv-as="Name"
            name="tokenName"
            type="text"
          />
          <div class="invalid-feedback" v-show="errors.has('tokenName')">
            {{ errors.first('tokenName') }}
          </div>
        </div>
      </div>
    </modal-dlg>
  </div>
</template>

<script>
import { default as ApiMixin } from '../mixins/ApiMixin.vue';
import { default as ModalDlg } from '../components/ModalDlg.vue';
import { SubscriptionMethod } from '../data/apis/SubscriptionMethod';
export default {
  name: 'NewDataTokenDialog',
  mixins: [ApiMixin],
  components: {
    ModalDlg,
  },
  async mounted() {
    this.$validator.extend('methods', (value, args) => {
      console.log('validating', value, args, this.newTokenApiMethods.length > 0);
      return this.newTokenApiMethods.length > 0;
    });
  },
  computed: {
    keySize: () => 32,
    allApiMethods() {
      return SubscriptionMethod.allApiMethods();
    },
  },
  data() {
    return {
      newTokenKey: '',
      newTokenName: '',
      newTokenApiMethods: [],
    };
  },
  methods: {
    clearState: function () {
      this.newTokenKey = '';
      this.newTokenName = '';
      this.newTokenApiMethods = [];
    },
    show: function () {
      this.$refs.modal.show();
    },
    createToken: async function () {
      try {
        const valid = await this.$validator.validate();
        if (valid) {
          const tokenForm = {
            name: this.newTokenName,
            key: this.newTokenKey,
            methods: this.newTokenApiMethods,
          };
          const newToken = await this.tokensApi().createIntegrationsToken(tokenForm);
          this.$emit('newTokenCreated', newToken);
        }
        this.clearState();
      } catch (error) {
        console.error(error);
        alert('something is wrong, try again');
      }
    },
    async onMethodChange(event) {
      const checkbox = event.target;
      if (checkbox.checked) {
        this.newTokenApiMethods.push(checkbox.value);
      } else {
        const idx = this.newTokenApiMethods.findIndex((m) => m === checkbox.value);
        if (idx > -1) {
          this.newTokenApiMethods.splice(idx, 1);
        }
      }
      await this.$validator.validate('newTokenApiMethods');
    },
  },
};
</script>
