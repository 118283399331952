<template>
  <div>
    <div class="row">
      <div class="col"><h4>Integrations</h4></div>
      <div class="col col-3 text-right">
        <input type="text" placeholder="search" class="w-100" v-model="filter" />
      </div>
    </div>

    <div class="row">
      <div
        class="col-lg-3 col-md-6 col-sm-12 mb-4"
        v-for="blueprint in filteredBlueprints"
        v-bind:key="blueprint.id"
      >
        <integration-tile :blueprint="blueprint" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import IntegrationTile from './IntegrationTile.vue';
export default {
  name: 'IntegrationList',
  computed: {
    ...mapGetters(['blueprints']),
    filteredBlueprints() {
      return !this.filter
        ? this.blueprints
        : this.blueprints.filter((blueprint) => blueprint.name.match(new RegExp(this.filter, 'i')));
    },
  },
  data() {
    return {
      filter: '',
    };
  },
  components: {
    IntegrationTile,
  },
};
</script>
