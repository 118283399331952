<template>
  <div>
    <div class="card w-50">
      <form v-on:submit.prevent="update">
        <div class="card-body">
          <user-details ref="userDetails" :user="user"></user-details>

          <div class="row justify-content-center">
            <button type="submit" class="btn btn-primary mx-2">Save</button>
            <button class="btn btn-primary mx-2" @click.prevent="$router.back()">Cancel</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { default as ApiMixin } from '../mixins/ApiMixin.vue';
import UserDetails from './UserDetails.vue';
import { default as ValidationMixin } from '../mixins/ValidationMixin.vue';

export default {
  name: 'Profile',
  mixins: [ApiMixin, ValidationMixin],
  components: {
    UserDetails,
  },
  computed: mapGetters(['currentUser']),
  methods: {
    update() {
      const userDetails = this.$refs.userDetails;
      const validator = userDetails.getValidator();
      this.validateInput(validator)
        .then(() => {
          const model = userDetails.getModel();
          return this.userApi().updateUser(model);
        })
        .then(() => this.back())
        .catch((error) => {
          this.onSubmitError(validator, error);
          console.error(error);
        });
    },
    back() {
      this.$router.push({ name: 'repo-list' });
    },
  },
};
</script>

<style scoped></style>
