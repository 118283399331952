import format from 'string-format';
import { Api } from './Api';

class TokensApi extends Api {
  static URL_DATA_TOKENS = 'repositories/{repositoryId}/tokens';
  static URL_INTEGRATIONS_TOKENS = 'integrations/tokens';

  static URL_CANCEL_TOKEN = 'tokens/{id}';

  createDataToken(repositoryId, tokenForm) {
    const url = format(TokensApi.URL_DATA_TOKENS, { repositoryId });
    return super.post(url, tokenForm);
  }

  getDataTokens(repositoryId) {
    const url = format(TokensApi.URL_DATA_TOKENS, { repositoryId });
    return super.get(url);
  }

  cancelToken(id) {
    const url = format(TokensApi.URL_CANCEL_TOKEN, { id });
    return super.post(url);
  }

  createIntegrationsToken(tokenForm) {
    return super.post(TokensApi.URL_INTEGRATIONS_TOKENS, tokenForm);
  }

  getIntegrationsTokens() {
    return super.get(TokensApi.URL_INTEGRATIONS_TOKENS);
  }
}

export default TokensApi;
