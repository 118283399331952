<template>
  <div>
    <b-modal
      ref="modalDlg"
      title="Invite to subscribe"
      @shown="() => this.$refs.emailInput.focus()"
      @ok="onOk"
      @cancel="onCancel"
      no-fade
      size="lg"
    >
      <div class="form-group row mb-4">
        <label class="col-2 col-form-label" for="api">API</label>
        <div class="col-10">
          <b-dropdown :text="selectedApi.name" variant="light" size="md">
            <b-dd-item-button
              v-for="api in apis"
              :key="api.referenceId"
              :value="api"
              @click="onApiChange(api)"
            >
              {{ api.name }}
            </b-dd-item-button>
          </b-dropdown>
        </div>
      </div>
      <div class="form-group row mb-4">
        <label class="col-2 col-form-label">{{
          isApi ? 'API methods' : 'Repository operations'
        }}</label>
        <div class="col-10">
          <input
            hidden
            value="apiMethods"
            v-validate="'methods'"
            name="apiMethods"
            class="form-control"
            :class="{ 'is-invalid': errors.has('apiMethods') }"
          />

          <label v-for="(method, idx) in allMethods" :key="idx" class="row px-4 align-items-center">
            <input
              type="checkbox"
              :value="method.value"
              class="col-auto"
              @change="onMethodChange"
            />
            <span class="font-weight-bold col-2">{{ method.title }}</span>
            <span class="col-8">{{ method.description }}</span>
          </label>
          <div class="invalid-feedback" v-show="errors.has('apiMethods')">
            At least one method must be selected
          </div>
        </div>
      </div>

      <div class="form-group row mb-4">
        <label class="col-2 col-form-label" for="email">Email</label>
        <div class="col-10">
          <input
            id="email"
            ref="emailInput"
            :class="[{ 'is-invalid': errors.has('email') }, 'form-control']"
            v-model="email"
            v-validate="'required'"
            data-vv-as="Email"
            name="email"
            type="email"
          />
          <div class="invalid-feedback" v-show="errors.has('email')">
            {{ errors.first('email') }}
          </div>
        </div>
      </div>

      <div class="form-group row mb-4">
        <label class="col-2 col-form-label" for="email">Key</label>
        <div class="col-10">
          <input
            id="key"
            :class="[{ 'is-invalid': errors.has('key') }, 'form-control']"
            v-model="key"
            v-validate="`required|alpha_dash|length:${keySize}`"
            data-vv-as="key"
            name="key"
            type="text"
            :minlength="keySize"
            :maxlength="keySize"
          />
          <div class="invalid-feedback" v-show="errors.has('key')">
            {{ errors.first('key') }}
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { SubscriptionMethod } from '../apis/SubscriptionMethod';
import { default as ApiMixin } from '../../mixins/ApiMixin.vue';
import { mapMutations } from 'vuex';
const KEY_SIZE = 32;

export default {
  name: 'InviteToSubscribeDialog',
  mixins: [ApiMixin],
  props: {
    apis: {
      type: Array,
      required: true,
    },
    subscriptionType: {
      type: String,
      default: 'Api',
    },
  },
  data() {
    return {
      email: '',
      selectedApi: '',
      key: '',
      apiMethods: [],
    };
  },
  computed: {
    keySize: () => KEY_SIZE,
    allMethods() {
      return this.isApi
        ? SubscriptionMethod.allApiMethods()
        : SubscriptionMethod.allRepositoryMethods();
    },
    isApi() {
      return this.subscriptionType === 'Api';
    },
  },
  methods: {
    ...mapMutations(['updateSubscription']),
    open() {
      this.selectedApi = this.apis[0];
      this.email = '';
      this.key = '';
      this.apiMethods = [];
      this.$refs.modalDlg.show();
      this.$validator.reset();
      this.$validator.extend('methods', (value, args) => {
        console.log('validating', value, args, this.apiMethods.length > 0);
        return this.apiMethods.length > 0;
      });
    },
    async onOk(event) {
      event.preventDefault();

      const valid = await this.$validator.validate();
      if (valid) {
        const newSubscription = await this.subscriptionApi().inviteToSubscribe(
          this.selectedApi.referenceId,
          this.key,
          this.apiMethods,
          this.email,
        );
        this.updateSubscription(newSubscription);
        this.$refs.modalDlg.hide();
      }
    },
    onCancel() {
      this.$emit('cancel');
    },
    onApiChange(api) {
      this.selectedApi = api;
    },
    async onMethodChange(event) {
      const checkbox = event.target;
      if (checkbox.checked) {
        this.apiMethods.push(checkbox.value);
      } else {
        const idx = this.apiMethods.findIndex((m) => m === checkbox.value);
        if (idx > -1) {
          this.apiMethods.splice(idx, 1);
        }
      }
      await this.$validator.validate('apiMethods');
    },
  },
};
</script>
