import 'bootstrap';
import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './app/App.vue';
import routes from './app/routes.js';
import VeeValidate from 'vee-validate';
import store from './app/vuex';
import bootstrap from 'bootstrap-vue';
import { UserApi } from './app/api.js';
import { Auth } from './app/auth/auth.js';
import { get as _get } from 'lodash';
import ToggleButton from 'vue-js-toggle-button';
import './app/validators';

Vue.use(VueRouter);
Vue.use(VeeValidate, { fieldsBagName: 'veeFields' });

Vue.use(bootstrap);
Vue.use(ToggleButton);

const router = new VueRouter({
  mode: 'history',
  // eslint-disable-next-line no-undef
  base: __dirname,
  routes: routes,
});

router.beforeEach((to, from, next) => {
  let goNext = true;

  // if any of the 'to' has meta.requiresAuth (default is true) make sure 'user' is loaded before navigating
  if (to.matched.some((record) => _get(record, ['meta', 'requiresAuth'], true))) {
    const store = router.app.$store;
    const currentUser = getUser(store);
    if (!currentUser) {
      // user is required but missing, let's load it
      goNext = false;
      loadUser()
        .then((user) => {
          setCurrentUser(store, user);
          store.dispatch('initStore');
          next();
        })
        .catch((error) => {
          console.info(error);
          const optionalAuth = _get(to, ['meta', 'requiresOptionalAuth'], false);
          // some rotes require 'optional' authentication (like 'home' page) - they can be displayed
          // in both 'authenticated' and 'not authenticated' modes
          if (optionalAuth) {
            next();
          } else {
            // go to 'login' page
            next({
              name: 'login',
              query: { redirect: to.fullPath },
            });
          }
        });
    }
  }

  // sometime we have to wait for some actions to complete before navigating further
  if (goNext) {
    next();
  }
});

new Vue({
  router,
  store,
  el: '#app',
  render: (h) => h(App),
});

/**
 * get user from Vuex store
 */
function getUser(store) {
  return store.getters.currentUser;
}

/**
 * set user in Vuex store
 */
function setCurrentUser(store, user) {
  store.dispatch('setCurrentUser', user);
}

/**
 * If JWT tokens are present and valid - try to load current user
 * @return {Promise}
 */
function loadUser() {
  if (Auth.areTokensValid()) {
    return new UserApi(null).getCurrent();
  } else {
    return Promise.reject();
  }
}
