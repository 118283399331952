import format from 'string-format';
import { Api } from './Api';

class TaskApi extends Api {
  static URL_TASKS = 'repositories/{repositoryId}/tables/{tableReferenceId}/tasks';

  getTasks(repositoryId, tableReferenceId) {
    const url = format(TaskApi.URL_TASKS, { repositoryId, tableReferenceId });
    return super.get(url);
  }
}

export default TaskApi;
