import * as components from '.';

export default [
  {
    path: '/repos/:repositoryId/tables/:tableReferenceId',
    component: components.Table,
    name: 'table',
    props: true,
  },
  {
    path: '/repos/:repositoryId/tables',
    component: components.TableList,
    name: 'tables',
    props: true,
  },
];
