<template>
  <div>
    <div v-if="isEmpty">
      <div class="col"><h4>Inbox</h4></div>
      <p class="col">Everything is done, life is good!</p>
    </div>
    <api-subscription-list
      v-if="apiSubscriptions.length"
      :subscriptions="apiSubscriptions"
      :mySubscriptions="false"
      @subscriptionChange="updateSubscription"
      title="API subscription requests"
    ></api-subscription-list>
    <repository-subscription-list
      v-if="repositorySubscriptions.length"
      :subscriptions="repositorySubscriptions"
      :mySubscriptions="false"
      @subscriptionChange="updateSubscription"
      title="Repository subscription requests"
    ></repository-subscription-list>
  </div>
</template>

<script>
import { default as ApiMixin } from '../../mixins/ApiMixin.vue';
import { mapGetters, mapMutations } from 'vuex';
import ApiSubscriptionList from './ApiSubscriptionList.vue';
import RepositorySubscriptionList from './RepositorySubscriptionList.vue';
import { SubscriptionStatus } from './statuses.js';

export default {
  name: 'Inbox',
  mixins: [ApiMixin],
  components: {
    ApiSubscriptionList,
    RepositorySubscriptionList,
  },
  computed: {
    ...mapGetters(['currentUser', 'apiSubscriptionsToMe', 'repositorySubscriptionsToMe']),
    apiSubscriptions: function () {
      const cutoffDate = new Date();
      cutoffDate.setDate(cutoffDate.getDate() - 1);
      return this.apiSubscriptionsToMe.filter(
        (s) => s.status === SubscriptionStatus.PENDING || new Date(s.created * 1000) > cutoffDate,
      );
    },
    repositorySubscriptions: function () {
      const cutoffDate = new Date();
      cutoffDate.setDate(cutoffDate.getDate() - 1);
      return this.repositorySubscriptionsToMe.filter(
        (s) => s.status === SubscriptionStatus.PENDING || new Date(s.created * 1000) > cutoffDate,
      );
    },
    isEmpty: function () {
      return this.apiSubscriptions.length === 0 && this.repositorySubscriptions.length === 0;
    },
  },
  methods: {
    ...mapMutations(['updateSubscription']),
  },
};
</script>
